import React, { useEffect, useState } from "react";
import { Typography, Card, Input, Spin } from "antd";
import icon from "../../../assets/icon.png";
import firebase from "firebase";

const { Search } = Input;
const { Text} = Typography;

const CommunicationCard = ({ type, value, comms, setCommunicationType }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Card
      hoverable
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: hovered ? "#f2f2f2" : "transparent",
        textAlign: "center",
        height: 150,
        width: 150,
        margin: 5,
      }}
      onClick={() => {
        setCommunicationType({
          _type: type,
          newValues: [
            ...comms.map((item) => {
              return item.type !== type
                ? item
                : {
                    type: type,
                    value: value - 1,
                  };
            }),
          ],
        });
      }}
    >
      <img src={icon} height={60} alt="Head PNG" />
      <Typography
        style={{
          color: "grey",
        }}
      >
        {type}
      </Typography>
      <Typography style={{ fontWeight: "bold", fontSize: 18 }}>
        {value} left
      </Typography>
    </Card>
  );
};

const CommunicationModal = ({ setCommunicationType }) => {
  const [find, setFind] = useState("");
  const [loading, setLoading] = useState(true);
  const [comms, setComms] = useState([]);
  const auth = firebase.auth();
  const firestore = firebase.firestore();

  const getDetails = async () => {
    try {
      setLoading(true);
      const result = (
        await firestore.collection("Users").doc(auth.currentUser.uid).get()
      ).data();
      setComms(
        result?.appointments.filter((item) => {
          return item.value > 0;
        })
      );
      setLoading(false);
      console.log(result?.appointments);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      <Search
        value={find}
        style={{ marginBottom: 20 }}
        onChange={(e) => {
          setFind(e.target.value);
        }}
      />
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {loading ? (
          <Spin spinning={loading} />
        ) : (
          <>
          {
            comms.length=== 0 ?
            <>
            <Text>You do not have any appointments left! You need to buy more!</Text>
            </>
            :
            <>
            
            {find === "" ? (
              <>
                {comms.map(({ type, value }, index) => (
                  <CommunicationCard
                    type={type}
                    value={value}
                    comms={comms}
                    // price={price}
                    // key={`${index}`}
                    setCommunicationType={setCommunicationType}
                  />
                ))}
              </>
            ) : (
              <>
                {comms.map(({ type, value }, index) => {
                  return type.search(find) !== -1 ? (
                    <CommunicationCard
                      type={type}
                      value={value}
                      comms={comms}
                      // price={price}
                      // key={`${index}`}
                      setCommunicationType={setCommunicationType}
                    />
                  ) : null;
                })}
              </>
            )}
            </>
          }
          </>
        )}
      </div>
    </>
  );
};

export default CommunicationModal;
