import React from "react";
import MainModalContent from "./MainModalContent";
import CommunicationModal from "./CommunicationModal";
import TimeSlotModal from "./TimeSlotModal";

// const comms = [
//     // {
//     //   type: "Gastroenterology Consultation",
//     //   price: 100,
//     // },
//     // {
//     //   type: "General Physician Consultation",
//     //   price: 200,
//     // },
//     // {
//     //   type: "Gynaecology Consultation",
//     //   price: 300,
//     // },
//     // {
//     //   type: "Dental Consultation",
//     //   price: 250,
//     // },
//     // {
//     //   type: "Medical Treatment",
//     //   price: 250,
//     // },
//     // {
//     //   type: "Neurology Treatment",
//     //   price: 200,
//     // },
//     {
//       type:"Audio",
//       // price: 200
//     },
//     {
//       type:"Video",
//       // price: 200
//     },
//     {
//       type:"Virtual",
//       // price: 200
//     },
// ];

export const ModalContent = ({
  screen,
  communication,
  timeSlot,
  comms,
  setCommunicationType,
  setTime,
  navigate,
  bookAppointment,
}) => {
  if (screen === 0) {
    return (
      <MainModalContent
        navigate={navigate}
        communication={communication}
        timeSlot={timeSlot}
        bookAppointment={bookAppointment}
      />
    );
  } else if (screen === 1) {
    return (
      <CommunicationModal
        setCommunicationType={setCommunicationType}
      />
    );
  } else {
    return <TimeSlotModal setTime={setTime} />;
  }
};

export default ModalContent;
