import React, { useState } from "react";
import {Typography} from 'antd';
import "./Button.css";

const STYLES = ["btn--primary", "btn--outline"];
const SIZES = ["btn--medium", "btn--large"];
const {Text} = Typography;

export const Button = ({
  text,
  type,
  onClick,
  buttonStyle,
  buttonSize,
  style
}) => {
  const [hover, setHover] = useState(false); //used for bookAppointment in menu
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];

  return (
    <div className="btn-mobile">
      <button
        className={`btn ${checkButtonStyle} ${checkButtonSize}`}
        onClick={onClick}
        type={type}
        style={style ? {...style} : {width:210}}
        onMouseEnter={() => setHover(!hover)}
        onMouseLeave={() => setHover(!hover)}
      >
      <Text
        style={{
          fontFamily: "GalanoMedium",
          fontSize: 18,
          color: hover ? "#fff" : "#000",
        }}
      >
        {text}
      </Text>
      </button>
    </div>
  );
};
