import React from "react";
import firebase from "firebase";
import firebaseConfig from "../firebaseConfig";
const UserContext = React.createContext();

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

class UserProvider extends React.Component {
    setValue = (value) => {    
      this.setState({ value });
    }
  
    state = {
      setValue: this.setValue,
      value: localStorage.getItem("user")?localStorage.getItem("user"):{},
      loading:false,
      isAdmin:false,
      userId:"",
      name:""
    }
  
    assessPrivilege = async (user) => {
      await firebase.firestore()
        .collection("Users")
        .doc(user.uid)
        .get()
        .then(async (val) => {
          window.localStorage.setItem("user", JSON.stringify(val.data()));
          if (val.data()?.privilege == "admin") {
            const token = await user?.getIdToken();
            window.localStorage.setItem("access-token", token);
            this.setState({isAdmin:true,loading:false,name:val.data()?.firstName + " " + val.data()?.lastName});
          }
          else {
            this.setState({isAdmin:false,loading:false,name:val.data()?.firstName + " " + val.data()?.lastName});
          }
        }).catch((error) => {
          console.log(error);
        })
    }

    async componentDidMount () {
      this.setState({loading:true});
      await firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          this.setState({userId:user.uid});
          await this.assessPrivilege(user);
        } else {
          this.setState({loading:false});
          window.localStorage.setItem("access-token", "");
        }
      });
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.state.value !== prevState.value) {
        if(Object.keys(this.state.value).length == 0){
          this.setState({isAdmin:false})
        } else {
          this.setState({isAdmin:true})
        }
        localStorage.setItem("user", JSON.stringify(this.state.value));
      }
    }
  
    render() {
      return (
        <UserContext.Provider value={this.state}>
          {this.props.children}
        </UserContext.Provider>
      )
    }
  }

  export {
    UserContext,
    UserProvider
  }