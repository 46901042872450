import React,{useState,useEffect,useCallback} from "react";
import { Typography, Space, Card } from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";

const { Text, Paragraph } = Typography;

const BlogCard = ({ title, description, text, date, blogPhoto }) => {
  const [width, setWidth] = useState(window.innerWidth);
    
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);
  
  
  const history = useHistory();
  const publishDate = moment(parseInt(date))
    .toISOString()
    .split("T")[0]
    .split("-");

  return (
    <Card
      style={{
        height: 500,
        width: width >800 ? 500 : 350,
        margin: 15,
        backgroundImage: blogPhoto
          ? `  linear-gradient(rgba(150,150,150,0.3), rgba(150,150,150,0.3)), url(${blogPhoto})`
          : null,
        backgroundSize: "cover",
        backgroundColor: blogPhoto ? null : "rgba(40,60,200,0.7)",
        borderRadius: 20,
      }}
      onClick={() => {
        history.push({
          pathname: "/view-blog",
          title: title,
          description: description,
          text: text,
          date: date,
          blogPhoto: blogPhoto,
        });
      }}
    >
      <Space direction="vertical" style={{ width: "100%" }} wrap>
        <Text style={{ color: "#fff", fontSize: 40 }}>{title}</Text>
        <Space direction="vertical" style={{ height: 325 }} wrap>
          <Paragraph
            style={{
              color: "#fff",
              fontSize: 20,
              fontFamily: "GalanoLight",
            }}
            ellipsis={{ rows: 12 }}
          >
            {description}
          </Paragraph>
        </Space>
        <Space>
          <Text
            style={{ color: "#fff", fontSize: 25, fontFamily: "GalanoLight" }}
          >
            {publishDate[2] +
              " " +
              moment.months()[parseInt(publishDate[1]) - 1] +
              " " +
              publishDate[0]}
          </Text>
        </Space>
      </Space>
    </Card>
  );
};

export default BlogCard;