import React,{useState,useCallback,useEffect} from "react";
import { Typography, Space } from "antd";
import image from "../../assets/doctorbg.jpg";
import UserNavbar from "../../layout/UserNavbar";
import PageFooter from "../../layout/PageFooter";

const { Text } = Typography;

const UserContactUs = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);

  const handleFontSize = () => {
    if (width > 900) {
      return "45px";
    } else if (width > 600) {
      return "26px";
    } else {
      return "16px";
    }
  };
  
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <>
      <UserNavbar />
      <div
        style={{
          height: width > 600 ? 650 : 300,
          overflow: "hidden",
          // objectFit: 'cover',
          position: "relative",
          backgroundImage: image,
          borderBottomLeftRadius: "30%",
          borderBottomRightRadius: "30%",
        }}
      >
        <img
          src={image}
          alt=""
          style={{
            width: "100%",
            // objectFit: 'cover',
            borderBottomLeftRadius: "30%",
            borderBottomRightRadius: "30%",
          }}
        />
        <Space
          style={{
            position: "absolute",
            left: 0,
            marginTop: "20%",
            backgroundColor: "rgba(0,0,0,.4)",
            padding: 20,
            borderRadius: "0 60px 60px 0",
          }}
        >
          <Text
            style={{
              fontFamily: "GalanoBold",
              fontSize: handleFontSize(),
              fontStyle: "italic",
              color: "#fff",
            }}
          >
          'Where Your Health Matters'
          </Text>
        </Space>
      </div>
      <div
        style={{
          textAlign: "left",
          marginLeft: "5%",
          marginRight: "10%",
          padding: 20,
          fontWeight: 100,
        }}
      >
        <Text
          style={{
            fontFamily: "GalanoBold",
            fontSize: 30,
            fontStyle: "italic",
          }}
        >
          Contact Us
        </Text>
        <hr
          style={{
            marginTop: "2%",
            borderTop: "5px solid rgb(40, 55, 239)",
            borderRadius: 3,
            width: 200,
            marginLeft:0
          }}
        />
        <Text style={{ fontFamily: "GalanoLight", fontSize: 14 }}>
        Nimble Neurology is using telemedicine to bring neurological experts directly to the palm of your hand for increased access. Telemedicine has become an increasingly necessary form of medical practice in light of the COVID-19 pandemic. However, this is a trend that is here to stay for many reasons.
        </Text>
       
      </div>
      <PageFooter/>
    </>
  );
};

export default UserContactUs;
