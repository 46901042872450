import React from 'react';
import { Popconfirm, message, Popover, Button } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';


export default function ChatMenu(props) {
    const [hover, setHover] = React.useState(false);
    const db = firebase.firestore();

    async function confirmLeaving(e) {
        const res = await db.collection(`Chats/${props.doc}/messages`).get();
        res.docs.forEach(doc => {
            doc.ref.delete();
        });
        props.history.push('/bookings')
    }

    function cancelLeaving(e) {
        console.log(e);
        message.error('Click on No');
    }

    const text = <span>Virtual Chat Menu</span>;
    const content = (
        <div>
            <Popconfirm
                title="Are you sure to leave this chat?"
                onConfirm={confirmLeaving}
                onCancel={cancelLeaving}
                okText="Yes"
                cancelText="No"
            >
                <p style={{ color: hover ? "red" : "black" }} onMouseEnter={() => setHover(!hover)} onMouseLeave={() => setHover(!hover)}>Leave Chat</p>
            </Popconfirm>
        </div>
    );

    return (
        <Popover placement="bottomRight" title={text} content={content} trigger="click"  >
            <MenuOutlined style={{ height: "90%" }} />
        </Popover>
    );
}