import React, { useState, useEffect } from "react";
import { Modal, Typography, Space, Button } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import ModalHeader from "./ModalHeader";
import ModalContent from "./ModalContent";
import { Link, useHistory } from "react-router-dom";
import firebase from 'firebase';
import moment from 'moment';

const { Text } = Typography;

const AppointmentModal = ({ visible, setVisible,refreshData }) => {
  const [screen, setScreen] = useState(0);
  const [hovered, setHovered] = useState(false);
  const [submission, setSubmission] = useState([]);
  const [communication, setCommunication] = useState(null);
  const [newValues,setNewValues] = useState([]);
  const [timeSlot, setTimeSlot] = useState(null);
  const [userData,setUserData] = useState(null);
  const auth= firebase.auth();
  const firestore= firebase.firestore();
  const functions = firebase.functions();
  const history = useHistory();


  const setCommunicationType = (type) => {
    setCommunication(type._type);
    setNewValues(type.newValues);
    setScreen(0);
  };

  const setTime = (slot) => {
    setTimeSlot(slot);
    getSubscription();
    setScreen(0);
  };

  const remindDoc = async (type, time) => {
    try {
      const bookingNotification = functions.httpsCallable("sendMail");
      await bookingNotification({ type: type, time: time });
    } catch (error) {
      console.log(error)
    }
  }

  const parseMonth =  (month) => {
    const months = {}
    moment.months().map((item)=>{
        months[item.slice(0,3)] = item
    });
    return months[month];
  }
  
  const parseTime = (time) => {
    const minutes = time.slice(3,5);
    if(time.slice(0,2) == "00"){
      return `12:${minutes} am`;
    }
    else{
      const hours = (time.slice(0,2) > 12) ? time.slice(0,2) -12 : time.slice(0,2) ;
      if(time.slice(0,2) > 11){
          return hours <10 ? `0${hours}:${minutes} pm` : `${hours}:${minutes} pm`;
      }
      return `${hours}:${minutes} am`;
    }
  }
  const getUTCDate = (_date,_time) => {
    const UTCtime =new Date(
      `${_date} ${_time.slice(
        0,
        2
      )}:${_time.slice(3, 5)}:00 ${_time.slice(6, 8)}`
    ).toUTCString();
    const newTime =UTCtime.split(" ");
    return `${newTime[1]}-${parseMonth(newTime[2])}-${newTime[3]}`
  }
  
const getUTCTime = (_date,_time) => {
    const UTCtime =new Date(
      `${_date} ${_time.slice(
        0,
        2
      )}:${_time.slice(3, 5)}:00 ${_time.slice(6, 8)}`
    ).toUTCString();
    const newTime =UTCtime.split(" ");
    return `${parseTime(newTime[4])}`
  }


  const bookAppointment = (type, time) => {
    try {
      setSubmission([type, time]);
      firestore.collection("Users").doc(auth.currentUser.uid).update({
        appointments: newValues 
      });
      firestore.collection("Bookings").doc(auth.currentUser.uid).collection("bookings").doc(`${auth.currentUser.uid}${getUTCDate(time.split(" - ")[0], time.split(" - ")[1])}${getUTCTime(time.split(" - ")[0], time.split(" - ")[1])}`).set({
        UID: auth.currentUser.uid,
        userName: userData.firstName+" "+userData.lastName,
        appointment: type,
        bookingDate: getUTCDate(time.split(" - ")[0], time.split(" - ")[1]),
        bookingTime: getUTCTime(time.split(" - ")[0], time.split(" - ")[1]),
        subscription: userData.subType,
        status:"Pending",
        comment: "",
        email: auth.currentUser.email,
      });
      firestore.collection("AdminBookings").doc(getUTCDate(time.split(" - ")[0], time.split(" - ")[1])).collection("bookings").doc(`${auth.currentUser.uid}${getUTCTime(time.split(" - ")[0], time.split(" - ")[1])}`).set({
        UID: auth.currentUser.uid,
        userName: userData.firstName+" "+userData.lastName,
        appointment: type,
        bookingDate: getUTCDate(time.split(" - ")[0], time.split(" - ")[1]),
        bookingTime: getUTCTime(time.split(" - ")[0], time.split(" - ")[1]),
        subscription: userData.subType,
        status:"Pending",
        comment: "",
        email: auth.currentUser.email,
      });
      remindDoc(type,`${getUTCDate(time.split(" - ")[0], time.split(" - ")[1])} ${getUTCTime(time.split(" - ")[0], time.split(" - ")[1])}`);
      if(history.location.pathname=="/bookings"){
        refreshData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resetModal = () => {
    setScreen(0);
    setSubmission([]);
    setCommunication(null);
    setTimeSlot(null);
  };

  const getSubscription = async ()=>{
    const result = firestore.collection("Users").doc(auth.currentUser.uid);
    result.get().then((querySnap)=>{
      setUserData(querySnap.data());
    })
  }


  useEffect(() => {
    resetModal();
  }, []);

  return (
    <Modal
      title={
        !submission.length == 0 ? null : (
          <ModalHeader screen={screen} navigate={setScreen} />
        )
      }
      visible={visible}
      onCancel={() => {
        setVisible(false);
    resetModal();
      }}
      destroyOnClose
      closable={submission.length == 0 ? false : true}
      footer={null}
      style={{ display: "flex", flexDirection: "column", borderRadius: 40 }}
    >
      {!submission.length == 0 ? (
        <Space
          direction="vertical"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Text
            style={{
              alignSelf: "center",
              fontSize: 30,
              fontFamilty: "GalanoBold",
            }}
          >
            Success!
          </Text>
          <Text
            style={{
              alignSelf: "center",
              fontSize: 15,
              fontFamilty: "GalanoLight ",
            }}
          >
            {`${submission[0]} booked at ${submission[1]}.`}
          </Text>
          <CheckCircleFilled
            style={{
              fontSize: 75,
              color: "#2837ef",
              marginBottom: 20,
              marginTop: 20,
            }}
          />
          <Button
            onMouseEnter={() => {
              setHovered(true);
            }}
            onMouseLeave={() => {
              setHovered(false);
            }}
            onClick={() => {
              setVisible(false);
              resetModal();
            }}
            style={{
              width: 350,
              borderRadius: 20,
              backgroundColor: hovered ? "#000" : "#2837ef",
              border: 0,
              boxShadow: "0px 1px 2px 1px #2837ef",
              height: 40,
            }}
          >
            <Link to="/bookings">
              <Typography
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  fontFamily: "GalanoMedium",
                }}
              >
                Check Bookings!
              </Typography>
            </Link>
          </Button>
        </Space>
      ) : (
        <>
          <ModalContent
            screen={screen}
            communication={communication}
            timeSlot={timeSlot}
            navigate={setScreen}
            setCommunicationType={setCommunicationType}
            setTime={setTime}
            bookAppointment={bookAppointment}
          />
        </>
      )}
    </Modal>
  );
};

export default AppointmentModal;
