import React, { useState, useEffect } from "react";
import { Typography, Layout, Space, Spin } from "antd";
import firebase from "firebase";
import AdminAppointmentTable from "../../components/AdminAppointmentTable";
import AdminNavbar from "../../layout/AdminNavbar";
import AdminFooter from "../../layout/AdminFooter";
import moment from "moment";

const { Title, Text } = Typography;

const AdminBookings = () => {
  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState([]);
  const firestore = firebase.firestore();


  const calculateDayTime = (_time) => {
    const ap = _time.split(" ")[1];
    const arr = _time.split(" ")[0].split(":");
    if (ap == "am") {
        const x = parseInt(arr[0]) * 3600000;
        const y = parseInt(arr[1]) * 60000;
        return x + y;
    }
    else {
        const x = parseInt(arr[0]) * 3600000;
        const y = parseInt(arr[1]) * 60000;
        const z = 3600000 * 12
        return x + y + z;
    }
}

const convertBookingTime = (_date,_time) => {
    // console.log(_date,_time);
    const _dateSplit = _date.split("-");
    const _day = moment(`${(moment.months().indexOf(_dateSplit[1]) + 1).toString()}-${_dateSplit[0]}-${_dateSplit[2]}`, "MM-DD-YYYY").valueOf();
    const _daytime = calculateDayTime(_time);
    return _day + _daytime;
}


const parseMonth =  (month) => {
  const months = {}
  moment.months().map((item)=>{
      months[item.slice(0,3)] = item
  });
  return months[month];
}

const parseTime = (time) => {
  const hours = (time.slice(0,2) > 12) ? time.slice(0,2) -12 : 12 - time.slice(0,2) ;
  const minutes = time.slice(3,5);
  if(time.slice(0,2) > 12){
      return hours<10 ? `0${hours}:${minutes} pm` : `${hours}:${minutes} pm`;
  }
  return hours<10 ? `0${hours}:${minutes} am` : `${hours}:${minutes} am`;    
}

const getLocalDate = (_date,_time) => {
  const LocalTime =new Date(
      `${_date} ${_time.slice(
      0,
      2
      )}:${_time.slice(3, 5)}:00 ${_time.slice(6, 8)} UTC`
  ).toLocaleString();
  const newTime =LocalTime.split(", ")[0].split("/");
  if(newTime[1] > 9) {
      return `${newTime[1]}-${moment.months()[newTime[0]-1]}-${newTime[2]}`
  }
  else{
      return `0${newTime[1]}-${moment.months()[newTime[0]-1]}-${newTime[2]}`
  }
}

const getLocalTime = (_date,_time) => {
    
  const LocalTime =new Date(
      `${_date} ${_time.slice(
      0,
      2
      )}:${_time.slice(3, 5)}:00 ${_time.slice(6, 8)} UTC`
  ).toLocaleString();
  const newTime = LocalTime.split(", ")[1].split(" ");
  const newTime1 = newTime[0].split(":");
  const AP = newTime[1].toLowerCase();
  if(newTime1[0] < 9){
      return `0${newTime1[0]}:${newTime1[1]} ${AP}`;
  }
  return `${newTime1[0]}:${newTime1[1]} ${AP}`;
}




  const getAllRecords = async (id)=>{
    const records = [];
    const _bookings = await firestore.collection(`AdminBookings/${id}/bookings`).get();
      _bookings.docs.map((value)=>{
        records.push({recordID: value.id, ...value.data()});
      })
    return records; 
  }

  const getData = async () => {
    setLoading(true);
    const allAppoints = []
    const booking = firestore.collection("AdminBookings");
    const values = await booking.get().then((value)=>{
      return value.docs.map(({id})=> {return id;})
    })
    Promise.all(values.map((value)=>{
      return getAllRecords(value);
    })).then((values)=> {
      values.map((_value)=>{
        allAppoints.push(..._value);
      });
      allAppoints.sort((a,b)=>{
        return convertBookingTime(a.bookingDate,a.bookingTime) - convertBookingTime(b.bookingDate,b.bookingTime) ? -1 : 1;
      });
      setBookings(allAppoints.map((item,index)=>{
        // return {key:index+1, ...item, bookingDate: getLocalDate(item.bookingDate,item.bookingTime), bookingTime: getLocalTime(item.bookingDate,item.bookingTime)};
        return {key:index+1, ...item, };
      }))
    });

    // booking.get().then((querySnapshot) => {
    //   const tempDoc = querySnapshot.docs.map( (doc, index) => {
    //     console.log(doc.id);
    //   });
    //   // setBookings(tempDoc);
    //   console.log(tempDoc);
    // });

    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <AdminNavbar />
      <Spin spinning={loading}>
        <div
          style={{
            height: 1000,
          }}
        >
          <AdminAppointmentTable data={bookings} refresh={getData} />
        </div>
      </Spin>
        <AdminFooter/>
    </>
  );
};
export default AdminBookings;
