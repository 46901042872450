import React, { Fragment, useState, useCallback, useEffect } from "react";
import {
  Layout,
  Table,
  Typography,
  Statistic,
  Button,
  Modal,
  Input,
  Space,
} from "antd";
import { CLIENT_ID, API_KEY, DISCOVERY_DOCS, SCOPES } from "../googleAPIconfig";
import firebase from "firebase";
import 'firebase/auth';
import 'firebase/firestore';
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";
import { WechatOutlined, VideoCameraFilled, AudioFilled } from '@ant-design/icons';
import { 
  userGetLocalDate as getLocalDate,
  userGetLocalTime as getLocalTime,
  timeLeft
} from "../common/Time";

const { Content } = Layout;
const { Title, Text } = Typography;
const { TextArea } = Input;
const { Countdown } = Statistic;

const CommentBox = ({ val, record }) => {
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <Button
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <Text style={{ fontFamily: "GalanoMedium" }}>View Comment</Text>
      </Button>
      <Modal
        visible={visible}
        title="Comment"
        width={700}
        footer={null}
        onCancel={() => {
          setVisible(false);
        }}
        destroyOnClose
        style={{
          overflow: "hidden",
        }}
        bodyStyle={{
          overflow: "hidden",
        }}
      >
        <div
          style={{
            maxHeight: 300,
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
          }}
        >
          <Text
            style={{
              fontFamily: "GalanoLight",
              marginBottom: 10,
            }}
          >
            {val ? (
              val
            ) : (
              <Text style={{ fontStyle: "italic", fontFamily: "GalanoMedium" }}>
                {" "}
                No Comments!
              </Text>
            )}
          </Text>
        </div>
      </Modal>
    </>
  );
};

const deleteAllChats = (db) => {
  let ref = db.collection('chats')
  ref.onSnapshot(snapshot => {
    snapshot.docs.forEach(doc => {
      ref.doc(doc.id).delete()
        .catch(error => {
          console.log(error)
        })
    })
  })
}


const ActionButton = ({ db, record, history, selectedRecord }) => {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const currentDate = moment().format('DD-MMMM-YYYY');
    if (record.bookingDate == currentDate && record.status == "Approved") {
      const currentTime = moment().format('HH:mm a');
      var startTime = moment(record.bookingTime, "HH:mm a");
      var endTime = moment(currentTime, "HH:mm a");
      var total = moment.utc(endTime.diff(startTime)).format("HH:mm");

      if (total >= '00:00' && total <= '00:30') {
        setDisabled(false);
      } else if (total > '00:30' && total < '05:00') {
        firebase
          .firestore()
          .collection(`AdminBookings/${record.bookingDate}/bookings`)
          .doc(`${record.UID}${record.bookingTime}`)
          .update({
            status: "Completed",
          });
        firebase
          .firestore()
          .collection(`Bookings/${record.UID}/bookings`)
          .doc(`${record.UID}${record.bookingDate}${record.bookingTime}`)
          .update({
            status: "Completed",
          });
        setDisabled(true);
      }
    }
  }, []);

  useEffect(() => {
    if (selectedRecord.key == record.key && selectedRecord.type == record.type) {
      setDisabled(false);
    }
  }, [selectedRecord])
  return (
    <div>
      {record.appointment == "Virtual Chat" ? <Button
        type="primary"
        shape="circle"
        icon={<WechatOutlined />}
        size={'large'}
        style={{ flex: 0 }}
        disabled={disabled}
        onClick={() => {
          deleteAllChats(db);
          history.push('/chat', record.UID);
        }}
      /> : null}
      {record.appointment == "Video Call" ? <Button
        type="primary"
        shape="circle"
        icon={<VideoCameraFilled />}
        size={'large'}
        disabled={disabled}
        onClick={() => {
          history.push({
            pathname: '/video',
            state: { name: record.userName, photoURL: record.doctorPhotoURL ? record.doctorPhotoURL : null }
          });
        }}
      /> : null}
      {record.appointment == "Audio Call" ? <Button
        type="primary"
        shape="circle"
        icon={<AudioFilled />}
        size={'large'}
        disabled={disabled}
        onClick={() => {
          history.push({
            pathname: '/audio',
            state: { name: record.userName, photoURL: record.doctorPhotoURL ? record.doctorPhotoURL : null }
          });
        }}
      /> : null}
    </div>
  );
};


const AppointmentTable = ({ data }) => {
  const db = firebase.firestore();
  const history = useHistory();
  const location = useLocation();
  const [width, setWidth] = useState(window.innerWidth);
  const [selectedRecord, setSelectedRecord] = useState({});
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);

  var gapi = window.gapi;

  const makeMonth = (DMY) => {
    const time = DMY.split("-");
    const month = moment.months().indexOf(time[1]) + 1;
    if (month > 9) {
      return time[2] + "-" + month.toString() + "-" + time[0];
    } else {
      return time[2] + "-" + "0" + month.toString() + "-" + time[0];
    }
  };

  const makeHMS = (HMS) => {
    const time = HMS.split(" ");
    const HM = time[0].split(":");
    if (time[1] == "AM " || time[1] == "am") {
      return time[0].toString() + ":00";
    } else {
      return (parseInt(HM[0]) + 12).toString() + ":" + HM[1] + ":00";
    }
  };
  
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  const collapsed = () => {
    if (width < 768)
      return {
        title: <Text style={{ fontFamily: "GalanoMedium" }}>Record</Text>,
        render: (record) => (
          <Fragment>
          <Text style={{ fontFamily: "GalanoLight" }}>
            {getLocalDate(record.bookingDate,record.bookingTime)}
          </Text>
          <br />
          <Text style={{ fontFamily: "GalanoMedium" }}>
            {getLocalTime(record.bookingDate,record.bookingTime)}
          </Text>
            <br />
            <Text style={{ fontFamily: "GalanoLight" }}>
              {record.appointment}
            </Text>
            <br />
            <Text style={{ fontFamily: "GalanoLight" }}>
              {record.subscription}
            </Text>
            <br />
            <Space>
              <Text>{record.status}</Text>

              {/* {record.status === "Approved" && (
                <Button
                  onClick={async () => {
                    // addEvent(record);
                    try {
                      const date = record.bookingDate.split("-");
                      const time = record.bookingTime;
                      const start_time = new Date(
                        moment(
                          `${date[2]}-${moment.months().indexOf(date[1]) + 1}-${date[0]
                          } ${time.slice(0, 2)}:${time.slice(
                            3,
                            5
                          )}:00 ${time.slice(6, 8)}`,
                          "YYYY-MM-DD hh:mm:ss a"
                        )
                      );
                      if (ApiCalendar.sign) {
                        await ApiCalendar.handleSignoutClick();
                      }
                      await ApiCalendar.handleAuthClick();
                      await ApiCalendar.createEvent({
                        summary: record.appointment + " Appointment",
                        description:
                          "You have a nimble " +
                          record.appointment +
                          " appointment with Dr. Dee. ",
                        start: {
                          dateTime: moment(start_time.getTime()).format(),
                        },
                        end: {
                          dateTime: moment(
                            start_time.getTime() + 1800000
                          ).format(),
                        },

                      });
                      await firebase
                        .firestore()
                        .collection(`AdminBookings/${record.bookingDate}/bookings`)
                        .doc(`${record.UID}${record.bookingTime}`)
                        .update({
                          status: "In Progress",
                        });
                      await firebase
                        .firestore()
                        .collection(`Bookings/${record.UID}/bookings`)
                        .doc(`${record.UID}${record.bookingDate}${record.bookingTime}`)
                        .update({
                          status: "In Progress",
                        });
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  Add
                </Button>
              )} */}
            </Space>
            <br />
            <Text style={{ fontFamily: "GalanoLight" }}>{record.comment}</Text>
          </Fragment>
        ),
        responsive: ["xs", "sm"],
      };
    else {
      return {};
    }
  };

  const onFinish = (record) => {
    setSelectedRecord(record);
  }

  const columns = [
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>S.No</Text>,
      dataIndex: "key",
      key: "key",
      responsive: ["xs", "sm"],
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Booking Date</Text>,
      dataIndex: "bookingDate",
      key: "bookingDate",
      responsive: ["md", "lg"],
      render: (text, record) => <Text>{getLocalDate(record.bookingDate,record.bookingTime)}</Text>
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Booking Time</Text>,
      dataIndex: "bookingTime",
      key: "bookingTime",
      responsive: ["md", "lg"],
      render: (text, record) => <Text>{getLocalTime(record.bookingDate,record.bookingTime)}</Text>
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Appointment</Text>,
      dataIndex: "appointment",
      key: "appointment",
      responsive: ["md", "lg"],
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Subscription</Text>,
      dataIndex: "subscription",
      key: "subscription",
      responsive: ["md", "lg"],
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Status</Text>,
      dataIndex: "status",
      key: "status",
      responsive: ["md", "lg"],
      render: (text, record) => (
        <Space>
          <Text>{text}</Text>


          {/* {text === "Approved" && (

            <Button
              onClick={async () => {
                // addEvent(record);
                try {
                  const date = record.bookingDate.split("-");
                  const time = record.bookingTime;
                  const start_time = new Date(
                    moment(
                      `${date[2]}-${moment.months().indexOf(date[1]) + 1}-${date[0]
                      } ${time.slice(0, 2)}:${time.slice(
                        3,
                        5
                      )}:00 ${time.slice(6, 8)}`,
                      "YYYY-MM-DD hh:mm:ss a"
                    )
                  );
                  if (ApiCalendar.sign) {
                    await ApiCalendar.handleSignoutClick();
                  }
                  await ApiCalendar.handleAuthClick();
                  await ApiCalendar.createEvent({
                    summary: record.appointment + " Appointment",
                    description:
                      "You have a nimble " +
                      record.appointment +
                      " appointment with Dr. Dee. ",
                    start: {
                      dateTime: moment(start_time.getTime()).format(),
                    },
                    end: {
                      dateTime: moment(
                        start_time.getTime() + 1800000
                      ).format(),
                    },

                  });
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              Add
            </Button>
          )} */}
        </Space>
      ),
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Timer</Text>,
      dataIndex: "timer",
      key: "timer",
      responsive: ["md", "lg"],
      render: (text, record) => record.status !== "Approved" || timeLeft( getLocalDate(record.bookingDate,record.bookingTime),getLocalTime(record.bookingDate,record.bookingTime) ) < 0 ? <Text style={{ fontFamily: "GalanoLight" }}>N/A</Text> : <Countdown value={timeLeft( getLocalDate(record.bookingDate,record.bookingTime),getLocalTime(record.bookingDate,record.bookingTime) )} onFinish={() => onFinish(record)} />,
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Comments</Text>,
      dataIndex: "comment",
      key: "comment",
      render: (text, record) => <CommentBox val={text} record={record} />,
      responsive: ["md", "lg"],
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Actions</Text>,
      dataIndex: "actions",
      key: "actions",
      responsive: ["md", "lg"],
      render: (text, record) => <ActionButton db={db} record={record} history={history} selectedRecord={selectedRecord} />,
    },
    collapsed()
  ];

  return (
    <Content style={{ display: "flex", flexDirection: "column", padding: 20 }}>
      <Title style={{ display: "flex", fontFamily: "GalanoBold" }}>
        Booking
      </Title>

      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 10 }}
      />
    </Content>
  );
};

export default AppointmentTable;
