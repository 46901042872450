import React from "react";
import { Button, Card, Typography, Space, Input, Tooltip } from "antd";
import styled from "styled-components";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { CheckOutlined } from "@ant-design/icons";
import { Link, useHistory } from 'react-router-dom';
import './Cards.css'
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import firebase from "firebase";
import { getAccessToken,return_url,cancel_url } from "../../../common/getAccessToken";

const { Text } = Typography;
//const { TextArea } = Input;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px;
  justify-content: center;
`;
const OuterCard = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-width: 1px;
  border-radius: 10px;
  margin: 20px;
  max-width: 300px;
  justify-content: space-between;
`;
const UpperCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-color: 7a7a7a;
  border-bottom-width: 1px;
  height: 250px;
  margin-bottom: 10%;
`;
const LowerCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: auto;
  justify-content: flex-end;
  padding: 10px;
  padding-left: 20px;
  align-items: flex-start;
`;
const CardText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
`;
const Feature = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 5px;
  align-items: center;
`;

const payOrder = async (item, firestore, user, history) => {
  if (item.paypalproductid) {
    if (item.paypalplanid) {
      history.push(`/payment/${item.id}`)
    } else {
      const tokenResults = await getAccessToken();
      if (tokenResults) {
        const planDetails = await createPaypalPlan(tokenResults.data.access_token, item, firestore, item.paypalproductid);
        if (planDetails.data.id) {
          history.push(`/payment/${item.id}`)
        } else {
          console.log('error in create plan');
        }
      }
    }
  } else {
    const tokenResults = await getAccessToken();
    if (tokenResults) {
      const productDetails = await createPaypalProduct(tokenResults.data.access_token, item, firestore);
      if (productDetails.data.id) {
        const planDetails = await createPaypalPlan(tokenResults.data.access_token, item, firestore, productDetails.data.id);
        if (planDetails.data.id) {
          history.push(`/payment/${item.id}`);
        } else {
          console.log('error in create plan');
        }
      } else {
        console.log('error in create product');
      }
    }
  }
}

const accessToken = async (count,type,price,eachPrice,id) => {
  const tokenResults = await getAccessToken();
  if (tokenResults) {
    await createOrderBuy(tokenResults.data.access_token, count, type, eachPrice,price, id);
  }
}

const createOrderBuy = async (access_token, count, type, eachPrice, price, id) => {
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    }
  };

  const productBody = {
    "intent": "CAPTURE",
    "purchase_units": [
      {
        "reference_id": id,
        "amount": {
          "currency": "USD",
          "details": {
            "subtotal": eachPrice,
            "shipping": "0.00",
            "tax": "0.00"
          },
          "total": eachPrice
        },
        "items": [
          {
            "name": type,
            "price": price,
            "currency": "USD",
            "quantity": count.toString()
          }
        ]
      }
    ],

    "redirect_urls": {
      "return_url": return_url,
      "cancel_url": cancel_url
    }
  }
  axios.post('https://api.paypal.com/v1/checkout/orders', JSON.stringify(productBody), headers)
    .then((res) => {
      res.data.links.map((item) => {
        if (item.rel == 'approval_url') {
          window.location.replace(item.href);
        }
      })
    }).catch(err => console.log(err))
}

const createPaypalProduct = async (access_token, planObject, firestore) => {
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    }
  };

  const productBody = {
    "name": planObject.name + " Subscription",
    "description": planObject.name + " Subscription",
    "type": "SERVICE",
    "category": "HEALTH_AND_PERSONAL_CARE",
    "image_url": "https://firebasestorage.googleapis.com/v0/b/nimble-neurology.appspot.com/o/drdee.png?alt=media&token=e93a1374-f555-413a-8260-4725aa763d40",
    "home_url": "https://firebasestorage.googleapis.com/v0/b/nimble-neurology.appspot.com/o/drdee.png?alt=media&token=e93a1374-f555-413a-8260-4725aa763d40"
  }

  const result = await axios.post('https://api.paypal.com/v1/catalogs/products', JSON.stringify(productBody), headers);

  const productRef = firestore.collection("Plans").doc(planObject.id)

  const firebaseResult = await productRef.update({
    paypalproductid: result.data.id
  }).then(() => { return true; }).catch(err => console.log(err))

  return result; //product details
}

const createPaypalPlan = async (access_token, planObject, firestore, productid) => {
  const headers = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${access_token}`
    }
  };

  const planBody = {
    "product_id": productid,
    "name": planObject.name + " Subscription",
    "status": "ACTIVE",
    "description": planObject.name + " Subscription Monthly Plan",
    "billing_cycles": [{
      "frequency": {
        "interval_unit": "MONTH",
        "interval_count": 1
      },
      "tenure_type": "REGULAR",
      "sequence": 1,
      "total_cycles": 12,
      "pricing_scheme": {
        "fixed_price": {
          "value": planObject.price.toString(),
          "currency_code": "USD"
        }
      }
    }],
    "payment_preferences": {
      "auto_bill_outstanding": true,
      "setup_fee": {
        "value": "0",
        "currency_code": "USD"
      },
      "setup_fee_failure_action": "CANCEL",
      "payment_failure_threshold": 300
    },
    "taxes": {
      "percentage": "0",
      "inclusive": false
    },
    "quantity_supported": true
  }

  const result = await axios.post('https://api.paypal.com/v1/billing/plans', JSON.stringify(planBody), headers);

  const planRef = firestore.collection("Plans").doc(planObject.id)

  const firebaseResult = await planRef.update({
    paypalplanid: result.data.id
  }).then(() => { return true; }).catch(err => console.log(err))

  return result; //plan details
}

const PaymentPackage = ({ type, price, features, item, width, firestore, user, history, activePlan, id }) => {

  const [disabled, setDisabled] = useState(false);

  const handleFontSize = () => {
    if (width > 900) {
      return "16px";
    } else if (width > 700) {
      return "14px";
    } else {
      return "12px";
    }
  };

  useEffect(() => {
    if (activePlan.length > 0) {
      if (activePlan[0].planid == item.paypalplanid) {
        setDisabled(true);
      }
    }
  }, [activePlan])

  return (
    <OuterCard hoverable style={{ boxShadow: '0px 8px 15px rgb(40, 55, 239, 0.5)' }}>
      <UpperCard>
        {type == "Routine Subscription" ? (
          <Space
            style={{
              backgroundColor: "#2837ef",
              position: "absolute",
              left: "10%",
              top: "-5%",
              width: "80%",
              justifyContent: "center",
              borderRadius: 5,
              height: 40
            }}
          >
            <Text style={{ fontFamily: "GalanoMedium", color: "#fff" }}>
              RECOMMENDED
            </Text>
          </Space>
        ) : null}
        <Text
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            fontFamily: "GalanoMedium",
          }}
        >
          {type}
        </Text>

        {features.map((item, index) => {
          if (item == '') return null;
          return (
            <Feature key={index}>
              <CheckOutlined style={{ color: "#2837ef" }} />
              <Text
                style={{
                  marginLeft: "20px",
                  // color: "#7a7a7a",
                  fontSize: handleFontSize(),
                  color: "#000",
                  fontFamily: "GalanoLight",
                }}
              >
                {item}
              </Text>
            </Feature>
          )

        })}
      </UpperCard>
      <LowerCard>
        <CardText>
          <Text
            style={{
              marginBottom: "10px",
              fontFamily: "GalanoMedium",
            }}
          >
            Price:
          </Text>
        </CardText>
        <CardText>
          <Text
            style={{
              marginBottom: "10px",
              fontFamily: "GalanoLight",
              fontStyle: "italic",
            }}
          >
            ${parseInt(price).toFixed(2)}/month
          </Text>
        </CardText>
        <div className='Button-div'>
          <div disabled={disabled} onClick={() => {
            if (!disabled) {
              payOrder(item, firestore, user, history)
            }
          }} className={disabled ? "DisabledBuy" : "Buy"}><h4>BUY NOW</h4></div>
        </div>
      </LowerCard>
    </OuterCard>
  );
};

const EachPaymentPackage = ({ type, price, id }) => {

  const [eachPrice, setPrice] = useState(price);
  const [count, setCount] = useState(1);

  return (
    <OuterCard hoverable style={{ boxShadow: '0px 8px 15px rgb(40, 55, 239, 0.5)' }}>
      <UpperCard style={{ height: type == 'Audio Call' || type == 'Video Call' || type == 'Virtual Chat' ? 130 : null }}>
        <Text
          style={{
            fontWeight: "bold",
            fontSize: "16px",
            fontFamily: "GalanoMedium",
          }}
        >
          {type}
        </Text>
        <div>
          <div style={{ alignSelf: 'center', marginTop: 10 }}>
            <Text
              style={{
                marginBottom: "10px",
                fontFamily: "GalanoMedium",
              }}
            >
              Quantity: {count}
            </Text>
          </div>
          <div style={{ alignSelf: 'center', marginTop: 20 }}>
            <Button shape="circle"
              icon={<MinusOutlined />}
              size={'large'}
              type="primary"
              style={{ backgroundColor: 'blue', marginRight: 5 }}
              onClick={() => {
                setCount(count - 1)
                setPrice(parseInt(price * count - price).toFixed(2))
              }} />

            <Button shape="circle"

              icon={<PlusOutlined />}
              size={'large'}
              type="primary"
              style={{ backgroundColor: 'blue', marginLeft: 5 }}
              onClick={() => {
                setCount(count + 1)
                setPrice(parseInt(price * count + price).toFixed(2))
              }}
            />
          </div>
        </div>
      </UpperCard>
      <LowerCard>
        <CardText>
          <Text
            style={{
              marginBottom: "10px",
              fontFamily: "GalanoMedium",
            }}
          >
            Price:
          </Text>
        </CardText>
        <CardText>
          <Text
            style={{
              marginBottom: "10px",
              fontFamily: "GalanoLight",
              fontStyle: "italic",
            }}
          >
            ${parseInt(price * count).toFixed(2)}
          </Text>
        </CardText>
        <div className='Button-div'>
          <div onClick={() => accessToken(count, type, price, eachPrice, id)} className={"Buy"}><h4 style={{ color: '#ffffff' }}>BUY NOW</h4></div>
        </div>
      </LowerCard>
    </OuterCard>
  );
};

export const Payment = ({ plans, width, setTrigger, firestore, activePlan, id }) => {

  const [packages, setPackages] = useState([]);
  const auth = firebase.auth();
  const history = useHistory();

  const getPackages = (plans) => {
    var packageArray = [];
    plans.forEach(item => {
      var packageObject = {};
      packageObject.type = `${item.name} Subscription`;
      packageObject.price = item.price;
      packageObject.features = [
        `${item.profile} User Profile`,
        `${item.video > 0 ? item.video + ' Video chat/month' : ''}`,
        `${item.audio} Phone chat/month`,
        `${item.virtual} Virtual Chat Session (${item.virtualminutes} minutes each)/month`,
      ];
      packageObject.item = item;
      packageArray.push(packageObject);
    });
    setPackages(packageArray);
  }

  useEffect(() => {
    getPackages(plans)
  }, [])

  return (
    <CardContainer style={{ flexWrap: "wrap" }}>
      {packages.map(({ type, price, features, item }, index) => (
        <PaymentPackage
          key={index}
          type={type}
          price={price}
          item={item}
          features={features}
          width={width}
          setTrigger={setTrigger}
          firestore={firestore}
          user={auth}
          history={history}
          activePlan={activePlan}
        />
      ))}
    </CardContainer>
  );
};

export const EachPayment = ({ plans, width }) => {
  const [packages, setPackages] = useState([]);

  const getPackages = (plans) => {
    var packageArray = [];
    plans.forEach(item => {
      var packageObject = {};
      packageObject.type = `${item.audio == 1 ? 'Audio Call' : '' || item.video == 1 ? 'Video Call' : '' || item.chat == 1 ? 'Virtual Chat' : ''}`;
      packageObject.price = item.amount;
      packageObject.id = item.uid;
      packageObject.item = item;
      packageArray.push(packageObject);
    });
    setPackages(packageArray);
  }

  useEffect(() => {
    getPackages(plans)
  }, [])

  return (
    <CardContainer style={{ flexWrap: "wrap" }}>
      {packages.map(({ type, price, item, id }, index) => (
        <EachPaymentPackage
          key={index}
          type={type}
          width={width}
          price={price}
          item={item}
          id={id}
        />
      ))}
    </CardContainer>
  );
};
