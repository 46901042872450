import React, { useState,useEffect } from "react";
import { Form, Typography, Input, Button, Space, Modal } from "antd";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import firebase from "firebase";
import styled from "styled-components";

const { Text } = Typography;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const StyledPasswordInput = styled(Input.Password)`
  border-radius: 8px;
  height: 60px;
  input {
    font-size: 17px;
  }
`;

export const Login = ({ setUseEmail,trigger,setTrigger }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [resetEmail,setResetEmail] = useState("");
  const [passReset,setPassReset] = useState(false);
  const [error,setError] = useState(false);

  const onLogin = async ({ email, password }) => {
    try {
      setIsLoading(true);
      const result = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      window.localStorage.setItem(
        "access-token",
        JSON.stringify(firebase.auth().currentUser.uid)
      );
      setTrigger(false);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setError(true);
      setIsLoading(false);
    }
  };
  
  useEffect(
    ()=>{
      setPassReset(false);
    },[]
  );

  return (
    <Modal
    
    title={
      passReset ? (
        <Space direction="horizontal">
          <ArrowLeftOutlined
            onClick={() => {
              setPassReset(false);
            }}
          />
          <Typography
            style={{ fontWeight: "bold", fontFamily: "GalanoBold" }}
          >
            Back to Login
          </Typography>
        </Space>
      ) : null
    }
      visible={trigger}
      closable={true}
      closeIcon={()=>null}
      footer={null}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
      bodyStyle={{
        backgroundColor: "transparent",
        borderRadius: 30,
      }}
      onCancel={()=>{
        setTrigger(false);
      }}
      
    >
      <Space direction="vertical" style={{ padding: 20, alignItems: "center",display:"flex",justifyContent:"center" }}>
        <Text
          style={{
            fontSize: "40px",
            fontWeight: "600",
            fontFamily: "GalanoBold",
          }}
        >
          Nimble Neurology
        </Text>
        <Text
          style={{
            color: "#869ab8",
            fontSize: "19px",
            fontFamily: "GalanoMedium",
          }}
        >
          {! passReset ? "Sign in with your email" : "Input your email to get new password"}
        </Text>
        <Text
          style={{
            color: "red",
            fontSize: "19px",
            fontFamily: "GalanoMedium",
          }}
        >
          {error ? "Couldn't Sign In! Wrong Credentials!" : null}
        </Text>
      </Space>
      {
        passReset ? (
          <Space direction="vertical" style={{  alignItems: "center",display:"flex",justifyContent:"center" }}>
          <Input
            style={{
              borderRadius: 5,
              height: 60,
              fontSize: 17,
              fontFamily: "GalanoLight",
            }}
            placeholder="name@address.com"
            value={resetEmail}
            onChange={e=>setResetEmail(e.target.value)}
          />
          <Button
            loading={isLoading}
            style={{
              borderRadius: 5,
              height: 60,
              fontSize: 17,
              marginTop: 20,
              backgroundColor: "blue",
              fontFamily: "GalanoMedium",
            }}
            type="primary"
            onClick={async()=>{
              await firebase.auth().sendPasswordResetEmail(resetEmail)
                .then((res) => {
                  setPassReset(false);
                  setResetEmail("");
                  setTrigger(false);
                })
                .catch((error) => {
                  const errorCode = error.code;
                  const errorMessage = error.message;
                  console.log(errorCode,errorMessage);
                });
              // await firebase.auth().sendPasswordResetEmail(resetEmail);
            }}
          >
            Send New Password
          </Button>

          </Space>
        ) : (
          <><Form
          requiredMark={false}
          validateTrigger={false}
          layout="vertical"
          style={{
            flexDirection: "column",
            justifyContent: "center",
            display: "flex",
          }}
          onFinish={onLogin}
        >
          <Form.Item
            label={
              <Text
                style={{
                  fontSize: 20,
                  fontFamily: "GalanoMedium",
                }}
              >
                Email Address
              </Text>
            }
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input your valid email!",
              },
            ]}
          >
            <Input
              style={{
                borderRadius: 5,
                height: 60,
                fontSize: 17,
                fontFamily: "GalanoLight",
              }}
              placeholder="name@address.com"
            />
          </Form.Item>
          <Form.Item
            label={
              <Text
                style={{
                  fontSize: 20,
                  fontFamily: "GalanoMedium",
                }}
              >
                Password
              </Text>
            }
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <StyledPasswordInput
              style={{ borderRadius: 5, fontFamily: "GalanoLight" }}
              placeholder="Your Password"
            />
          </Form.Item>
          <Form.Item>
            <Button
              loading={isLoading}
              style={{
                borderRadius: 5,
                height: 60,
                fontSize: 17,
                marginTop: 20,
                backgroundColor: "blue",
                fontFamily: "GalanoMedium",
              }}
              block
              type="primary"
              htmlType="submit"
            >
              Login
            </Button>
            <Space
              style={{ paddingTop: 10 }}
            >
              <Button style={{fontFamily:"GalanoLight"}} 
              onClick={()=>{
                
              setPassReset(true);
              setError(false);
              }}>
                Forgot Password?
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <Button
          onClick={() => {
            setUseEmail(false);
            setError(false);
          }}
          style={{ margin: 10, fontFamily: "GalanoLight" }}
        >
          <Text>Use phone number ?</Text>
        </Button>
          </>
        )
      }
    </Modal>
  );
};
export default Login;
