import React, { Component } from 'react';
import '../Video.scss';
import AVControl from './AVControl';

class Track extends Component {

    constructor(props) {
        super(props)
        this.ref = React.createRef();

    }

    componentDidMount() {
        if (this.props.track.kind == "audio" || this.props.track.kind == "video") {
            if (this.props.track.kind == 'video') {
                this.props.track.dimensions.width = '100%';
                this.props.track.dimensions.height = '600px';
            }
            const child = this.props.track.attach();
            this.ref.current.classList.add(this.props.track.kind);
            this.ref.current.appendChild(child)

            this.props.track.on('disabled', (track) => {
                if (track.kind == 'video') {
                    var myVideo = document.querySelector(`#${this.props.identity} .track video`);
                    myVideo.style.display = 'none';
                }
            });
            this.props.track.on('enabled', (track) => {
                if (track.kind == 'video') {
                    var myVideo = document.querySelector(`#${this.props.identity} .track video`);
                    myVideo.style.display = 'block';
                }
            });
        }
    }

    render() {
        return (
            <div className="track" ref={this.ref}>
            </div>
        )
    }

}

export default Track;
