import React, { useState, useCallback, useEffect } from "react";
import { Typography, Space, Input, Form, Button, Upload,Select,Image,message } from "antd";
import image from "../../assets/doctorbg.jpg";
import AdminNavbar from "../../layout/AdminNavbar";
import styled from "styled-components";
import firebase from "firebase";
import { useHistory } from "react-router-dom";
import {
  DeleteFilled,
  UploadOutlined,
} from "@ant-design/icons";
import StatefulEditor from './StatefulEditor';
import AdminFooter from "../../layout/AdminFooter";

const { Text } = Typography;
const { Option} = Select;
const { TextArea } = Input;

const StyledInput = styled(Input)`
  border-radius: 8px;
  height: 60px;
  input {
    font-size: 17px;
    font-family: "GalandoLight";
  }
`;

const FormLabel = ({ label }) => {
  return (
    <Text
      style={{
        fontSize: 20,
        fontFamily: "GalanoMedium",
      }}
    >
      {label}
    </Text>
  );
};


const SubscriptionMenu = ({setSubscription})=>{
  const [val,setVal]=useState("Free");
  return (      
    <Select
    // defaultValue={"Select a package"}
    value={val}
    style={{ width: 350 }}  
    size="large"
    onChange={(item)=>{
      setVal(item);
      setSubscription(item);
    }}
  >
    <Option value="Free">Free</Option>
    <Option value="Basic Subscription" >Basic Subscription</Option>
    <Option value="Routine Subscription" >Routine Subscription</Option>
    <Option value="High Frequency Subscription" >High Frequency Subscription</Option>
  </Select>
  );

}


const ContentImage = ({ src, onDelete }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: 10,
        paddingBottom: 50,
        paddingTop: 30,
      }}
    >
      <Image src={src} />
      <Button
        onClick={onDelete}
        style={{
          marginTop: 10,
          fontFamily: "GalanoLight",
          backgroundColor: "#f1c40f",
          color: "#000",
        }}
        icon={<DeleteFilled />}
        type="primary"
        danger={true}
      >
        Delete
      </Button>
    </div>
  );
};

const StoryImage = ({ fun, notFun }) => {
  const [loading, setLoading] = useState(false);

  const onUpload = async (e) => {
    const isJpgOrPng =
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
      return;
    }
    const isLt3M = e.target.files[0].size / 1024 / 1024 < 3;
    if (!isLt3M) {
      message.error("Image must smaller than 3MB!");
      return;
    }
    try {
      const stamp = Date.now().toString();
      setLoading(true);
      const result = await firebase
        .storage()
        .ref("Pictures")
        .child(stamp + e.target.files[0].name)
        .put(e.target.files[0]);
      const url = await result.ref.getDownloadURL();
      fun(url);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notFun(error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: 200,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: 10,
        marginBottom: 50,
        marginTop: 30,
        backgroundColor: "rgb(240,240,240)",
      }}
    >
      <input type="file" accept="image/png, image/jpeg" onChange={onUpload} />
    </div>
  );
};


const CreateBlog = () => {
  // const [editorState, setEditor] = useState(() => EditorState.createEmpty())
  const [editorState, setEditor] = useState("")
  const [width, setWidth] = useState(window.innerWidth);
  const [subscription,setSubscription] =useState("Free");
  const [photoURL,setPhotoURL] =useState("");
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);
  const firestore = firebase.firestore();
  const storage = firebase.storage();
  const history = useHistory();
  
  
  const onChange = (value) => {
    setEditor({value});
    // if (this.props.onChange) {
    //   // Send the changes up to the parent component as an HTML string.
    //   // This is here to demonstrate using `.toString()` but in a real app it
    //   // would be better to avoid generating a string on each change.
    //   this.props.onChange(
    //     value.toString('html')
    //   );
    // }
  };


  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <>
      <AdminNavbar />
      <div style={{ minHeight: "70vh" }}>
        <Text
          style={{ fontSize: 50, marginLeft: "5%", fontFamily: "GalandoBold" }}
        >
          Create Blog
        </Text>
        <Form
          // form={form}
          requiredMark={true}
          validateTrigger={false}
          layout="vertical"
          onFinish={(values) => 
          {
            firestore.collection("Blogs").doc().set({
              ...values,
              subType:subscription,
              blogPhoto:photoURL,
              date:new Date().getTime() });
            history.replace("/blogs");
          }}
          style={{
            textAlign: "left",
            marginLeft: "5%",
            marginRight: "5%",
            padding: 20,
            fontWeight: 100,
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Form.Item
            label={<FormLabel label="Title" />}
            name="title"
            rules={[
              {
                required: true,
                message: "Please input blog title!",
              },
            ]}
          >
            <StyledInput placeholder="Title" />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="Picture" />}
            rules={[
              {
                required: false,
              },
            ]}
          >
            {/* <Upload
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={onUpload}
              headers={{
                authorization: "authorization-text",
              }}
              multiple={false}
            >
              <Button icon={<UploadOutlined />} style={{ margin: 5 }}>
                Upload Photo
              </Button>
            </Upload> */}
            
        {photoURL ? (
          <div style={{ height: "50vh", overflow: "hidden" }}>
            <ContentImage src={photoURL} onDelete={() => setPhotoURL(null)} />
          </div>
        ) : (
          <StoryImage
            fun={(url) => {
              setPhotoURL(url);
            }}
            notFun={(error) => {
              console.log(error);
            }}
          />
        )}


          </Form.Item>
        <Form.Item
          label={
            <Text
              style={{
                fontSize: 20,
                fontFamily: "GalanoMedium",
              }}
            >
              Subscription
            </Text>
          }
          // name="subType"
          rules={[
            {
              required: false,
              message: "Please select a package!",
            },
          ]}
        >
          <SubscriptionMenu setSubscription={setSubscription} />
        </Form.Item>
          <Form.Item
            label={<FormLabel label="Blog Description" />}
            name="description"
            rules={[
              {
                required: true,
                message: "Please input blog description!",
              },
            ]}
          >
            <TextArea placeholder="Description" rows={4} />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="Blog Text" />}
            name="text"
            rules={[
              {
                required: true,
                message: "Please input blog text!",
              },
            ]}
          >
            {/* <TextArea placeholder="Text" rows={15} /> */}

{/*             
          <RichTextEditor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            onEditorStateChange={setEditor}
            placeholder = 'Text'
            editorStyle={{
              fontFamily:"GalanoMedium"
            }}
          
          /> */}
          <StatefulEditor onChange={(val)=>{console.log(val)}} />

          
          </Form.Item>
          <Form.Item>
            <Button
              style={{
                borderRadius: 5,
                height: 60,
                fontSize: 17,
                marginTop: 20,
                backgroundColor: "#2837ef",
                fontFamily: "GalanoMedium",
              }}
              block
              type="primary"
              htmlType="submit"
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
      <AdminFooter />
    </>
  );
};

export default CreateBlog;
