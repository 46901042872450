import React, { Component } from 'react';
import '../Video.scss';
import Track from './Track';
import CallCenterImage from '../../../assets/call_center_icons.png';

class Participant extends Component {

    constructor(props) {
        super(props);

        const existingPublications = Array.from(this.props.participant.tracks.values());
        const existingTracks = existingPublications.map(publication => publication.track);
        const nonNullTracks = existingTracks.filter(track => track !== null)

        this.state = {
            tracks: nonNullTracks
        }
    }

    componentDidMount() {
        if (!this.props.localParticipant) {
            this.props.participant.on('trackSubscribed', track => this.addTrack(track));

        }
    }

    addTrack(track) {
        this.setState({
            tracks: [...this.state.tracks, track]
        });
    }

    render() {
        return (
            <div className="participant" id={this.props.participant.identity}>
                <div className="identity">{this.props.participant.identity}</div>
                <img src={this.props.localParticipant ? this.props.user.photoURL ? this.props.user.photoURL : CallCenterImage : this.props.user.privilege == 'admin' ? this.props.remoteUser.photoURL != "" ? this.props.remoteUser.photoURL : CallCenterImage : this.props.photo ? this.props.photo : CallCenterImage} width={200} height={200} style={{ alignSelf: 'center', position: 'absolute' }} />
                {
                    this.state.tracks.map(track =>
                        <Track key={track} filter={this.state.filter} track={track} local={this.props.localParticipant} identity={this.props.participant.identity} />)
                }

            </div>
        );
    }

}

export default Participant;
