import React, { useState, useCallback, useEffect } from "react";
import { Typography, Input, Row,Col } from "antd";
import AdminNavbar from "../../layout/AdminNavbar";
import AdminFooter from "../../layout/AdminFooter";
import BlogCard from './BlogCard';
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import firebase from 'firebase';

const { Text, Paragraph } = Typography;
const {Search} =Input;

const AdminBlogs = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [blogs, setBlogs] = useState([]);
  const [selected,setSelected] = useState({
    title: "",
    description: "",
    text: "",
    date: "",
    blogPhoto: "",
  });
  const [find,setFind] = useState("");
  const firestore = firebase.firestore();
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const blogCollection = firestore.collection("Blogs");
    blogCollection.get().then((querySnapshot) => {
      const _blogs = querySnapshot.docs.map((doc, index) => {
        return { key: index + 1, ...doc.data() };
      });
      setBlogs(_blogs);
    });
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <>
      <AdminNavbar />
      <Row
        style={{
          height: 100,
          alignItems: "center",
          paddingLeft: 100,
          paddingRight: 100,
          flexWrap:"wrap"
        }}
      >
        <Col span={8}>
          <Text
            style={{
              fontFamily: "GalanoBold",
              fontSize: 30,
              fontStyle: "italic",
            }}
          >
            Blogs
          </Text>
        </Col>
        <Col span={8}>
          <Search
            size="large"
            placeholder="Blog Title"
            value={find}
            onChange={(e) => {
              setFind(e.target.value);
            }}
          />
        </Col>
        <Col span={8} style={{justifyContent:"flex-end",display:"flex",flexDirection:"row"}}>
        <Text
          style={{
            fontFamily: "GalanoBold",
            fontSize: 22,
            fontStyle: "italic",
          }}
        >
          <Link to="/create-blog">
          <PlusOutlined/> Create Blog</Link>
        </Text>
        </Col>
      </Row>

      <div
        style={{
          textAlign: "left",
          marginLeft: "5%",
          marginRight: "5%",
          padding: 20,
          fontWeight: 100,
          display: width > 1000 ? "flex" : "block",
          flexWrap: "wrap",
          justifyContent: "center",
          minHeight: 700,
        }}
      >
      { find === "" ? 
      blogs.map(({ title, description, text, date, blogPhoto }, index) => (
        <BlogCard
          key={index}
          title={title}
          description={description}
          text={text}
          date={date}
          blogPhoto={blogPhoto}
        />
      ))
      :
      blogs.map(({ title, description, text, date, blogPhoto }, index) => {
        return title.toLowerCase().search(find)!==-1 ? 
        (
          <BlogCard
            key={index}
            title={title}
            description={description}
            text={text}
            date={date}
            blogPhoto={blogPhoto}
          />
        ):null
      }
      )
      }
      </div>
      <AdminFooter />
    </>
  );
};

export default AdminBlogs;
