import React, { useState, useCallback, useEffect } from "react";
import { Typography, Space, Card, Input, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import back from "../../assets/back.jpg";
import UserNavbar from "../../layout/UserNavbar";
import PageFooter from "../../layout/PageFooter";
import BlogCard from "./BlogCard";
import moment from "moment";
import firebase from "firebase";

const { Text, Paragraph } = Typography;

const { Search } = Input;

const UserBlogs = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [blogs, setBlogs] = useState([]);
  const [search, setSearch] = useState([]);
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);
  const auth = firebase.auth();
  const firestore = firebase.firestore();

  const blogSelection = async (_blogs) => {
    if (!auth.currentUser) {
      setBlogs(
        _blogs.filter((item) => {
          return item.subType === "Free";
        })
      );
    } else {
      const user = firestore.collection("Users").doc(auth.currentUser.uid);
      const { subType } = (await user.get()).data();
      if (subType == "") {
        setBlogs(
          _blogs.filter((item) => {
            return item.subType == "Free";
          })
        );
      }
      if (subType == "Basic Subscription") {
        setBlogs(
          _blogs.filter((item) => {
            return (
              item.subType == "Free" ||
              item.subType == "Basic Subscription"
            );
          })
        );
      }
      if (subType == "Routine Subscription") {
        setBlogs(
          _blogs.filter((item) => {
            return item.subType != "High Frequency Subscription";
          })
        );
      }
      if (subType == "High Frequency Subscription") {
        setBlogs(_blogs);
      }
    }
  };

  // const blogSearch = async (_blogs) => {
  //   const user = firestore.collection("Users").doc(auth.currentUser.uid);
  //   const { subType } = (await user.get()).data();
  //   setBlogs(
  //     _blogs.filter((item) => {
  //       return item.title.toLowerCase().includes(search);
  //     })
  //   );
  // };

  useEffect(() => {
    const blogCollection = firestore.collection("Blogs");
    blogCollection.get().then((querySnapshot) => {
      const _blogs = querySnapshot.docs.map((doc, index) => {
        return { key: index + 1, ...doc.data() };
      });

      blogSelection(_blogs);
    });
  }, [search]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <>
      <UserNavbar />
      <Row
        style={{
          height: 100,
          alignItems: "center",
          paddingLeft: 100,
          paddingRight: 100,
        }}
      >
        <Col span={10}>
          <Text
            style={{
              fontFamily: "GalanoBold",
              fontSize: 30,
              fontStyle: "italic",
            }}
          >
            Blogs
          </Text>
        </Col>
        <Col>
          <Search
            size="large"
            placeholder="Blog Title"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Col>
      </Row>

      <div
        style={{
          textAlign: "left",
          marginLeft: "5%",
          marginRight: "5%",
          padding: 20,
          fontWeight: 100,
          display: width > 1000 ? "flex" : "block",
          flexWrap: "wrap",
          justifyContent: "center",
          minHeight: 700,
        }}
      >
        {search === ""
          ? blogs.map(
              ({ title, description, text, date, blogPhoto }, index) => (
                <BlogCard
                  key={index}
                  title={title}
                  description={description}
                  text={text}
                  date={date}
                  blogPhoto={blogPhoto}
                />
              )
            )
          : blogs.map(
              ({ title, description, text, date, blogPhoto }, index) => {
                return title.toLowerCase().search(search) !== -1 ? (
                  <BlogCard
                    key={index}
                    title={title}
                    description={description}
                    text={text}
                    date={date}
                    blogPhoto={blogPhoto}
                  />
                ) : null;
              }
            )}
      </div>
      <PageFooter />
    </>
  );
};

export default UserBlogs;
