import React from 'react'
import UserNavbar from "../../layout/UserNavbar";
import PageFooter from "../../layout/PageFooter";
import { Image } from 'antd';
import NotFoundImage from "../../assets/404.png";

export default function NotFound() {
  return (
    <>
      <UserNavbar />
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: "50vh"
        }}>
            <Image
                width={260}
                src={NotFoundImage}
                preview={false}
            />
        </div>
        <div style={{position:"fixed",bottom:0,width:"100%"}}>
            <PageFooter/>
        </div>
    </>
  )
}