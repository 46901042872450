import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatRelative } from 'date-fns';

const formatDate = date => {
    let formattedDate = '';
    if (date) {
        // Convert the date in words relative to the current date
        formattedDate = formatRelative(date, new Date());
        // Uppercase the first letter
        formattedDate =
            formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    }
    return formattedDate;
};

const ChatMessage = ({
    createdAt = null,
    text = '',
    image = '',
    displayName = '',
    photoURL = '',
}) => {
    const [open, setOpen] = useState(false);
    const handleShowDialog = () => {
        setOpen(!open);
    }
    if (!text && !image) return null;

    return (
        <div style={{ display: 'flex', backgroundColor: 'lightgrey', margin: 10, padding: 10 }}>
            {photoURL ? (
                <img
                    src={photoURL}
                    alt="Avatar"
                    className="rounded-full mr-4"
                    width={45}
                    height={45}
                />
            ) : null}
            <div>
                <div style={{ fontSize: 16 }}>
                    {displayName ? (
                        <p>{displayName}</p>
                    ) : <p>{'Patient'}</p>}
                </div>
                {text ? <p style={{ fontSize: 14 }}>{text}</p> : null}
                {image ? <img src={image} width="200" height="200" onClick={handleShowDialog} /> : null}
            </div>
            {open && (
                <dialog
                    style={{ position: 'absolute', width: '70%', height: '50%', alignSelf: 'center' }}
                    open
                    onClick={handleShowDialog}
                >
                    <img
                        src={image}
                        onClick={handleShowDialog}
                        width="100%"
                        height="100%"
                    />
                </dialog>
            )}

        </div>
    );
};

ChatMessage.propTypes = {
    text: PropTypes.string,
    createdAt: PropTypes.shape({
        seconds: PropTypes.number,
    }),
    displayName: PropTypes.string,
    photoURL: PropTypes.string,
};

export default ChatMessage;