import React from "react";
import { Typography, Space } from "antd";
import UserNavbar from "../../layout/UserNavbar";
import PageFooter from "../../layout/PageFooter";

const { Text } = Typography;

export default function DelAccount() {
  return (
    <>
      <UserNavbar />
      <div
        style={{
          textAlign: "left",
          marginLeft: "5%",
          marginRight: "10%",
          padding: 20,
          fontWeight: 100,
        }}
      >
        <Text
          style={{
            fontFamily: "GalanoBold",
            fontSize: 30,
            fontStyle: "italic",
          }}
        >
          Delete Account
        </Text>
        <hr
          style={{
            marginTop: "1%",
            borderTop: "5px solid rgb(40, 55, 239)",
            borderRadius: 3,
            width: 230,
            marginLeft: 0,
          }}
        />
        <p>
          Every user can delete their account from your app, to delete your
          account and all its data you have to follow few steps
        </p>
        <ol>
          <li>
            <p>In app login to your account which you want to delete</p>
          </li>
          <li>
            <p>On main home screen in top left there is a gear icon click on it</p>
          </li>
          <li>
            <p>Then in settings screen at the bottom there is a delete account option click on it and it will ask you whether you want to delete it or not</p>
          </li>
        </ol>
      </div>
      <div style={{position:"absolute",bottom:0,width:"100%"}}>
        <PageFooter />
      </div>
    </>
  );
}
