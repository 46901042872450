import axios from "axios";

const getAccessToken = async () => {
    var username = 'AZlrbAJf6ipG1rrptJo_WpWB_RSvmD8mFuYq2XLjnczYqFKXJCvPUchjjq_eARpzzn_Sa33fo3x8gzD7';
    var password = 'ECct-9lfCUll0UfjnpAUBB_nVjDIPC7pttT4RzgR2FDjwba9OQWDB9y_LyJgLmXuIfpLOsXcVp4qrCRc'
  
    const token = `${username}:${password}`;
    const encodedToken = Buffer.from(token).toString('base64');
  
    const headers = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Basic ${encodedToken}`,
      }
    };
    const params = 'grant_type=client_credentials';
    const result = await axios.post('https://api.paypal.com/v1/oauth2/token', params, headers);
    return result;
}

const return_url = "https://nimbleneuro.com/profile";
const cancel_url = "https://nimbleneuro.com/profile";


export {
    getAccessToken,
    return_url,
    cancel_url
}