import React from "react";
import { useState, useLayoutEffect, useEffect, useCallback } from "react";
import { Layout, Row, Col, Typography, Divider, Space, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
import AppointmentModal from "../../components/AppoinmentModal";
import googlePlay from "../../assets/googleplay.png";
import appleStore from "../../assets/ios.png";
import "./Footer.css";

const { Footer } = Layout;
const { Text, Title } = Typography;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      // setSize([window.innerWidth, window.innerHeight]);
      setSize([window.innerWidth]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    console.log("size: ", size);
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  console.log("size: ", size);
  return size;
}
//   function ShowWindowDimensions(props) {
//     const [width, height] = useWindowSize();
//     // return <span>Window size: {width} x {height}</span>;
//     return width
//   }

function PageFooter({ refreshData }) {
  const [width, setWidth] = useState(window.innerWidth);
  const [book, setBook] = useState(false);
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  const handlerIcon = (text) => {
    if (width > 700) {
      return "fab " + text + " fa-2x circle-icon";
    } else {
      return "fab " + text + " fa-lg circle-icon";
    }
  };

  const handlerSize = () => {
    if (width > 900) {
      return "50px";
    } else if (width > 700) {
      return "40px";
    } else {
      return "30px";
    }
  };

  const handleFontSize = () => {
    if (width > 900) {
      return "20px";
    } else if (width > 600) {
      return "15px";
    } else {
      return "12px";
    }
  };

  return (
    <>
      <Footer
        style={{
          background: "linear-gradient(90deg, #2837ef 0%, #1557e3 100%)",
          textAlign: "center",
          padding: 10,
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Col
            xl={7}
            lg={7}
            md={7}
            xs={7}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: 20,
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontFamily: "GalanoMedium",
                fontSize: handleFontSize(),
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Stay Connected
            </Text>
            <Space
              style={{
                paddingTop: 10,
                flexWrap: "wrap",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {/* <i className="fab fa-facebook-f fa-2x circle-icon" />
              <i className="fab fa-twitter fa-2x circle-icon" />
              <i className="fab fa-instagram fa-2x circle-icon" />
              <i className="fab fa-pinterest-p fa-2x circle-icon" />
              <i className="fab fa-linkedin-in fa-2x circle-icon" />
              <i className="fab fa-youtube fa-2x circle-icon" /> */}
              <i
                className={handlerIcon("fa-facebook-f")}
                style={{
                  height: handlerSize(),
                  width: handlerSize(),
                  margin: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <i
                className={handlerIcon("fa-twitter")}
                style={{
                  height: handlerSize(),
                  width: handlerSize(),
                  margin: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <i
                className={handlerIcon("fa-instagram")}
                style={{
                  height: handlerSize(),
                  width: handlerSize(),
                  margin: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <i
                className={handlerIcon("fa-pinterest-p")}
                style={{
                  height: handlerSize(),
                  width: handlerSize(),
                  margin: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <i
                className={handlerIcon("fa-linkedin-in")}
                style={{
                  height: handlerSize(),
                  width: handlerSize(),
                  margin: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <i
                className={handlerIcon("fa-youtube")}
                style={{
                  height: handlerSize(),
                  width: handlerSize(),
                  margin: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </Space>
          </Col>
          {/* <div className="vl2" /> */}
          <Divider
            type="vertical"
            style={{
              background:
                "linear-gradient(180deg,transparent,#fff,transparent)",
              width: 2,
              height: 150,
            }}
          />
          <Col
            xl={7}
            lg={7}
            md={7}
            xs={7}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: 20,
            }}
          >
            {window.localStorage.getItem("access-token") != "" ?
            <Text
              style={{
                color: "#fff",
                fontFamily: "GalanoMedium",
                fontSize: handleFontSize(),
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Find Out What’s Happening in Your Community
            </Text>:
              null
            }
            {window.localStorage.getItem("access-token") != "" ? (
              <Space
                style={{
                  paddingTop: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Link
                  to="/newsletter"
                  style={{ marginTop: 5, alignSelf: "center",}}
                >
                  <Button style={{
                    width: width > 800 ? "auto" : "100px"
                  }}>
                    <i
                      className="fas fa-upload"
                      style={{
                        color: "#013da5",
                        alignSelf: "center",
                        paddingRight: width > 800 ? "20px" : "0px",
                      }}
                    />
                    {width > 800 ? (
                      <Text
                        style={{
                          color: "#013da5",
                          fontFamily: "GalanoMedium",
                          fontSize: "14px",
                        }}
                      >
                        STAY INFORMED
                      </Text>
                    ) : null}
                  </Button>
                </Link>
              </Space>
            ) : 
            <>
            <a href="https://play.google.com/store/apps/details?id=com.nimbleapp" 
              style={{display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "center"}}>
              <img src={googlePlay} alt="downlaod app" style={{ width: "150px",height:50 }} />
            </a>
            <a href="https://apps.apple.com/us/app/nimble-neuro/id1643409713?platform=iphone"
              style={{paddingTop:14,display: "flex",flexDirection: "column",justifyContent: "center",alignItems: "center"}}>
              <img src={appleStore} alt="downlaod app" style={{ width: "150px" }} />
            </a>
            </>
            }
          </Col>

          <Divider
            type="vertical"
            style={{
              background:
                "linear-gradient(180deg,transparent,#fff,transparent)",
              width: 2,
              height: 150,
            }}
          />

          <Col
            xl={7}
            lg={7}
            md={7}
            xs={7}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: 20,
              paddingLeft: 20,
              textAlign: "left",
            }}
          >
            <Link
              to="/"
              style={{
                color: "#fff",
                fontFamily: "GalanoMedium",
                fontSize: handleFontSize(),
                paddingBottom: 15,
              }}
            >
              Home
            </Link>
            <Link
              to="/contact-us"
              style={{
                color: "#fff",
                fontFamily: "GalanoMedium",
                fontSize: handleFontSize(),
                paddingBottom: 15,
              }}
            >
              Contact Us
            </Link>
            <Link
              to="/privacy-policy"
              style={{
                color: "#fff",
                fontFamily: "GalanoMedium",
                fontSize: handleFontSize(),
                paddingBottom: 15,
              }}
            >
              Privacy Policy
            </Link>
            {window.localStorage.getItem("access-token") != "" ? (
              <div onClick={() => setBook(!book)}>
                <Text
                  style={{
                    cursor:"pointer",
                    color: "#fff",
                    fontFamily: "GalanoMedium",
                    fontSize: handleFontSize(),
                    paddingBottom: 15,
                  }}
                >
                  Book Appointment
                </Text>
              </div>
            ) : null}
          </Col>
        </Row>
      </Footer>

      <Row
        style={{
          backgroundColor: "#000",
          padding: 15,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Text
          style={{
            color: "#fff",
            fontFamily: "GalanoMedium",
            fontSize: 15,
          }}
        >
          &copy;{new Date().getFullYear()} Neuro Nimble | All rights reserved |
          Terms Of Service | Privacy
        </Text>
      </Row>
      <AppointmentModal
        visible={book}
        setVisible={setBook}
        refreshData={refreshData}
      />
    </>
  );
}

export default PageFooter;

{
  /* <div className="vl2"></div>
<div className="vl2"></div>
<div className="vl2"></div> */
}

{
  /* <div className="footerRow">
    <div className="footerCol">
        <Link to="/" className="navbar-logo" style={{textDecoration: 'none'}}>
            <text>Home</text>
            <div className="vl1"></div>
        </Link>
    </div>
    
    <div className="footerCol">
        <Link to="/" className="navbar-logo" style={{textDecoration: 'none'}}>
            <text>Careers</text>
            <div className="vl2"></div>
        </Link>
    </div>
    
    <div className="footerCol">
        <Link to="/" className="navbar-logo" style={{textDecoration: 'none'}}>
            <text>Contact Us</text>
            <div className="vl3"></div>
        </Link>
    </div>
    
    <div className="footerCol">
        <Link to="/" className="navbar-logo" style={{textDecoration: 'none'}}>
            <text>Book Appointment</text>
        </Link>
    </div>
</div>
<div className="footerRow">
    <div className="footerCol3">
        <text>Contact No.</text>   
    </div>
    <div className="footerCol3">
        <text>Address</text>
    </div>
    <div className="footerCol4">
        <text>Follow Us On</text>
    </div>
</div>
<div className="footerRow3">
    <div className="footerCol3">
        <text>+91 8054646064</text>   
    </div>
    <div className="footerCol33">
        <text>PO Box 43473 Birmingham, AL 35243</text>
    </div>
    <div className="footerCol5">
        <i className="fab fa-facebook-f fa-2x" style={{}}></i>
        <i className="fab fa-twitter fa-2x" style={{marginLeft:'5%'}}></i>
        <i className="fab fa-instagram fa-2x" style={{marginLeft:'5%'}}></i>
        <i className="fab fa-linkedin-in fa-2x" style={{marginLeft:'5%'}}></i>
        <i className="fab fa-youtube fa-2x" style={{marginLeft:'5%'}}></i>
    </div>
</div> */
}
