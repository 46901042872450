import React, { Fragment, useState, useCallback, useEffect } from "react";
import { Layout, Table, Typography, Select, Button, Modal, Input, Statistic } from "antd";
import firebase from "firebase";
import 'firebase/auth';
import 'firebase/firestore';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { WechatOutlined, VideoCameraFilled, AudioFilled } from '@ant-design/icons';
import ApiCalendar from "react-google-calendar-api";
import { 
  adminGetLocalDate as getLocalDate,
  adminGetLocalTime as getLocalTime,
  timeLeft,
  getTime
} from "../common/Time";

const { Content } = Layout;
const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const { Countdown } = Statistic;

const StatusDropDown = ({ val, record, auth }) => {
  const [value, setValue] = useState(val);
  const [deleteModal, setDeleteModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (value === "Cancel") {
      setDisabled(true);
    }
  }, [value]);

  const confirmCancellation = async () => {

    try {
      const _userData = await firebase.firestore().collection("Users").doc(record.UID).get();
      const fcm = _userData.data().fcm;
      const instant = moment.now().toString();
      if (fcm) {
        await firebase.functions().httpsCallable("bookingCancelled")({ fcm: fcm, type: record.appointment, time: `${getLocalDate(record.bookingDate,record.bookingTime)} ${getLocalTime(record.bookingDate,record.bookingTime)}` }).then((val) => {
          console.log(val);
          firebase.firestore().collection("Notifications").doc(record.UID).collection("notifications").doc(instant).set({
            time: instant,
            text: `${record.appointment} booked on ${getLocalDate(record.bookingDate,record.bookingTime)} ${getLocalTime(record.bookingDate,record.bookingTime)} is cancelled!`,
            code: 2,
            opened: false,
            booking: `${getLocalDate(record.bookingDate,record.bookingTime)} ${getLocalTime(record.bookingDate,record.bookingTime)}`
          })
        }).catch(err => console.log(err));

      }
      firebase
        .firestore()
        .collection(`AdminBookings/${record.bookingDate}/bookings`)
        .doc(`${record.UID}${record.bookingTime}`)
        .update({
          status: "Cancel",
        });
      firebase
        .firestore()
        .collection(`Bookings/${record.UID}/bookings`)
        .doc(`${record.UID}${record.bookingDate}${record.bookingTime}`)
        .update({
          status: "Cancel",
        });
      setDeleteModal(false);
      setValue("Cancelled");
    } catch (error) {
      console.log(error);
    }
  };


  const approveAppointment = async () => {
    try {
      // const _date = getLocalDate(record.bookingDate,record.bookingTime);
      // const date = _date.split("-");
      // const time = getLocalTime(record.bookingDate,record.bookingTime);
      // const start_time = new Date(
      //   moment(
      //     `${date[2]}-${moment.months().indexOf(date[1]) + 1}-${date[0]
      //     } ${time.slice(0, 2)}:${time.slice(
      //       3,
      //       5
      //     )}:00 ${time.slice(6, 8)}`,
      //     "YYYY-MM-DD hh:mm:ss a"
      //   )
      // );
      
      // if (ApiCalendar.sign) {
      //   await ApiCalendar.handleSignoutClick();
      // }
      // await ApiCalendar.handleAuthClick();
      // const result = await ApiCalendar.listEvents();
      // await ApiCalendar.createEvent({
      //   summary: record.appointment + " Appointment",
      //   description:
      //     "You have a nimble " +
      //     record.appointment +
      //     " appointment with Dr. Dee. ",
      //   start: {
      //     dateTime: moment(start_time.getTime()).format(),
      //   },
      //   end: {
      //     dateTime: moment(
      //       start_time.getTime() + 1800000
      //     ).format(),
      //   },

      // });
      const _userData = await firebase.firestore().collection("Users").doc(record.UID).get();
      const fcm = _userData.data().fcm;
      const instant = moment.now().toString();
      if (fcm) {
        firebase.functions().httpsCallable("bookingApproved")({ fcm: fcm, type: record.appointment, time: `${getLocalDate(record.bookingDate,record.bookingTime)} ${getLocalTime(record.bookingDate,record.bookingTime)}` }).then((val) => {
          // console.log(val);
          firebase.firestore().collection("Notifications").doc(record.UID).collection("notifications").doc(instant).set({
            time: instant,
            text: `${record.appointment} booked on ${getLocalDate(record.bookingDate,record.bookingTime)} ${getLocalTime(record.bookingDate,record.bookingTime)} is approved!`,
            code: 3,
            opened: false,
            booking: `${getLocalDate(record.bookingDate,record.bookingTime)} ${getLocalTime(record.bookingDate,record.bookingTime)}`
          })
        }).catch(err => console.log(err));
      }
      

      const doctorData = await firebase.firestore().collection("Users").doc(auth.currentUser.uid).get();

      firebase
        .firestore()
        .collection(`AdminBookings/${record.bookingDate}/bookings`)
        .doc(`${record.UID}${record.bookingTime}`)
        .update({
          status: "Approved",
          doctorPhotoURL: doctorData.data().photoURL
        });
      firebase
        .firestore()
        .collection(`Bookings/${record.UID}/bookings`)
        .doc(`${record.UID}${record.bookingDate}${record.bookingTime}`)
        .update({
          status: "Approved",
          doctorPhotoURL: doctorData.data().photoURL
        });
      setValue("Approved");
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <>
      <Select
        style={{ width: 350 }}
        size="large"
        disabled={disabled}
        onChange={async (item) => {
          if (item !== "Cancel") {
            approveAppointment(auth);
            // setValue(item);
            // await firebase
            //   .firestore()
            //   .collection(`AdminBookings/${record.bookingDate}/bookings`)
            //   .doc(`${record.UID}${record.bookingTime}`)
            //   .update({
            //     status: item,
            //   });
            // await firebase
            //   .firestore()
            //   .collection(`Bookings/${record.UID}/bookings`)
            //   .doc(`${record.UID}${record.bookingDate}${record.bookingTime}`)
            //   .update({
            //     status: item,
            //   });
          } else {
            setDeleteModal(true);
          }
        }}
        value={value}
      >
        <Option
          disabled={true}
          value="Pending"
          style={{
            fontFamily: "GalanoLight",
          }}
        >
          Pending
        </Option>
        <Option
          value="Approved"
          style={{
            fontFamily: "GalanoLight",
          }}
        >
          Approved
        </Option>
        <Option
          value="Completed"
          style={{
            fontFamily: "GalanoLight",
          }}
        >
          Completed
        </Option>
        <Option
          value="Cancel"
          style={{
            fontFamily: "GalanoLight",
          }}
        >
          Cancel
        </Option>
      </Select>

      <Modal
        visible={deleteModal}
        onCancel={() => setDeleteModal(false)}
        onOk={confirmCancellation}
      >
        <Text> Are you sure you want to cancel this booking ? </Text>
      </Modal>
    </>
  );
};

const CommentBox = ({ val, record }) => {
  const [comment, setComment] = React.useState(val);
  const [visible, setVisible] = React.useState(false);
  return (
    <>
      <Button
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <Text
          style={{
            fontFamily: "GalanoLight",
          }}
        >
          Add Comment
        </Text>
      </Button>
      <Modal
        visible={visible}
        title="Comment"
        width={700}
        footer={null}
        onCancel={() => {
          setVisible(false);
        }}
        destroyOnClose
        style={{
          overflow: "hidden",
        }}
        bodyStyle={{
          overflow: "hidden",
        }}
      >
        <div
          style={{
            maxHeight: 300,
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
          }}
        >
          <TextArea
            key="textarea"
            placeholder="Add a comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            style={{ marginBottom: 10, fontFamily: "GalanoLight" }}
          />
          ,
          <Button
            key="save"
            type="primary"
            onClick={() => {
              firebase
                .firestore()
                .collection(`AdminBookings/${record.bookingDate}/bookings`)
                .doc(`${record.UID}${record.bookingTime}`)
                .update({
                  comment: comment,
                });
              firebase
                .firestore()
                .collection(`Bookings/${record.UID}/bookings`)
                .doc(`${record.UID}${record.bookingDate}${record.bookingTime}`)
                .update({
                  comment: comment,
                });
              setVisible(false);
            }}
            style={{
              fontFamily: "GalanoLight",
            }}
          >
            Submit Comment
          </Button>
        </div>
      </Modal>
    </>
  );
};

const ActionButton = ({ db, record, history, selectedRecord }) => {
  const [disabled, setDisabled] = useState(true);


  const getDate = (bookingDate) => {
    const _date = bookingDate.split("-");
    if(moment.months().indexOf(_date[1]) + 1 <10){
        return `${_date[2]}-0${moment.months().indexOf(_date[1]) + 1}-${_date[0]}`
    }
    return `${_date[2]}-${moment.months().indexOf(_date[1]) + 1}-${_date[0]}`
}

  useEffect(() => {
    // const currentDate = moment().format('DD-MMMM-YYYY');
    // if (record.bookingDate == currentDate && record.status == "Approved") {
    //   const currentTime = moment().format('HH:mm a');
    //   var startTime = moment(record.bookingTime, "HH:mm a");
    //   var endTime = moment(currentTime, "HH:mm a");
    //   var total = moment.utc(endTime.diff(startTime)).format("HH:mm");

    //   if (total >= '00:00' && total <= '00:30') {
    //     setDisabled(false);
    //   } else if (total > '00:30' && total < '05:00') {
    //     firebase
    //       .firestore()
    //       .collection(`AdminBookings/${record.bookingDate}/bookings`)
    //       .doc(`${record.UID}${record.bookingTime}`)
    //       .update({
    //         status: "Completed",
    //       });
    //     firebase
    //       .firestore()
    //       .collection(`Bookings/${record.UID}/bookings`)
    //       .doc(`${record.UID}${record.bookingDate}${record.bookingTime}`)
    //       .update({
    //         status: "Completed",
    //       });
    //     setDisabled(true);
    //   }
    // }
    if (record.status == "Approved") {
      const startTime = moment(moment(`${getDate(record.bookingDate)}T${getTime(record.bookingTime)}:00Z`).valueOf() )
      const endTime = moment(moment(`${getDate(record.bookingDate)}T${getTime(record.bookingTime)}:00Z`).valueOf() + 1800000)
      if ( (startTime - moment.utc().valueOf()) > 0 ) {
        setDisabled(true);
      }
      else if ( (endTime - moment.utc().valueOf()) > 0 ) {
        setDisabled(false);
      }
      if(moment.utc().valueOf() > endTime) {
          firebase
              .firestore()
                  .collection(`AdminBookings/${record.bookingDate}/bookings`)
                      .doc(`${record.UID}${record.bookingTime}`)
                          .update({
                              status: "Completed",
                          });
          firebase
              .firestore()
                  .collection(`Bookings/${record.UID}/bookings`)
                      .doc(`${record.UID}${record.bookingDate}${record.bookingTime}`)
                          .update({
                              status: "Completed",
                          });
          setDisabled(true);
      }
  }
  }, []);

  useEffect(() => {
    if (selectedRecord.key == record.key && selectedRecord.type == record.type) {
      setDisabled(false);
    }
  }, [selectedRecord])
  return (
    <div>
      {record.appointment == "Virtual Chat" ? <Button
        type="primary"
        shape="circle"
        icon={<WechatOutlined />}
        size={'large'}
        disabled={disabled}
        onClick={() => {
          history.push('/chat', record.UID);
        }}
      /> : null}
      {record.appointment == "Video Call" ? <Button
        type="primary"
        shape="circle"
        icon={<VideoCameraFilled />}
        size={'large'}
        disabled={disabled}
        onClick={() => {
          history.push({
            pathname: '/video',
            state: { name: record.userName, photoURL: record.doctorPhotoURL ? record.doctorPhotoURL : null }
          });
        }}
      /> : null}
      {record.appointment == "Audio Call" ? <Button
        type="primary"
        shape="circle"
        icon={<AudioFilled />}
        size={'large'}
        disabled={disabled}
        onClick={() => {
          history.push({
            pathname: '/audio',
            state: { name: record.userName, photoURL: record.doctorPhotoURL ? record.doctorPhotoURL : '' }
          });
        }}
      /> : null}
    </div>
  );
};

const AdminAppointmentTable = ({ data }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const history = useHistory();
  const db = firebase.firestore();
  const auth = firebase.auth();
  const [selectedRecord, setSelectedRecord] = useState({});

  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    getActiveBookingDates(data);

    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  const collapsed = () => {
    if (width < 768)
      return {
        title: <Text style={{ fontFamily: "GalanoMedium" }}>Record</Text>,
        render: (record) => (
          <Fragment>
            <Text style={{ fontFamily: "GalanoLight" }}>
              {record.userName}
            </Text>
            <br />
            <Text style={{ fontFamily: "GalanoLight" }}>
              {getLocalDate(record.bookingDate,record.bookingTime)}
            </Text>
            <br />
            <Text style={{ fontFamily: "GalanoMedium" }}>
              {getLocalTime(record.bookingDate,record.bookingTime)}
            </Text>
            <br />
            <Text style={{ fontFamily: "GalanoLight" }}>
              {record.appointment}
            </Text>
            <br />
            <Text style={{ fontFamily: "GalanoLight" }}>
              {record.subscription}
            </Text>
            <br />
            <StatusDropDown val={record.status} record={record} auth={auth} />
            <br />{
              record.status !== "Approved" || timeLeft( getLocalDate(record.bookingDate,record.bookingTime),getLocalTime(record.bookingDate,record.bookingTime) ) < 0 ? null : <Countdown value={timeLeft( getLocalDate(record.bookingDate,record.bookingTime),getLocalTime(record.bookingDate,record.bookingTime) )} />
            }
            <br />
            <CommentBox val={record.comment} record={record} />
            <br/>
            <ActionButton db={db} record={record} history={history} selectedRecord={selectedRecord} />
          </Fragment>
        ),
        responsive: ["xs", "sm"],
      };
    else {
      return {};
    }
  };

  const getActiveBookingDates = (data) => {
    data.map(item => {
      const timeDuration = timeLeft(item.bookingDate, item.bookingTime);
      const currentTimeDuration = new Date().getTime();
    })
  }

  const onFinish = (record) => {
    setSelectedRecord(record);
  }

  const columns = [
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>S.No</Text>,
      dataIndex: "key",
      key: "key",
      responsive: ["xs", "sm"],
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Name</Text>,
      dataIndex: "userName",
      key: "userName",
      responsive: ["md", "lg"],
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Booking Date</Text>,
      dataIndex: "bookingDate",
      key: "bookingDate",
      responsive: ["md", "lg"],
      render: (text, record) => <Text>{getLocalDate(record.bookingDate,record.bookingTime)}</Text>
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Booking Time</Text>,
      dataIndex: "bookingTime",
      key: "bookingTime",
      responsive: ["md", "lg"],
      render: (text, record) => <Text>{getLocalTime(record.bookingDate,record.bookingTime)}</Text>
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Appointment</Text>,
      dataIndex: "appointment",
      key: "appointment",
      responsive: ["md", "lg"],
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Subscription</Text>,
      dataIndex: "subscription",
      key: "subscription",
      responsive: ["md", "lg"],
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Status</Text>,
      dataIndex: "status",
      key: "status",
      responsive: ["md", "lg"],
      render: (text, record) => <StatusDropDown val={text} record={record} auth={auth} />,
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Timer</Text>,
      dataIndex: "timer",
      key: "timer",
      responsive: ["md", "lg"],
      render: (text, record) => record.status !== "Approved" || timeLeft( getLocalDate(record.bookingDate,record.bookingTime),getLocalTime(record.bookingDate,record.bookingTime) ) < 0 ? <Text style={{ fontFamily: "GalanoLight" }}>N/A</Text> : <Countdown value={timeLeft( getLocalDate(record.bookingDate,record.bookingTime),getLocalTime(record.bookingDate,record.bookingTime) )} onFinish={() => onFinish(record)} />,
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium" }}>Comments</Text>,
      dataIndex: "comments",
      key: "comments",
      responsive: ["md", "lg"],
      render: (text, record) => <CommentBox val={text} record={record} />,
    },
    {
      title: <Text style={{ fontFamily: "GalanoMedium", justifyContent: 'center' }}>Actions</Text>,
      dataIndex: "actions",
      key: "actions",
      responsive: ["md", "lg"],
      render: (text, record) => <ActionButton db={db} record={record} history={history} selectedRecord={selectedRecord} />,
    },
    collapsed(),
  ];

  return (
    <Content
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 20,
        overflow: "scroll",
        height: "100%",
      }}
    >
      <Title style={{ display: "flex", fontFamily: "GalanoLight" }}
      
        onClick={()=>{
          ApiCalendar.handleAuthClick();
        }}
      >
        Booking
      </Title>

      <Table
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 10 }}
      />
    </Content>
  );
};

export default AdminAppointmentTable;
