import React, { useState, useEffect } from 'react';
import '../Video.scss';
import Participant from './Participant';
import { useHistory } from 'react-router-dom';
import { PhoneFilled } from '@ant-design/icons';
import {BiCamera,BiCameraOff, BiMicrophone, BiMicrophoneOff } from 'react-icons/bi';

const Room = (props) => {

    const [remoteParticipants, setRemoteParticipants] = useState([]);
    const [audioDisable, setAudioDisable] = useState(false);
    const [videoDisable, setVideoDisable] = useState(false);
    const history = useHistory();

    useEffect(() => {
        // Add event listeners for future remote participants coming or going
        props.room.on('participantConnected', participant => addParticipant(participant));
        props.room.on('participantDisconnected', participant => removeParticipant(participant));

        window.addEventListener("beforeunload", leaveRoom);
        setRemoteParticipants(Array.from(props.room.participants.values()));

        return () => {
            window.removeEventListener("beforeunload", leaveRoom);
            if (history.action == 'POP') {
                leaveRoom();
            }
        }
    }, []);

    const addParticipant = (participant) => {
        console.log(`${participant.identity} has joined the room.`);
        setRemoteParticipants([...remoteParticipants, participant]);
    }

    const removeParticipant = (participant) => {
        console.log(`${participant.identity} has left the room`);
        setRemoteParticipants(remoteParticipants.filter(p => p.identity !== participant.identity));
    }

    const leaveRoom = () => {
        props.room.disconnect();
        props.returnToLobby();
    }

    const checkAudioTrack = () => {
        props.room.localParticipant.audioTracks.forEach(function (audioTrack) {
            if (audioTrack.kind == 'audio' && audioTrack.isTrackEnabled) {
                setAudioDisable(true);
                audioTrack.track.disable();
            } else {
                setAudioDisable(false);
                audioTrack.track.enable();
            }
        });
    }

    const checkVideoTrack = () => {
        props.room.localParticipant.videoTracks.forEach(function (videoTrack) {
            if (videoTrack.kind == 'video' && videoTrack.isTrackEnabled) {
                setVideoDisable(true);
                videoTrack.track.disable();
                var myVideo = document.querySelector(`#${props.room.localParticipant.identity} .track video`);
                myVideo.style.display = 'none';
            } else {
                setVideoDisable(false);
                videoTrack.track.enable();
                var myVideo = document.querySelector(`#${props.room.localParticipant.identity} .track video`);
                myVideo.style.display = 'block';
            }
        });
    }

    return (
        <div className="room">
            <div className="participants">
                <Participant key={props.room.localParticipant.identity} localParticipant="true" participant={props.room.localParticipant} user={props.user} remoteUser={props.remoteUser} />
                {
                    remoteParticipants[0] ?
                        <Participant key={remoteParticipants[0].identity} participant={remoteParticipants[0]} user={props.user} remoteUser={props.remoteUser} photo={props.photo} /> : null
                }
            </div>
            {/* <div id="buttons">
                <button onClick={checkAudioTrack}>{audioDisable ? "Audio on" : "Audio off"}</button>
                <button onClick={leaveRoom}>Leave Room</button>
                <button onClick={checkVideoTrack}>{videoDisable ? "Video on" : "Video off"}</button>
            </div> */}
            
            <div id="buttons" >
                <div style={{height:70,width:70,borderRadius:35,backgroundColor:"#fff",display:'flex',alignItems:"center",justifyContent:"center"}}>
                    { !audioDisable ? <BiMicrophone onClick={checkAudioTrack}  style={{fontSize:40}}/> : <BiMicrophoneOff onClick={checkAudioTrack} style={{fontSize:40}} /> }
                </div>
                <div style={{height:70,width:70,borderRadius:35,backgroundColor:"#fff",display:'flex',alignItems:"center",justifyContent:"center"}}>
                    <PhoneFilled onClick={leaveRoom}  style={{fontSize:40,color:"red"}} rotate={-135} />
                </div>
                <div style={{height:70,width:70,borderRadius:35,backgroundColor:"#fff",display:'flex',alignItems:"center",justifyContent:"center"}}>
                    { !videoDisable ? <BiCamera onClick={checkVideoTrack}  style={{fontSize:40}}/> : <BiCameraOff onClick={checkVideoTrack} style={{fontSize:40}} /> }
                </div>
                
            </div>
        </div>
    );
}

export default Room;
