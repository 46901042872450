import React, { useContext } from "react";
import { Route, Redirect,Switch } from "react-router-dom";

import AdminHome from "../pages/AdminHome";
import AdminBookings from "../pages/AdminBookings";
import AdminProfile from "../pages/AdminProfile";
import AdminSettings from "../pages/AdminSettings";
// import AdminContactUs from "../pages/AdminContactUs";
import AdminBlogs from "../pages/AdminBlogs";
import CreateBlog from "../pages/AdminBlogs/CreateBlog";
import AdminViewBlog from "../pages/AdminBlogs/AdminViewBlog";

import Register from "../pages/Auth/Register";
import UserHome from "../pages/UserHome";
import UserBookings from "../pages/UserBookings";
import UserProfile from "../pages/UserProfile";
import UserSettings from "../pages/UserSettings";
import AboutUs from "../pages/AboutUs";
import UserContactUs from "../pages/UserContactUs";
import UserBlogs from "../pages/UserBlogs";
import Payment from "../pages/Payment";
import UserViewBlog from "../pages/UserBlogs/UserViewBlog";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import DelAccount from "../pages/DelAccount";
import Newsletter from "../pages/Newsletter";
import DoctorProfile from "../pages/DoctorProfile";
import PaypalResult from "../pages/PaypalResult";
import Chat from "../pages/Chat";
import Video from "../pages/Video";
import Audio from "../pages/Audio";
import NotFound from "../pages/NotFound";
import AdminNotFound from "../pages/NotFound/adminNotFound";
import { UserContext } from "../context/UserContext";
import Splash from "../pages/Splash";

const ProtectedRoute = (props) => {
  const isLoggedIn = window.localStorage.getItem("access-token");
  if (isLoggedIn) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/" />;
  }
};

const Routes = () => {
  const Context = useContext(UserContext);
  // console.log("in routes",Context);

  if(Context.loading){
    return <Splash />
  }

  // if (window.location.pathname === '/pay') {
  //   return <Route path="/pay" component={PaypalResult} />;
  // } else {
    return (
      <>
        {Context.isAdmin ? (
          <>
            <Switch>
            <Route exact path="/" component={AdminHome} />
            <ProtectedRoute exact path="/bookings" component={AdminBookings} />
            <ProtectedRoute exact path="/profile" component={AdminProfile} />
            <ProtectedRoute exact path="/chat" component={Chat} />
            <ProtectedRoute exact path="/video" component={Video} />
            <ProtectedRoute exact path="/audio" component={Audio} />
            <ProtectedRoute exact path="/settings" component={AdminSettings} />
            <ProtectedRoute exact path="/blogs" component={AdminBlogs} />
            <ProtectedRoute exact path="/create-blog" component={CreateBlog} />
            <ProtectedRoute exact path="/view-blog" component={AdminViewBlog} />
            <Route component={AdminNotFound} />
            </Switch>
          </>
        ) : (
          <>
            <Switch>
            <Route exact path="/" component={UserHome} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/contact-us" component={UserContactUs} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/delete-account" component={DelAccount} />
            <Route exact path="/blogs" component={UserBlogs} />
            <Route exact path="/view-blog" component={UserViewBlog} />
            <ProtectedRoute exact path="/doctor-profile" component={DoctorProfile} />
            <ProtectedRoute exact path="/newsletter" component={Newsletter} />
            <ProtectedRoute exact path="/settings" component={UserSettings} />
            <ProtectedRoute exact path="/bookings" component={UserBookings} />
            <ProtectedRoute exact path="/profile" component={UserProfile} />
            <ProtectedRoute exact path="/payment/:id" component={Payment} />
            <ProtectedRoute exact path="/chat" component={Chat} />
            <ProtectedRoute exact path="/video" component={Video} />
            <ProtectedRoute exact path="/audio" component={Audio} />
            <ProtectedRoute path="/pay" component={PaypalResult} />
            <Route component={NotFound} />
            </Switch>
          </>
        )}
      </>
    );
  // }
};

export default Routes;


/* import React, { useContext } from "react";
import { Route, Redirect,Switch } from "react-router-dom";

import AdminHome from "../pages/AdminHome";
import AdminBookings from "../pages/AdminBookings";
import AdminProfile from "../pages/AdminProfile";
import AdminSettings from "../pages/AdminSettings";
import AdminContactUs from "../pages/AdminContactUs";
import AdminBlogs from "../pages/AdminBlogs";
import CreateBlog from "../pages/AdminBlogs/CreateBlog";
import AdminViewBlog from "../pages/AdminBlogs/AdminViewBlog";

import Register from "../pages/Auth/Register";
import UserHome from "../pages/UserHome";
import UserBookings from "../pages/UserBookings";
import UserProfile from "../pages/UserProfile";
import UserSettings from "../pages/UserSettings";
import AboutUs from "../pages/AboutUs";
import UserContactUs from "../pages/UserContactUs";
import UserBlogs from "../pages/UserBlogs";
import Payment from "../pages/Payment";
import UserViewBlog from "../pages/UserBlogs/UserViewBlog";
import Newsletter from "../pages/Newsletter";
import DoctorProfile from "../pages/DoctorProfile";
import PaypalResult from "../pages/PaypalResult";
import Chat from "../pages/Chat";
import Video from "../pages/Video";
import Audio from "../pages/Audio";
import NotFound from "../pages/NotFound";
import AdminNotFound from "../pages/NotFound/adminNotFound";
import { UserContext } from "../context/UserContext";

const ProtectedRoute = (props) => {
  const isLoggedIn = window.localStorage.getItem("access-token");
  if (isLoggedIn) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/" />;
  }
};

const Routes = ({ isAdmin }) => {
  const User = React.useContext(UserContext);
  const parsedUser = JSON.parse(User.value);
  console.log("=============================");
  console.log(isAdmin);
  console.log("=============================");

  if (window.location.pathname == '/pay') {
    return <Route path="/pay" component={PaypalResult} />;
  } else {
    return (
      <>
        {(parsedUser.privilege == "admin" || isAdmin) ? (
            <Switch>
            <Route exact path="/" component={AdminHome} />
            <ProtectedRoute exact path="/bookings" component={AdminBookings} />
            <ProtectedRoute exact path="/profile" component={AdminProfile} />
            <ProtectedRoute exact path="/chat" component={Chat} />
            <ProtectedRoute exact path="/video" component={Video} />
            <ProtectedRoute exact path="/audio" component={Audio} />
            <ProtectedRoute exact path="/settings" component={AdminSettings} />
            <ProtectedRoute exact path="/blogs" component={AdminBlogs} />
            <ProtectedRoute exact path="/create-blog" component={CreateBlog} />
            <ProtectedRoute exact path="/view-blog" component={AdminViewBlog} />
            <Route component={AdminNotFound} />
            </Switch>
        ) : parsedUser.privilege == "user" ?(
            <Switch>
              <ProtectedRoute exact path="/doctor-profile" component={DoctorProfile} />
              <ProtectedRoute exact path="/newsletter" component={Newsletter} />
              <ProtectedRoute exact path="/settings" component={UserSettings} />
              <ProtectedRoute exact path="/bookings" component={UserBookings} />
              <ProtectedRoute exact path="/profile" component={UserProfile} />
              <ProtectedRoute exact path="/payment/:id" component={Payment} />
              <ProtectedRoute exact path="/chat" component={Chat} />
              <ProtectedRoute exact path="/video" component={Video} />
              <ProtectedRoute exact path="/audio" component={Audio} />
              <Route component={NotFound} />
            </Switch>
        ):(
          <Switch>
            <Route exact path="/" component={UserHome} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/contact-us" component={UserContactUs} />
            <Route exact path="/blogs" component={UserBlogs} />
            <Route exact path="/view-blog" component={UserViewBlog} />
            <Route component={NotFound} />
          </Switch>
        )}
      </>
    );
  }
};

export default Routes;
 */