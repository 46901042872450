import React, { useEffect, useState, useCallback } from "react";
import { Typography, Layout, Space, Avatar } from "antd";
// import Avatar2 from 'react-avatar-edit'
import image from "../../assets/bg3.jpeg";
import Splash from "../Splash";
import firebase from "firebase";
import AdminNavbar from "../../layout/AdminNavbar";
import AdminFooter from "../../layout/AdminFooter";
import google from "../../assets/drdee.png";
import {Link} from 'react-router-dom';
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import * as Twilio from 'twilio';
const { Content } = Layout;
const { Title, Text } = Typography;

const AdminHome = () => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState();
  const [size, setSize] = useState();
  const [token,setToken] = useState("");

  const twil = Twilio("AC7f7b9276e29b8d67655b8e394762b677","8759cc850fa9492e69552f75ea930d71");

  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
    if ( width > 600){
      setSize(200)
    }
    else{
      setSize(100)
    }
  }, []);
  const read = async () => {
    const data = firebase
      .firestore()
      .collection("Users")
      .doc("gRT9GEbL0ff9LVNBVo0zGDrcFya2");
    data.get().then((values) => {
      // console.log(values.data());
      setProfile(values.data());
    });
  };

  const handleFontSize = () => {
    if (width > 900) {
      return "45px";
    } else if (width > 600) {
      return "26px";
    } else {
      return "16px";
    }
  };

  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const storage = firebase.storage();
  const [user, setUser] = useState();
  const getUser = async () => {
    if(auth.currentUser!= null){
      const result = firestore.collection("Users").doc(auth.currentUser.uid);
      result.get().then((querySnapshot) => {
        setUser(querySnapshot.data());
      });
    }
  };

  const getToken = ()=>{
    twil.conversations.v1.conversations("")
  }

  useEffect(() => {
    getUser();
    read();
    window.addEventListener("resize", handleWindowResize);
    setLoading(true)
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return loading ? (
    <Splash />
  ) : (
    <>
      <AdminNavbar />
      <div
        style={{
          height: 300,
          overflow: 'visible',
          // objectFit: 'cover',
          position: "relative",
          backgroundImage: image,
          borderBottomLeftRadius: "0%",
          borderBottomRightRadius: "30%",
          // overflow: "hidden",
        }}
      >
      <div
      style={{
          height: 300,
          // overflow: "hidden",
          // objectFit: 'contain',
          position: "relative",
          backgroundImage: image,
          borderBottomLeftRadius: "0%",
          borderBottomRightRadius: "30%",
          overflow: "hidden",
        }}
      >
        <img
          src={image}
          alt=""
          style={{
            width: "100%",
            // objectFit: 'contain',
            filter: 'blur(10px)',
            borderBottomLeftRadius: "0%",
            borderBottomRightRadius: "30%",
            top:-30,
            overflow: "hidden"
          }}
        />
      </div>
        {/* <Space
          style={{
            position: "absolute",
            left: 0,
            marginTop: "20%",
            backgroundColor: "rgba(0,0,0,.4)",
            padding: "10px 30px 10px 5px",
            borderRadius: "0 30px 30px 0",
          }}
        > */}
          {/* <Text
            style={{
              fontFamily: "GalanoBold",
              fontSize: handleFontSize(),
              fontStyle: "italic",
              color: "#fff",
            }}
          >
          'Where Your Health Matters'
          </Text> */}
          
        {/* </Space> */}
        {/* {user?.photoURL ? ( 
          <Avatar size={size} col   style={{marginLeft: '5%' ,padding:10,marginTop: '-10%', backgroundColor: '#000', borderRadius: 250 }}>
            <Avatar size={size-10} src={user?.photoURL} style={{zIndex: -1, backgroundColor: '#fff', marginTop: -23}}/>
          </Avatar> )
          : (
            <Avatar size={200} col   
              style={{
                marginLeft: '1%' ,
                padding:10,
                marginTop: width > 600 ? -100 : -100,
                backgroundColor: '#000', 
                borderRadius: 250
              }}>
              <Avatar size={190} icon={<UserOutlined />} style={{zIndex: -1, backgroundColor: '#fff', marginTop: -23}}/>
            </Avatar>
          )}
          <Text
          style={{
            fontFamily: "GalanoBold",
            fontSize: 40,
          }}
        >
          Dr Dee
        </Text> */}
          
      </div>
      
      
      <div style={{}}>
        {user?.photoURL ? ( 
        <Avatar size={200} col   style={{marginLeft: '1%' ,padding:10,marginTop: -100, backgroundColor: '#000', borderRadius: 250 }}>
          <Avatar size={190} src={user?.photoURL} style={{zIndex: -1, backgroundColor: '#fff', marginTop: -23}}/>
        </Avatar> )
        : (
          <Avatar size={200} col   style={{marginLeft: '1%' ,padding:10,marginTop: -100, backgroundColor: '#000', borderRadius: 250 }}>
            <Avatar size={190} icon={<UserOutlined />} style={{zIndex: -1, backgroundColor: '#fff', marginTop: -23}}/>
          </Avatar>
        )}
        {width > 600 ? (null) : (<br/>)}
          
        
        <Text
          style={{
            fontFamily: "GalanoBold",
            fontSize: 40,
            marginLeft: width > 600 ? '1%' : '10%',
            marginTop: 0,
          }}
        >
          {user?.firstName + " " + user?.lastName}
        </Text>
        <br />
        <Text
          style={{
            fontFamily: "GalanoMedium",
            fontSize: 18,
            color: "#7a7a7a",
            marginBottom: 12,
            marginLeft: width > 600 ? '16%' : '10%',
            // marginTop: '-100px'
          }}
        >
          Some Words About Me And My Profession
        </Text>
        <hr
          style={{
            marginTop: "2%",
            borderTop: "5px solid rgb(40, 55, 239)",
            borderRadius: 3,
            width: '90%',
            marginLeft: 0,
            marginLeft: '1%'
          }} 
        />
      </div>


      <div
        style={{
          textAlign: "left",
          marginLeft: "1%",
          marginRight: "10%",
          padding: 20,
          paddingTop: 65,
          fontWeight: 100,
          marginTop: 10
        }}
      >
        {/* <Text
          style={{
            fontFamily: "GalanoBold",
            fontSize: 40,
          }}
        >
          Dr Dee
        </Text> */}
        {/* <br />
        <Text
          style={{
            fontFamily: "GalanoMedium",
            fontSize: 18,
            color: "#7a7a7a",
            marginBottom: 12,
          }}
        >
          Some Words About Me And My Profession
        </Text>
        <hr
          style={{
            marginTop: "2%",
            borderTop: "5px solid rgb(40, 55, 239)",
            borderRadius: 3,
            width: 200,
            marginLeft: 0,
          }}
        /> */}
        <Text style={{ fontFamily: "GalanoLight", fontSize: 14 }}>
          {user?.aboutMe}
        </Text>
      </div>

      <div
        style={{
          textAlign: "left",
          marginLeft: "1%",
          marginRight: "10%",
          padding: 20,
          fontWeight: 100,
        }}
      >
        <Text
          style={{
            fontFamily: "GalanoBold",
            fontSize: 32,
            marginBottom: 12,
          }}
        >
          Education & Credentials
        </Text>
        <hr
          style={{
            marginTop: "2%",
            borderTop: "5px solid rgb(40, 55, 239)",
            borderRadius: 3,
            width: 200,
            marginLeft: 0,
          }}
        />
        <Space direction="horizontal" wrap={true}>
          <Space direction="vertical" span={4}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Education
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              {/* {profile?.education} */}
              {user?.education}
            </Text>
          </Space>
          {/* <Space direction="vertical" style={{ padding: 20 }}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Languages Spoken
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              {profile?.languages}
            </Text>
          </Space> */}
          <Space direction="vertical" style={{ padding: 20 }}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Speciality
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              {/* {profile?.speciality} */}
              {user?.speciality}
            </Text>
          </Space>
          {/* <Space direction="vertical" style={{ padding: 20 }}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Membership
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              {profile?.membership}
            </Text>
          </Space> */}
          <Space direction="vertical" style={{ padding: 20 }}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Experience
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              {/* {profile?.experience} */}
              {user?.experience}
            </Text>
          </Space>

{/*           
          <Link to={{
                pathname:"/chat",
                name: user?.firstName,
                token: "12415qlfewsdnvdskheagwegw"
              }}>
              
              <button className="Buy"><h4>BUY NOW</h4></button>
          </Link> */}

          <Space direction="vertical" style={{ padding: 20 }}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Awards
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              {/* {profile?.experience} */}
              {user?.awards}
            </Text>
          </Space>
        </Space>
      </div>

      <AdminFooter />
    </>
  );
};
export default AdminHome;
