import React, { useState, useEffect } from "react";
import { Typography, Layout, Space, Spin } from "antd";
import firebase from "firebase";
import moment from 'moment';
import UserAppointmentTable from "../../components/UserAppointmentTable";
import UserNavbar from "../../layout/UserNavbar";
import PageFooter from "../../layout/PageFooter";

const { Title, Text } = Typography;

const UserBookings = () => {
  const [loading, setLoading] = useState(true);
  const [bookings, setBookings] = useState([]);
  const auth = firebase.auth();
  const firestore = firebase.firestore();

  const getData = async () => {
    setLoading(true);
    const booking = firestore
      .collection("Bookings")
      .doc(auth?.currentUser?.uid)
      .collection("bookings");
    const tempDoc = [];
    booking.get().then((querySnapshot) => {
      querySnapshot.docs.map((doc, index) => {
        tempDoc.unshift({ key: querySnapshot.docs.length - index, ...doc.data() });
      });

      setBookings(tempDoc);
    });

    setLoading(false);
  };

  useEffect(() => {
    if(auth?.currentUser){
      getData();
    }
    // console.log(bookings);
  }, []);

  return (
    <>
      <UserNavbar refreshData={getData} />
      <Space direction="vertical" style={{width: "100%",overflow:"scroll"}}>
        <Spin spinning={loading}>
          <div
            style={{
              height: 1000,
            }}
          >
            <UserAppointmentTable data={bookings} />
          </div>
        </Spin>
      </Space>
      <PageFooter refreshData={getData} />
    </>
  );
};
export default UserBookings;
