import React, { useState, useEffect } from "react";
import {
  Typography,
  Collapse,
  Dropdown,
  Menu,
  Button,
  Space,
  Tag,
  Card,
  Select,
  message,
} from "antd";
import Carousel from "react-elastic-carousel";
import firebase from "firebase";
import moment from "moment";

const { Panel } = Collapse;
const { Option } = Select;
const { Text } = Typography;

const SlotTag = ({ year, month, day, slotTime, slots, select }) => {
  const [booked, setBooked] = useState(true);
  const fixLength = (day_month) => {
    if (day_month < 10) {
      return `0${day_month}`;
    } else {
      return day_month;
    }
  };

  const parseMonth =  (month) => {
    const months = {}
    moment.months().map((item)=>{
        months[item.slice(0,3)] = item
    });
    return months[month];
  }

  const parseTime = (time) => {
    const minutes = time.slice(3,5);
    if(time.slice(0,2) == "00"){
      return `12:${minutes} am`;
    }
    else{
      const hours = (time.slice(0,2) > 12) ? time.slice(0,2) -12 : time.slice(0,2) ;
      if(time.slice(0,2) > 11){
          return hours <10 ? `0${hours}:${minutes} pm` : `${hours}:${minutes} pm`;
      }
      return `${hours}:${minutes} am`;
    }
  }

  const getUTCDate = (_date,_time) => {
    const UTCtime =new Date(
      `${_date} ${_time.slice(
        0,
        2
      )}:${_time.slice(3, 5)}:00 ${_time.slice(6, 8)}`
    ).toUTCString();
    const newTime =UTCtime.split(" ");
    console.log(UTCtime);
    return `${newTime[1]}-${parseMonth(newTime[2])}-${newTime[3]}`
  }
  
const getUTCTime = (_date,_time) => {
    const UTCtime =new Date(
      `${_date} ${_time.slice(
        0,
        2
      )}:${_time.slice(3, 5)}:00 ${_time.slice(6, 8)}`
    ).toUTCString();
    const newTime =UTCtime.split(" ");
    return `${parseTime(newTime[4])}`
  }



  useEffect(() => {
    // if(moment(`${year}-${moment.months().indexOf(month) +1}-${day} ${slotTime.slice(0,2)}:${slotTime.slice(3,5)}`) < moment.now())
    if (
      new Date(
        `${year}-${moment.months().indexOf(month) + 1}-${day} ${slotTime.slice(
          0,
          2
        )}:${slotTime.slice(3, 5)}:00 ${slotTime.slice(6, 8)}`
      ) < new Date()
    ) {
      setBooked(true);
    } else {
      if (slots.some((slot) => slot ==  `${getUTCDate(`${fixLength(day)}-${month}-${year}`,slotTime)} ${getUTCTime(`${fixLength(day)}-${month}-${year}`,slotTime)}` )) {
        setBooked(true);
      } else {
        setBooked(false);
      }
    }
  }, [slots]);

  return (
    <Tag
      style={{
        paddingLeft: 12,
        paddingRight: 12,
        paddingTop: 5,
        paddingBottom: 5,
        margin: 5,
        borderRadius: 20,
        fontWeight: "bold",
        fontSize: 12,
        color: booked ? "grey" : "#2837ef",
        borderColor: booked ? "grey" : "#2837ef",
        fontFamily: "GalanoLight",
      }}
      onClick={() => {
        if (booked) {
          // alert("This time slot is already booked");
          message.warning("This time slot is not available", 1);
        } else {
          select(`${fixLength(day)}-${month}-${year} - ${slotTime}`);
        }
      }}
    >
      {slotTime}
    </Tag>
  );
};

const TimeSlotModal = ({ setTime }) => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(moment.months()[new Date().getMonth()]);
  const [days, setDays] = useState(
    Array.from({ length: moment().daysInMonth() }, (_, i) => i + 1)
  );
  const [day, setDay] = useState(moment().date());
  const [takenSlots, setTakenSlots] = useState([]);
  const firestore = firebase.firestore();

  const slotMaker = (startTime, endTime, slotTime) => {
    const slots = [];
    var start = moment(startTime, "HH:mm");
    var end = moment(endTime, "HH:mm");
    while (start < end) {
      slots.push(start.format("hh:mm a"));
      start = start.add(slotTime, "minutes");
    }
    return slots;
  };

  const monthSelect = (key) => {
    setMonth(months[key]);
  };

  const yearSelect = (key) => {
    setYear(years[key]);
  };

  const morningSlots = slotMaker("00:00", "12:00", 30);
  const afternoonSlots = slotMaker("12:00", "16:00", 30);
  const eveningSlots = slotMaker("16:00", "23:59", 30);
  const months = moment.months();
  const years = [
    new Date(moment.now()).getFullYear(),
    new Date(moment.now()).getFullYear() + 1,
  ];


  const getDayAppointments = async (day)=>{
    return new Promise(async (res,rej)=>{
      const basePath = firestore.collection("AdminBookings").doc(day);
      const result = await basePath.get();
      if(result.exists){
        basePath.collection("bookings").get().then((query) => {
          const tempDoc = query.docs.map((doc, index) => {
            return { ...doc.data() };
          });
          res(tempDoc);
        })
      }
      else{
        basePath.set({ dummy: "dummy value" });
        rej([]);
      }
    })
  }

  const secureCurrentDay = async () => {
    const basePath = firestore
      .collection("AdminBookings")
      .doc(
        `${moment().date()}-${
          moment.months()[new Date().getMonth()]
        }-${new Date().getFullYear()}`
      );
    await basePath
      .get()
      .then( async (value) => {
        if (!value.exists) {
          basePath.set({ dummy: "dummy value" });
        }
        else{
          const check_days = [
            moment(`${moment().year()}${fixLength(moment().month() +1)}${day}`).subtract("1",'day').format("DD-MMMM-YYYY"),
            moment(`${moment().year()}${fixLength(moment().month() +1)}${day}`).format("DD-MMMM-YYYY"),
            moment(`${moment().year()}${fixLength(moment().month() +1)}${day}`).add("1",'day').format("DD-MMMM-YYYY")
          ]
          const _slots = [];

          const allPromises = [
            await getDayAppointments(check_days[0]),await getDayAppointments(check_days[1]),await getDayAppointments(check_days[2])
          ];

          Promise.all(allPromises).then(values=>{
            values.map((value)=>{
              value.map((rec)=>{
                _slots.push(`${rec.bookingDate} ${rec.bookingTime}`);
              })
            });
            setTakenSlots(_slots);
          });
        }
      })
      .catch((error) => console.log(error));
  };

  const fixLength = (day_month) => {
    if (day_month < 10) {
      return `0${day_month}`;
    } else {
      return day_month;
    }
  };
  

  useEffect(() => {
    if (!!year && !!month) {
      setDays(
        Array.from(
          {
            length: moment(
              `${year}-${fixLength(moment.months().indexOf(month) + 1)}`,
              "YYYY-MM"
            ).daysInMonth(),
          },
          (_, i) => i + 1
        )
      );
      secureCurrentDay();
    }
  }, [year, month]);

  return (
    <>
      <Space
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Space direction="vertical">
          <Text style={{ fontSize: 14, fontFamily: "GalanoLight" }}>Year</Text>
          <Select
            value={year}
            style={{ width: 120, fontSize: 15, fontFamily: "GalanoLight" }}
            onChange={async (item) => {
              setYear(item);

              setDays(
                Array.from(
                  {
                    length: moment(
                      `${item}-${fixLength(
                        moment.months().indexOf(month) + 1
                      )}`,
                      "YYYY-MM"
                    ).daysInMonth(),
                  },
                  (_, i) => i + 1
                )
              );

              // const basePath = firestore
              //   .collection("AdminBookings")
              //   .doc(`${day}-${month}-${item}`);
              // await basePath
              //   .get()
              //   .then((value) => {
              //     if (!value.exists) {
              //       basePath.set({ dummy: "dummy value" });
              //     }
              //   })
              //   .catch((error) => console.log(error));
              // const daySlots = basePath.collection("bookings");
              // daySlots.get().then((querySnapshot) => {
              //   const tempDoc = querySnapshot.docs.map((doc, index) => {
              //     return { key: index + 1, ...doc.data() };
              //   });
              //   setTakenSlots(
              //     tempDoc
              //       .filter(({ status }) => {
              //         return status != "Cancel";
              //       })
              //       .map(({ bookingTime }) => {
              //         return bookingTime;
              //       })
              //   );
              // });
              
              const check_days = [
                moment(`${item}${fixLength(moment.months().indexOf(month)+1)}${day}`).subtract("1",'day').format("DD-MMMM-YYYY"),
                moment(`${item}${fixLength(moment.months().indexOf(month)+1)}${day}`).format("DD-MMMM-YYYY"),
                moment(`${item}${fixLength(moment.months().indexOf(month)+1)}${day}`).add("1",'day').format("DD-MMMM-YYYY")
              ]
              const _slots = [];

              const allPromises = [
                await getDayAppointments(check_days[0]),await getDayAppointments(check_days[1]),await getDayAppointments(check_days[2])
              ];

              Promise.all(allPromises).then(values=>{
                values.map((value)=>{
                  value.map((rec)=>{
                    _slots.push(`${rec.bookingDate} ${rec.bookingTime}`);
                  })
                });
                setTakenSlots(_slots);
              });
            }}
          >
            {years.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Space>
        <Space direction="vertical">
          <Text style={{ fontSize: 14, fontFamily: "GalanoLight" }}>Month</Text>
          <Select
            value={month}
            style={{ width: 120, fontSize: 15, fontFamily: "GalanoLight" }}
            onChange={async (item) => {
              setMonth(item);

              setDays(
                Array.from(
                  {
                    length: moment(
                      `${year}-${fixLength(moment.months().indexOf(item) + 1)}`,
                      "YYYY-MM"
                    ).daysInMonth(),
                  },
                  (_, i) => i + 1
                )
              );

              // const basePath = firestore
              //   .collection("AdminBookings")
              //   .doc(`${day}-${item}-${year}`);
              // await basePath
              //   .get()
              //   .then((value) => {
              //     if (!value.exists) {
              //       basePath.set({ dummy: "dummy value" });
              //     }
              //   })
              //   .catch((error) => console.log(error));
              // const daySlots = basePath.collection("bookings");
              // daySlots.get().then((querySnapshot) => {
              //   const tempDoc = querySnapshot.docs.map((doc, index) => {
              //     return { key: index + 1, ...doc.data() };
              //   });
              //   setTakenSlots(
              //     tempDoc
              //       .filter(({ status }) => {
              //         return status != "Cancel";
              //       })
              //       .map(({ bookingTime }) => {
              //         return bookingTime;
              //       })
              //   );
              // });
              
              const check_days = [
                moment(`${year}${fixLength(moment.months().indexOf(item)+1)}${day}`).subtract("1",'day').format("DD-MMMM-YYYY"),
                moment(`${year}${fixLength(moment.months().indexOf(item)+1)}${day}`).format("DD-MMMM-YYYY"),
                moment(`${year}${fixLength(moment.months().indexOf(item)+1)}${day}`).add("1",'day').format("DD-MMMM-YYYY")
              ]
              const _slots = [];

              const allPromises = [
                await getDayAppointments(check_days[0]),await getDayAppointments(check_days[1]),await getDayAppointments(check_days[2])
              ];

              Promise.all(allPromises).then(values=>{
                values.map((value)=>{
                  value.map((rec)=>{
                    _slots.push(`${rec.bookingDate} ${rec.bookingTime}`);
                  })
                });
                setTakenSlots(_slots);
              });
            }}
          >
            {months.map((item, index) => (
              <Option key={index} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </Space>
      </Space>
      <div style={{ padding: 10 }}>
        <Carousel
          pagination={false}
          initialActiveIndex={moment().date() - 1}
          itemsToShow={3}
          focusOnSelect
          itemPadding={[0, 10, 0, 0]}
        >
          {days.map((item, index) => (
            <Card
              key={index}
              onClick={async () => {
                setDay(item);
                // send the day,month and year to get all the slots
                // const basePath = firestore
                //   .collection("AdminBookings")
                //   .doc(`${item}-${month}-${year}`);
                // await basePath
                //   .get()
                //   .then((value) => {
                //     if (!value.exists) {
                //       basePath.set({ dummy: "dummy value" });
                //     }
                //   })
                //   .catch((error) => console.log(error));
                // const daySlots = basePath.collection("bookings");
                // daySlots.get().then((querySnapshot) => {
                //   const tempDoc = querySnapshot.docs.map((doc, index) => {
                //     return { key: index + 1, ...doc.data() };
                //   });
                //   setTakenSlots(
                //     tempDoc
                //       .filter(({ status }) => {
                //         return status != "Cancel";
                //       })
                //       .map(({ bookingTime }) => {
                //         return bookingTime;
                //       })
                //   );
                // });
                
                const check_days = [
                  moment(`${year}${fixLength(moment.months().indexOf(month)+1)}${item}`).subtract("1",'day').format("DD-MMMM-YYYY"),
                  moment(`${year}${fixLength(moment.months().indexOf(month)+1)}${item}`).format("DD-MMMM-YYYY"),
                  moment(`${year}${fixLength(moment.months().indexOf(month)+1)}${item}`).add("1",'day').format("DD-MMMM-YYYY")
                ]
                const _slots = [];

                const allPromises = [
                  await getDayAppointments(check_days[0]),await getDayAppointments(check_days[1]),await getDayAppointments(check_days[2])
                ];

                Promise.all(allPromises).then(values=>{
                  values.map((value)=>{
                    value.map((rec)=>{
                      _slots.push(`${rec.bookingDate} ${rec.bookingTime}`);
                    })
                  });
                  setTakenSlots(_slots);
                });

              }}
              style={{
                backgroundColor: day === item ? "#2837ef" : null,  
                marginLeft: 10,
                marginRight: 10,
                borderRadius: 20,
              }}
            >
              <Typography
                style={{
                  fontFamily: "GalanoLight",
                  color: day === item ? "#fff" : "#000",
                }}
              >
                {item} {month.slice(0, 3)}
              </Typography>
            </Card>
          ))}
        </Carousel>
      </div>

      <Collapse
        accordion
        style={{ borderColor: "transparent" }}
        expandIconPosition="right"
      >
        <Panel
          header={
            <Typography
              style={{
                fontWeight: "bold",
                color: "#415d68",
                fontFamily: "GalanoLight",
              }}
            >
              MORNING
            </Typography>
          }
          key="1"
        >
          {morningSlots.map((item, index) => (
            <SlotTag
              key={index}
              slots={takenSlots}
              slotTime={item}
              year={year}
              month={month}
              day={day}
              select={setTime}
            />
          ))}
        </Panel>
        <Panel
          header={
            <Typography
              style={{
                fontWeight: "bold",
                color: "#415d68",
                fontFamily: "GalanoLight",
              }}
            >
              AFTERNOON
            </Typography>
          }
          key="2"
          head
        >
          {afternoonSlots.map((item, index) => (
            <SlotTag
              key={index}
              slots={takenSlots}
              slotTime={item}
              year={year}
              month={month}
              day={day}
              select={setTime}
            />
          ))}
        </Panel>
        <Panel
          header={
            <Typography
              style={{
                fontWeight: "bold",
                color: "#415d68",
                fontFamily: "GalanoLight",
              }}
            >
              EVENING
            </Typography>
          }
          key="3"
        >
          {eveningSlots.map((item, index) => (
            <SlotTag
              key={index}
              slots={takenSlots}
              slotTime={item}
              year={year}
              month={month}
              day={day}
              select={setTime}
            />
          ))}
        </Panel>
      </Collapse>
    </>
  );
};

export default TimeSlotModal;
