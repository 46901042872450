import React, { useEffect, useState,useCallback } from "react";
import {
  Typography,
  Input,
  Space,
  Avatar,
  Card,
  Upload,
  Button,
  message,
  Form,
  Spin
} from "antd";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import firebase from "firebase";
import UserNavbar from "../../layout/UserNavbar";
import PageFooter from "../../layout/PageFooter";
import {useHistory} from "react-router-dom";
import styled from "styled-components";

const { Title, Text } = Typography;

const FormLabel = ({ label }) => {
  return (
    <Text
      style={{
        fontSize: 20,
        fontFamily: "GalanoMedium",
      }}
    >
      {label}
    </Text>
  );
};

const StyledInput = styled(Input)`
  border-radius: 8px;
  height: 60px;
  input {
    font-size: 17px;
    font-family: "GalandoLight";
  }
`;

const UserSettings = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const storage = firebase.storage();
  const history = useHistory();
  const [width, setWidth] = useState(window.innerWidth);
  
const handleWindowResize = useCallback((event) => {
  setWidth(window.innerWidth);
}, []);
useEffect(() => {
  window.addEventListener("resize", handleWindowResize);
  return () => {
    window.removeEventListener("resize", handleWindowResize);
  };
}, [handleWindowResize]);


  const getUser = async () => {
    setLoading(true);
    const result = firestore.collection("Users").doc(auth.currentUser.uid);
    result.get().then((querySnapshot) => {
      setUser(querySnapshot.data());
      setLoading(false);
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <UserNavbar/>
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          padding: 20,
          justifyContent:"center"
        }}
      >
        {loading ? (
          <>
            <Spin spinning={loading} style={{ marginTop: 450 }} />
          </>
        ) : (
          <>
            {user?.photoURL ? (
              <>
                <Avatar size={250} src={user?.photoURL}  style={{
          border: "5px solid #2837ef",
          borderRadius:"50%"}}/>
              </>
            ) : (
              <>
                <Avatar size={250} icon={<UserOutlined />} />
              </>
            )}
            <Space style={{ width: 90, overflow: "hidden", marginTop: 20}}>
              <input
                type="file"
                accept="image/png, image/jpeg"
                onChange={async (e) => {
                    // console.log(e.target.files[0]);
                  const isJpgOrPng =
                    e.target.files[0].type === "image/jpeg" ||
                    e.target.files[0].type === "image/png";
                  if (!isJpgOrPng) {
                    message.error("You can only upload JPG/PNG file!");
                    return;
                  }
                  const isLt2M = e.target.files[0].size / 1024 / 1024 < 2;
                  if (!isLt2M) {
                    message.error("Image must smaller than 2MB!");
                    return;
                  }
                  try {
                    const upload = await storage
                      .ref("Pictures")
                      .child(auth.currentUser.uid)
                      .put(e.target.files[0]);
                    const url = await upload.ref.getDownloadURL();
                    await firestore
                      .collection("Users")
                      .doc(auth.currentUser.uid)
                      .update({
                        photoURL: url,
                      }).then(()=>message.success('Your profile pic has beed changed!'));
                    getUser();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
            </Space>
            <Form
              // form={form}
              requiredMark={true}
              validateTrigger={false}
              layout="vertical"
              initialValues={{
                firstName: user?.firstName,
                lastName: user?.lastName
              }}
              onFinish={async(values) => {
                  await firebase.firestore().collection("Users").doc(firebase.auth().currentUser.uid).update({
                    firstName: values?.firstName,
                    lastName: values?.lastName
                  }).then(()=>message.success('Your profile has beed updated!'));
              }}
              style={{
                textAlign: "left",
                marginLeft: "5%",
                marginRight: "5%",
                padding: 20,
                fontWeight: 100,
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Space direction="vertical">
                <Card style={{ width: width > 800 ? 600 : 350, borderRadius: 15, margin: 10,
            border: ".5px solid #2837ef"}}>
                  <Form.Item
                    label={<FormLabel label="First Name" />}
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        borderRadius: 5,
                        height: 60,
                        fontSize: 17,
                        fontFamily: "GalanoLight",
                      }}
                      placeholder="First Name"
                    />
                  </Form.Item>
                </Card>
                <Card style={{ width: width > 800 ? 600 : 350, borderRadius: 15, margin: 10,
            border: ".5px solid #2837ef", }}>
                  <Form.Item
                    label={<FormLabel label="Last Name" />}
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        borderRadius: 5,
                        height: 60,
                        fontSize: 17,
                        fontFamily: "GalanoLight",
                      }}
                      placeholder="Last Name"
                    />
                  </Form.Item>
                </Card>
                <Card style={{ width: width > 800 ? 600 : 350, borderRadius: 15, margin: 10,border: ".5px solid #2837ef", }}>
                    <Form.Item
                      label={<FormLabel label="Phone Number" />}
                      name="phoneNumber"
                    >
                      <Input
                        disabled={true}
                        // value={user?.phoneNumber}
                        defaultValue={user?.phoneNumber}
                        style={{
                          borderRadius: 5,
                          height: 60,
                          fontSize: 17,
                          fontFamily: "GalanoLight",
                        }}
                        placeholder="Phone Number"
                      />
                    </Form.Item>
                </Card>
                <Card style={{ width: width > 800 ? 600 : 350, borderRadius: 15, margin: 10,border: ".5px solid #2837ef", }}>
                  <Form.Item
                    label={<FormLabel label="Email" />}
                    name="email"
                  >
                    <Input
                      disabled={true}
                      style={{
                        borderRadius: 5,
                        height: 60,
                        fontSize: 17,
                        fontFamily: "GalanoLight",
                      }}
                      placeholder="Email"
                      defaultValue={user?.email}
                    />
                  </Form.Item>
                </Card>
              </Space>
              <Form.Item>
                <Button
                  style={{
                    borderRadius: 5,
                    height: 60,
                    fontSize: 17,
                    marginTop: 20,
                    backgroundColor: "#2837ef",
                    fontFamily: "GalanoMedium",
                  }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
      <PageFooter />
    </>
  );
};
export default UserSettings;
