import React, { useState, useEffect, useCallback } from 'react';
import AdminNavbar from "../../layout/AdminNavbar";
import AdminFooter from "../../layout/AdminFooter";
import AudioScreen from './Audio';

export default function AdminVideo() {

    return (
        <>
            <AdminNavbar />
            <AudioScreen />
            <AdminFooter />
        </>
    );
}