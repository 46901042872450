import React, { useEffect, useState, useCallback } from "react";
import { Typography, Layout, Space, Avatar } from "antd";
import image2 from "../../assets/hero-banner.jpg";
import image from "../../assets/bg3.jpeg"
import Splash from "../Splash";
import firebase from "firebase";
import UserNavbar from "../../layout/UserNavbar";
import PageFooter from "../../layout/PageFooter";
import { UserOutlined, UploadOutlined } from "@ant-design/icons"
const { Content } = Layout;
const { Title, Text } = Typography;

const DoctorProfile = () => {
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState();
  const [size, setSize] = useState()
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);
  const read = async () => {
    const data = firebase
      .firestore()
      .collection("Users")
      .doc("drdee");
    data.get().then((values) => {
      setProfile(values.data());
      setLoading(false);
    });
  };
  useEffect(() => {
    setLoading(true);
    read();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return loading ? (
    <Splash />
  ) : (
    <>
      <UserNavbar />
      <div
        style={{
          height: 300,
          overflow: "hidden",
          // objectFit: 'cover',
          position: "relative",
          backgroundImage: image,
          borderBottomLeftRadius: "0%",
          borderBottomRightRadius: "30%",
        }}
      >
        <img
          src={image}
          alt=""
          style={{
            width: "100%",
            filter: 'blur(10px)',
            borderBottomLeftRadius: "0%",
            borderBottomRightRadius: "30%",
            top:-30
          }}
        />
        <Space
          style={{
            position: "absolute",
            left: 0,
            marginTop: "20%",
            backgroundColor: "rgba(0,0,0,.4)",
            padding: "10px 30px 10px 5px",
            borderRadius: "0 30px 30px 0",
          }}
        >
        </Space>
      </div>
      <div style={{}}>
        {profile?.photoURL ? ( 
        <Avatar size={200} col   style={{marginLeft: '1%' ,padding:10,marginTop: -100, backgroundColor: '#000', borderRadius: 250 }}>
          <Avatar size={190} src={profile?.photoURL} style={{zIndex: -1, backgroundColor: '#fff', marginTop: -23}}/>
        </Avatar> )
        : (
          <Avatar size={200} col   style={{marginLeft: '1%' ,padding:10,marginTop: -100, backgroundColor: '#000', borderRadius: 250 }}>
            <Avatar size={190} icon={<UserOutlined />} style={{zIndex: -1, backgroundColor: '#fff', marginTop: -23}}/>
          </Avatar>
        )}
        {width > 600 ? (null) : (<br/>)}
          
        
        <Text
          style={{
            fontFamily: "GalanoBold",
            fontSize: 40,
            marginLeft: width > 600 ? '1%' : '10%',
            marginTop: 0,
          }}
        >
          {profile?.firstName + " " + profile?.lastName}
        </Text>
        <br />
        <Text
          style={{
            fontFamily: "GalanoMedium",
            fontSize: 18,
            color: "#7a7a7a",
            marginBottom: 12,
            marginLeft: width > 600 ? '16%' : '10%',
            // marginTop: '-100px'
          }}
        >
          Some Words About Me And My Profession
        </Text>
        <hr
          style={{
            marginTop: "2%",
            borderTop: "5px solid rgb(40, 55, 239)",
            borderRadius: 3,
            width: '90%',
            marginLeft: 0,
            marginLeft: '1%'
          }} 
        />
      </div>
      <div
        style={{
          textAlign: "left",
          marginLeft: "1%",
          marginRight: "10%",
          padding: 20,
          paddingTop: 65,
          fontWeight: 100,
        }}
      >
        <div>
        <Text style={{ fontFamily: "GalanoBold", fontSize: 20 }}>Decontee Jimmeh , M.D. is a well-known and respected neurologist serving Birmingham and surrounding locations in the U.S.</Text>        </div>
        <div>
          
        <Text style={{ fontFamily: "GalanoMedium", fontSize: 14 }}>As a graduate of Xavier University of Louisiana and Temple University School of Medicine, Dr. Dee is a sought-after voice in the world of neurology. Her expertise and understanding of these conditions are coupled with an intense desire to see each patient succeed with the best treatment plans. Dr. Dee seeks to provide individuals with the best neurological care through diagnosis and treatment options.</Text>
        </div>
        <div>
        <Text style={{ fontFamily: "GalanoLight", fontSize: 14 }}>{profile?.aboutMe}</Text>
        </div>
      
      </div>

      <div
        style={{
          textAlign: "left",
          marginLeft: "1%",
          marginRight: "10%",
          padding: 20,
          fontWeight: 100,
        }}
      >
        <Text
          style={{
            fontFamily: "GalanoBold",
            fontSize: 32,
            marginBottom: 12,
          }}
        >
          Education & Credentials
        </Text>
        <hr
          style={{
            marginTop: "2%",
            borderTop: "5px solid rgb(40, 55, 239)",
            borderRadius: 3,
            width: 200,
            marginLeft: 0,
          }}
        />
        <Space direction="horizontal" wrap={true}>
          {profile?.education !== "" ?
          <Space direction="vertical" span={4}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Education
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              {profile?.education ?  profile.education.split("and").map((item)=>
              <div>
                <Text>{item}</Text>
              </div>
                ) : " "}
            </Text>
          </Space>:null}
          {profile?.languages !== "" ?
          <Space direction="vertical" style={{ padding: 20 }}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Languages Spoken
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              {profile?.languages}
            </Text>
          </Space>:null}
          {profile?.speciality !== "" ?
          <Space direction="vertical" style={{ padding: 20 }}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Speciality
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              {profile?.speciality}
            </Text>
          </Space>:null}
          {profile?.experience !== "" ?
          <Space direction="vertical" style={{ padding: 20 }}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Experience
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              
              {profile?.experience ?  profile.experience.split("and").map((item)=>
              <div>
                <Text>{item}</Text>
              </div>
                ) : " "}
            </Text>
          </Space> :null}
          {profile?.awards !== "" ?
          <Space direction="vertical" style={{ padding: 20 }}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Awards
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              
              {profile?.awards}
            </Text>
          </Space>:null}
        </Space>
      </div>
      <PageFooter />
    </>
  );
};
export default DoctorProfile;
