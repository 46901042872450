import React, { useState, useEffect,useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Typography, Space, Popover,Avatar } from "antd";
import "./AdminNavbar.css";
import logo from "../../assets/nimbleLogo.png";
import firebase from "firebase";
import AppointmentModal from "../../components/AppoinmentModal";
import Auth from "../../pages/Auth";
import styled from "styled-components";
import {UserContext} from "../../context/UserContext";
import { UserOutlined } from "@ant-design/icons";

const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 20;
  @media (max-width: 768px) {
    display: none;
  }
`;

const HeaderRight2 = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  
`;
const { Text } = Typography;

export const ProfileMenu = () => {
  const User = useContext(UserContext);
  const [userName, setUserName] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const auth = firebase.auth();
  const firestore = firebase.firestore();

  const putPicture = async () => {
    if (User.userId !== "") {
      const location = firestore.collection("Users").doc(User.userId);
      const userData = (await location.get()).data();
      setProfileImage(userData?.photoURL);
      setUserName(userData?.firstName + " " + userData?.lastName);
    }
  };
  useEffect(() => {
    putPicture();
  }, []);

  const UserName = () => {
    return <Text>{userName}</Text>;
  };

  const Content = () => {
    const history = useHistory();
    return (
      <>
        <Link to="/settings">
          <Button style={{ width: "100%" }}>Settings</Button>
        </Link>
        <Button
          style={{ width: "100%" }}
          onClick={() => {
            firebase.auth().signOut();
            history.replace("/");
            User.setValue({});
            window.localStorage.setItem("access-token", "");
          }}
        >
          Logout
        </Button>
      </>
    );
  };

  return (
    <div style={{cursor:"pointer"}}>
      <Popover
        placement="bottomRight"
        title={UserName}
        content={Content}
        trigger="click"
      >
        {(profileImage == null || profileImage == "") ? <Avatar size={45} icon={<UserOutlined />} /> :
        <img
          src={profileImage}
          height={45}
          width={45}
          style={{ borderRadius: 22 }}
        />}
      </Popover>
    </div>
  );
};

const LogIn = ({ setLogging }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onClick={() => {
        setLogging(true);
      }}
    >
      <Space className="nav-logout">
        <i className="fa fa-sign-in" style={{ fontSize: 25, paddingTop: 10 }} />
        <Button
          style={{
            backgroundColor: "transparent",
            borderColor: "transparent",
            paddingLeft: 0,
          }}
          onMouseEnter={() => setHover(!hover)}
          onMouseLeave={() => setHover(!hover)}
        >
          <Text
            style={{
              fontSize: 20,
              fontFamily: "GalanoMedium",
              color: "#fff",
            }}
          >
            Login
          </Text>
        </Button>
      </Space>
    </div>
  );
};
const LogOut = () => {
  const [hover, setHover] = useState(false);
  const history = useHistory();

  return (
    <div
      onClick={() => {
        firebase.auth().signOut();
        history.replace("/");
        window.localStorage.setItem("access-token", "");
      }}
    >
      <Space className="nav-logout">
        {/* <img src={icon} alt="" height={40} />
         */}
        <i
          className="fa fa-sign-out"
          style={{ fontSize: 20, paddingTop: 10, color: "#f5f2d0" }}
        />
        <Button
          style={{
            backgroundColor: "transparent",
            borderColor: "transparent",
            paddingLeft: 0,
          }}
          onMouseEnter={() => setHover(!hover)}
          onMouseLeave={() => setHover(!hover)}
        >
          <Text
            style={{
              fontSize: 18,
              fontFamily: "GalanoMedium",
              color: "#f5f2d0",
            }}
          >
            Logout
          </Text>
        </Button>
        {/* <NavButton text='Logout'/> */}
      </Space>
    </div>
  );
};

const NavButton = ({ text, onClick }) => {
  const [hover, setHover] = useState(false);
  const [logging, setLogging] = useState(false);
  return (
    <Button
      style={{
        padding: "8px 20px",
        borderRadius: "25px",
        border: "1px solid grey",
        height: 50,
        minWidth: 120,
        backgroundColor: hover ? "#000" : "#fff",
        boxShadow: "0 4px 6px #2973a0",
        fontFamily: "GalanoMedium",
      }}
      onMouseEnter={() => setHover(!hover)}
      onMouseLeave={() => setHover(!hover)}
      onClick={onClick}
    >
      <Text
        style={{
          color: hover ? "#fff" : "#000",
          fontFamily: "GalanoMedium",
          fontSize: 20,
        }}
      >
        {text}
      </Text>
    </Button>
  );
};

const AdminNavbar = () => {

  const auth = firebase.auth();
  const firestore = firebase.firestore();


  const [userName, setUserName] = useState(null);
  const [token, setToken] = useState(null); // This state needs to be updated with the token from twilio using the identity and chatSID
  const [show, setShow] = useState(false);

  const [logging, setLogging] = useState(false);
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click); //switches the state between true false on click
  const closeMobileMenu = () => setClick(false);

  const dual = () => {
    closeMobileMenu();
    setShow(true);
  };
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
      setClick(false);
    } else {
      setButton(true);
    }
  };

  const getName = async () => {
    if (auth.currentUser) {
      const response = await firestore.collection("Users").doc(auth.currentUser.uid).get();
      const result = response.data();
      setUserName(`${result.firstName} ${result.lastName}`);
      // console.log(`${result.firstName} ${result.lastName}`);
    }
  }

  const getToken = () => {
    console.log("This function should be used to get the token and if the token is generated properly you will navigated to the conversation screen. If there is a bug, let me know");
  }


  useEffect(() => {
    showButton();
    getName();
    // getToken();  //uncomment this line after writing the getToken function
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <nav className="navbar">
        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
          <img src={logo} alt="" className="logo" style={{ width: "150px" }} />
        </Link>
        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          {window.localStorage.getItem("access-token") != "" ? null : (
            <LogIn setLogging={setLogging} />
          )}

          {window.localStorage.getItem("access-token") != "" && click ? (
            <ProfileMenu />

          ) : (
            null
          )}
          {window.localStorage.getItem("access-token") != "" ? (
            <li className="nav-item">
              <Link
                to="/profile"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                <Text
                  style={{
                    fontFamily: "GalanoMedium",
                    fontSize: 18,
                    color: !click ? "#fff" : "#000",
                  }}
                >
                  Profile
                </Text>
              </Link>
            </li>
          ) : null}
          {window.localStorage.getItem("access-token") != "" ? (
            <li className="nav-item">
              <Link
                to="/bookings"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                <Text
                  style={{
                    fontFamily: "GalanoMedium",
                    fontSize: 18,
                    color: !click ? "#fff" : "#000",
                  }}
                >
                  Bookings
                </Text>
              </Link>
            </li>
          ) : null}
          {window.localStorage.getItem("access-token") != "" ? (
            <li className="nav-item" style={{marginRight:30}}>
              <Link to="/blogs" className="nav-links" onClick={closeMobileMenu}>
                <Text
                  style={{
                    fontFamily: "GalanoMedium",
                    fontSize: 18,
                    color: !click ? "#fff" : "#000",
                  }}
                >
                  Blog
                </Text>
              </Link>
            </li>
          ) : null}
          {window.localStorage.getItem("access-token") != "" && !click ? (
            <HeaderRight>
              <ProfileMenu />
            </HeaderRight>
          ) : null}
        </ul>
      </nav>
      <AppointmentModal visible={show} setVisible={setShow} />
      <Auth trigger={logging} setTrigger={setLogging} />
    </>
  );
};

export default AdminNavbar;