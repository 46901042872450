import React from "react";
import styled from "styled-components";
import { Image } from "antd";
import logo from "../../assets/nimbleLogo.png";

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: grey;
`;

const LogoImage = styled(Image)`
  width: 200px;
`;

const Splash = () => {
  return (
    <Container>
      <LogoImage src={logo} preview={false}/>
    </Container>
  );
};

export default Splash;