import React, { useState, useCallback, useEffect } from "react";
import {
  Typography,
  Space,
  Col,
  Input,
  Radio,
  Button,
  Form,
  Select, Modal
} from "antd";
import { useLocation, useHistory } from "react-router-dom";
import paypal from "../../assets/paypal.png";
import UserNavbar from "../../layout/UserNavbar";
import PageFooter from "../../layout/PageFooter";
import countryList from "react-select-country-list";
import styled from 'styled-components';
import firebase from "firebase";
import axios from "axios";
import { getAccessToken,return_url,cancel_url } from "../../common/getAccessToken";
const { Text } = Typography;
const { Option } = Select;

const StyledInput = styled(Input)`
  border-radius: 8px;
  height: 60px;
  input {
    font-size: 17px;
    font-family: "GalandoLight" ;
  }
`;

const FormLabel = ({ label }) => {
  return (
    <Text
      style={{
        fontSize: 20,
        fontFamily: "GalanoMedium",
      }}
    >
      {label}
    </Text>
  );
};

const Payment = (props) => {
  const [paymentModal, setPaymentModal] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const auth = firebase.auth();
  const user = auth.currentUser;
  const firestore = firebase.firestore();
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);

  const handleFontSize = () => {
    if (width > 900) {
      return "45px";
    } else if (width > 600) {
      return "26px";
    } else {
      return "16px";
    }
  };

  const handlePriceSize = () => {
    if (width > 1000) {
      return "35px";
    } else if (width > 750) {
      return "22px";
    } else {
      return "14px";
    }
  };

  // const _confirmSubmit= async() => {
  //   // console.log(values)
  //   var name =  formValues.firstName+ ' '+formValues.lastName
  //   var email = formValues.email
  //   var price = location.packagePrice
  //   var type = location.packageType
  //   // console.log("email: ",values.email)
  //   // console.log("first Name: ",values.firstName)
  //   // console.log("Last Name: ",values.lastName)
  //   console.log("Name: "+ location.packagePrice)
  //   let payload = JSON.stringify({
  //     uid: auth.currentUser.uid,
  //     name: name,
  //     email: email,
  //     price: price,
  //     type:type,
  //   });
  //   const options = {
  //     method: "POST",
  //     headers: {
  //         "Content-Type" : "application/json",
  //     },
  //     body: payload
  //   };
  //   const res = await fetch('http://localhost:3000/authenticate', options)
  //   const data = await res.json()
  //   // if (data.status === 302) {
  //   //   console.log(data.data.forwardLink)
  //   window.location = data.forwardLink
  //   // }
  //   console.log(data.forwardLink)
  // }

  const _confirmSubmit = async () => {
    const tokenResults = await getAccessToken();
    if (tokenResults) {
      setPaymentModal(false);
      const localPlanId = props.match.params.id;
      const planDocs = await firestore.collection("Plans").doc(localPlanId).get();
      const subscriptionDetails = await createPaypalSubscription(tokenResults.data.access_token, planDocs.data(), formValues);
      if (subscriptionDetails.status == 201) {
        const transactionRef = firestore.collection("Transactions");
        const transactionResult = await transactionRef.add({
          userid: user.uid,
          paypalsubid: subscriptionDetails.data.id,
          paypalplanid: planDocs.data().paypalplanid,
          status: subscriptionDetails.data.status,
          createtime: subscriptionDetails.data.create_time
        }).then(() => { return true })
        if (transactionResult) {
          window.location.replace(subscriptionDetails.data.links[0].href);
        } else {
          console.log('transaction table entry error');
        }
      } else {
        console.log('subscription error');
      }
    }
  }

  const createPaypalSubscription = async (access_token, dbplanObject, values) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${access_token}`
      }
    };

    const subscriptionBody = {
      "plan_id": dbplanObject.paypalplanid,
      "quantity": "1",
      "shipping_amount": {
        "currency_code": "USD",
        "value": "0"
      },
      "subscriber": {
        "name": {
          "given_name": values.firstName,
          "surname": values.lastName
        },
        "email_address": values.email,
        "shipping_address": {
          "name": {
            "full_name": values.firstName + ' ' + values.lastName
          },
          "address": {
            "address_line_1": values.address,
            "address_line_2": values.address2,
            "admin_area_2": values.city,
            "admin_area_1": values.state,
            "postal_code": values.zip,
            "country_code": values.country //required
          }
        },
      },
      "auto_renewal": true,
      "application_context": {
        "brand_name": dbplanObject.name + " Subscription",
        "locale": "en-US",
        "shipping_preference": "SET_PROVIDED_ADDRESS",
        "user_action": "SUBSCRIBE_NOW",
        "payment_method": {
          "payer_selected": "PAYPAL",
          "payee_preferred": "IMMEDIATE_PAYMENT_REQUIRED"
        },
        "return_url": return_url,
        "cancel_url": cancel_url
      }
    }

    const result = await axios.post('https://api.paypal.com/v1/billing/subscriptions', JSON.stringify(subscriptionBody), headers);

    return result; //subscription details
  }

  useEffect(async () => {
    if (props.match.params.id) {
      const localPlanId = props.match.params.id;
      const planDocs = await firestore.collection("Plans").doc(localPlanId).get();
      if (!planDocs.exists) {
        history.push('/profile');
      }
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <UserNavbar />

      <Space
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          paddingLeft: 200,
          paddingRight: 200,
        }}
      >
        <Text
          style={{
            fontSize: handlePriceSize(),
            fontFamily: "GalandoBold",
          }}
        >
          Package: {location.packageType}
        </Text>
        <Text
          style={{
            fontSize: handlePriceSize(),
            fontFamily: "GalandoBold",
          }}
        >
          Price: ${location.packagePrice}
        </Text>
      </Space>

      <Form
        form={form}
        requiredMark={true}
        validateTrigger={false}
        layout="vertical"
        onFinish={(values) => {
          setFormValues(values);
          setPaymentModal(true);
        }}
        style={{
          textAlign: "left",
          marginLeft: "5%",
          marginRight: "5%",
          padding: 20,
          fontWeight: 100,
          display: width > 1000 ? "flex" : "block",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Col
          span={width > 1000 ? 12 : 24}
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: width > 1000 ? 100 : 0,
            paddingBottom: 20,
          }}
        >
          <Form.Item
            label={<FormLabel label="First Name" />}
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please input your first name!",
              },
            ]}
          >
            <StyledInput placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="Last Name" />}
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please input your last name!",
              },
            ]}
          >
            <StyledInput placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="Email Address" />}
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please input your valid email!",
              },
            ]}
          >
            <StyledInput placeholder="name@address.com" />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="Company" />}
            name="company"
            rules={[
              {
                required: false,
                message: "Please input your company!",
              },
            ]}
          >
            <StyledInput placeholder="Company" />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="Phone Number" />}
            name="phone"
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
              },
            ]}
          >
            <StyledInput placeholder="Phone Number" />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="Address" />}
            name="address"
            rules={[
              {
                required: true,
                message: "Please input your address!",
              },
            ]}
          >
            <StyledInput placeholder="Address" />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="Address 2" />}
            name="address2"
            rules={[
              {
                required: false,
                message: "Optional",
              },
            ]}
          >
            <StyledInput placeholder="Address 2" />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="Country" />}
            name="country"
            rules={[
              {
                required: true,
                message: "Please input your country!",
              },
            ]}
          >
            <Select
              placeholder="Select your country"
              onSelect={(value) => {
                form.setFieldsValue({ country: value });
              }}
              allowClear
              size="large"
              showSearch={true}
            >
              {countryList().data.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={<FormLabel label="City" />}
            name="city"
            rules={[
              {
                required: true,
                message: "Please input your city!",
              },
            ]}
          >
            <StyledInput placeholder="City" />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="State" />}
            name="state"
            rules={[
              {
                required: true,
                message: "Please input your state!",
              },
            ]}
          >
            <StyledInput placeholder="State" />
          </Form.Item>
          <Form.Item
            label={<FormLabel label="Zip Code" />}
            name="zip"
            rules={[
              {
                required: true,
                message: "Please input your zip!",
              },
            ]}
          >
            <StyledInput placeholder="Zip Code" />
          </Form.Item>
        </Col>
        <Col
          span={width > 1000 ? 12 : 24}
          style={{
            display: "flex",
            flexDirection: "column",
            paddingLeft: width > 1000 ? 100 : 0,
            paddingBottom: 20,
          }}
        >
          <Text
            style={{
              fontSize: 20,
              fontFamily: "GalanoMedium",
            }}
          >
            Payment Type
          </Text>
          <Space>
            <Radio checked={true} />
            <Text
              style={{
                fontSize: 20,
                fontFamily: "GalandoLight",
              }}
            >
              PayPal
            </Text>
          </Space>
          <Text
            style={{
              fontFamily: "GalandoLight",
              fontSize: 20,
              paddingTop: 15,
              paddingBottom: 15,
            }}
          >
            You will be asked for your PayPal information on the next page.
          </Text>
          <Space direction="vertical" align="center" style={{ padding: 20 }}>
            <img src={paypal} alt="Paypal" height={100} width={100} />
            <Text
              style={{
                fontFamily: "GalandoLight",
                fontSize: 20,
                fontWeight: "bold",
              }}
            >
              PayPal Verified Since 2002
            </Text>
          </Space>
          <Space style={{ marginBottom: 25 }}>
            <Radio checked={true} />
            <Text
              style={{
                fontSize: 20,
                fontFamily: "GalandoLight",
              }}
            >
              PayPal Agreement
            </Text>
          </Space>
          <Form.Item
            label={<FormLabel label="Tax Exemption ID" />}
            name="taxexemid"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <StyledInput placeholder="Tax Exemption ID" />
          </Form.Item>
        </Col>

        <Form.Item>
          <Button
            style={{
              borderRadius: 5,
              height: 60,
              fontSize: 17,
              marginTop: 20,
              backgroundColor: "#2837ef",
              fontFamily: "GalanoMedium",
            }}
            block
            type="primary"
            htmlType="submit"
          >
            Checkout Now!
          </Button>
        </Form.Item>
      </Form>

      <Modal
        visible={paymentModal}
        onCancel={() => setPaymentModal(false)}
        onOk={_confirmSubmit}
      >
        <Text> Are you sure you want to subscribe to this package ? </Text>
      </Modal>
      <PageFooter />
    </>
  );
};

export default Payment;
