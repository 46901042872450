import React from "react";
import { useState, useLayoutEffect, useEffect, useCallback } from "react";
import { Layout, Row, Col, Typography, Divider, Space, Button } from "antd";
import { Link, useHistory } from "react-router-dom";
// import AppointmentModal from "../../components/AppoinmentModal";
import "./Footer.css";

const { Footer } = Layout;
const { Text, Title } = Typography;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      // setSize([window.innerWidth, window.innerHeight]);
      setSize([window.innerWidth]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    console.log("size: ", size);
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  console.log("size: ", size);
  return size;
}

function AdminFooter() {
  const [width, setWidth] = useState(window.innerWidth);
  // const [book, setBook] = useState(false);
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  const handlerIcon = (text) => {
    if (width > 700) {
      return "fab " + text + " fa-2x circle-icon";
    } else {
      return "fab " + text + " fa-lg circle-icon";
    }
  };

  const handlerSize = () => {
    if (width > 900) {
      return "50px";
    } else if (width > 700) {
      return "40px";
    } else {
      return "30px";
    }
  };

  const handleFontSize = () => {
    if (width > 900) {
      return "20px";
    } else if (width > 600) {
      return "15px";
    } else {
      return "12px";
    }
  };

  return (
    <>
      <Footer
        style={{
          background: "linear-gradient(90deg, #2837ef 0%, #1557e3 100%)",
          textAlign: "center",
          padding: 10,
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Col
            xl={7}
            lg={7}
            md={7}
            xs={7}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop:20
            }}
          >
            <Text
              style={{
                color: "#fff",
                fontFamily: "GalanoMedium",
                fontSize: handleFontSize(),
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Stay Connected
            </Text>
            <Space
              style={{
                paddingTop: 10,
                flexWrap: "wrap",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <i
                className={handlerIcon("fa-facebook-f")}
                style={{
                  height: handlerSize(),
                  width: handlerSize(),
                  margin: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <i
                className={handlerIcon("fa-twitter")}
                style={{
                  height: handlerSize(),
                  width: handlerSize(),
                  margin: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <i
                className={handlerIcon("fa-instagram")}
                style={{
                  height: handlerSize(),
                  width: handlerSize(),
                  margin: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <i
                className={handlerIcon("fa-pinterest-p")}
                style={{
                  height: handlerSize(),
                  width: handlerSize(),
                  margin: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <i
                className={handlerIcon("fa-linkedin-in")}
                style={{
                  height: handlerSize(),
                  width: handlerSize(),
                  margin: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
              <i
                className={handlerIcon("fa-youtube")}
                style={{
                  height: handlerSize(),
                  width: handlerSize(),
                  margin: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              />
            </Space>
          </Col>
          <Divider
            type="vertical"
            style={{
              background:
                "linear-gradient(180deg,transparent,#fff,transparent)",
              width: 2,
              height: 150,
            }}
          />
          <Col
            xl={7}
            lg={7}
            md={7}
            xs={7}
            style={{ 
              display: "flex",
              flexDirection: "column",
              paddingTop:20
            }}
          >
           <Link
              to="/"
              style={{
                color: "#fff",
                fontFamily: "GalanoMedium",
                fontSize: handleFontSize(),
                paddingBottom: 15,
              }}
            >
              Home
            </Link>
            <Link
              to="/profile"
              style={{
                color: "#fff",
                fontFamily: "GalanoMedium",
                fontSize: handleFontSize(),
                paddingBottom: 15,
              }}
            >
              Profile
            </Link>
            <Link
              to="/settings"
              style={{
                color: "#fff",
                fontFamily: "GalanoMedium",
                fontSize: handleFontSize(),
                paddingBottom: 15,
              }}
            >
              Settings
            </Link>
          </Col>

          <Divider
            type="vertical"
            style={{
              background:
                "linear-gradient(180deg,transparent,#fff,transparent)",
              width: 2,
              height: 150,
            }}
          />

          <Col
            xl={7}
            lg={7}
            md={7}
            xs={7}
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: 20,
              paddingBottom: 35,
              paddingLeft: 20,
              textAlign: "left",
            }}
          >
           
            
            <Link
              to="/bookings"
              style={{
                color: "#fff",
                fontFamily: "GalanoMedium",
                fontSize: handleFontSize(),
                paddingBottom: 15,
              }}
            >
              Bookings
            </Link>
            <Link
              to="/blogs"
              style={{
                color: "#fff",
                fontFamily: "GalanoMedium",
                fontSize: handleFontSize(),
                paddingBottom: 15,
              }}
            >
              Blog
            </Link>
          </Col>
        </Row>
      </Footer>

      <Row
        style={{
          backgroundColor: "#000",
          padding: 15,
          paddingTop: 0,
          display: "flex",
          flexDirection: "row-reverse",
        }}
      >
        <Text
          style={{
            color: "#fff",
            fontFamily: "GalanoMedium",
            fontSize: 15,
          }}
        >
          &copy;{new Date().getFullYear()} Neuro Nimble | All rights reserved |
          Terms Of Service | Privacy
        </Text>
      </Row>
    </>
  );
}

export default AdminFooter;