import React from 'react';
import AdminNavbar from "../../layout/AdminNavbar";
import AdminFooter from "../../layout/AdminFooter";
import VideoScreen from './Video';

export default function AdminVideo() {

    return (
        <>
            <AdminNavbar />
            <VideoScreen />
            <AdminFooter />
        </>
    );
}