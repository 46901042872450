import React, { useState } from "react";
import { Form, Typography, Input, Button, Select, Space } from "antd";
import { useHistory,Link } from "react-router-dom";
import firebase from "firebase";
import styled from "styled-components";

const { Text } = Typography;
const { Option } = Select;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 100px;
  padding-bottom: 100px;
`;

const StyledPasswordInput = styled(Input.Password)`
  border-radius: 8px;
  height: 60px;
  input {
    font-size: 17px;
  }
`;

const SubscriptionMenu = ({ setSubscription, setPackage }) => {
  const packages = {
    "Basic Subscription":{
        type:"Basic Subscription",
        price:15
    },
    "Routine Subscription":{
        type:"Routine Subscription",
        price:25
    },
    "High frequency subscription":{
        type:"High frequency Subscription",
        price:40
    },
}

  return (
    <Select
      defaultValue={"Select a package"}
      style={{ width: 350 }}
      size="large"
      onChange={(item) => {
        setSubscription({ subType: item });
        setPackage(packages[item]);
      }}
    >
      <Option
        value="Basic Subscription"
      >
        Basic Subscription
      </Option>
      <Option
        value="Routine Subscription"
      >
        Routine Subscription
      </Option>
      <Option
        value="High frequency subscription"
      >
        High frequency subscription
      </Option>
    </Select>
  );
};

export const Register = () => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [sub, setSub] = useState(null);
  const history = useHistory();

  const getPlans = async(planType) => {
    const snapshot = await firebase.firestore().collection('Plans').get();
    snapshot.docs.map(doc => {
      if (planType === doc.data().name) {
        return history.replace({
          pathname: `/payment/${doc.id}`,
          package: sub,
        });
      }
    });
  }

  const onLogin = async ({ email, password, firstName, lastName, subType }) => {
    try {
      setIsLoading(true);
      const userID = firebase.auth().currentUser.uid;
      const creds = firebase.auth.EmailAuthProvider.credential(email, password);
      await firebase.auth().currentUser.linkWithCredential(creds);
      await firebase.firestore().collection("Users").doc(userID).set({
        email: email,
        firstName: firstName,
        lastName: lastName,
        subType: "",
        aboutMe:"",
        awards:"",
        experience:"",
        languages:"",
        education:"",
        speciality:"",
        privilege: "user",
        phoneNumber: firebase.auth().currentUser.phoneNumber,
        photoURL: "",
        appointments:[
          {
            type: "Audio Call",
            value: 0
          },
          {
            type: "Video Call",
            value: 0
          },
          {
            type: "Virtual Chat",
            value: 0
          },
        ]
      });
      await firebase.firestore().collection("Bookings").doc(userID).set({
        dummy:"dummy value"
      });
      const Token = await firebase.auth().currentUser.getIdToken();
      window.localStorage.setItem(
        "access-token",
        JSON.stringify(Token)
      );
      await getPlans(sub.type);
    } catch (error) {
      setIsLoading(false);
      setError(true);
    }
  };

  return (
    <Container>
      <Text
        style={{
          fontSize: "40px",
          fontWeight: "600",
          fontFamily: "GalanoBold",
        }}
      >
        Nimble Neurology
      </Text>
      <Text
        style={{
          color: "#869ab8",
          fontSize: "19px",
          fontFamily: "GalanoMedium",
        }}
      >
        Looks like you're new here.
      </Text>
      <Form
        form={form}
        requiredMark={true}
        validateTrigger={false}
        layout="vertical"
        style={{ width: 350, paddingTop: 50 }}
        onFinish={onLogin}
        // onFinish={(values)=>{
        //   console.log(values);
        // }}
      >
        <Form.Item
          label={
            <Text
              style={{
                fontSize: 20,
                fontFamily: "GalanoMedium",
              }}
            >
              First Name
            </Text>
          }
          name="firstName"
          rules={[
            {
              required: true,
              message: "Please input your first name!",
            },
          ]}
        >
          <Input
            style={{
              borderRadius: 5,
              height: 60,
              fontSize: 17,
              fontFamily: "GalanoLight",
            }}
            placeholder="First Name"
          />
        </Form.Item>
        <Form.Item
          label={
            <Text
              style={{
                fontSize: 20,
                fontFamily: "GalanoMedium",
              }}
            >
              Last Name
            </Text>
          }
          name="lastName"
          rules={[
            {
              required: true,
              message: "Please input your last name!",
            },
          ]}
        >
          <Input
            style={{
              borderRadius: 5,
              height: 60,
              fontSize: 17,
              fontFamily: "GalanoLight",
            }}
            placeholder="Last Name"
          />
        </Form.Item>
        <Form.Item
          label={
            <Text
              style={{
                fontSize: 20,
                fontFamily: "GalanoMedium",
              }}
            >
              Subscription
            </Text>
          }
          name="subType"
          rules={[
            {
              required: true,
              message: "Please select a package!",
            },
          ]}
        >
          <SubscriptionMenu
            setSubscription={form.setFieldsValue}
            setPackage={setSub}
          />
        </Form.Item>
        <Form.Item
          label={
            <Text
              style={{
                fontSize: 20,
                fontFamily: "GalanoMedium",
              }}
            >
              Email Address
            </Text>
          }
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please input your valid email!",
            },
          ]}
        >
          <Input
            style={{
              borderRadius: 5,
              height: 60,
              fontSize: 17,
              fontFamily: "GalanoLight",
            }}
            placeholder="name@address.com"
          />
        </Form.Item>
        <Form.Item
          label={
            <Text
              style={{
                fontSize: 20,
                fontFamily: "GalanoMedium",
              }}
            >
              Password
            </Text>
          }
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <StyledPasswordInput
            style={{ borderRadius: 5, fontFamily: "GalanoLight" }}
            placeholder="Your Password"
          />
        </Form.Item>
        <Form.Item>
          <Button
            loading={isLoading}
            style={{
              borderRadius: 5,
              height: 60,
              fontSize: 17,
              marginTop: 20,
              backgroundColor: "#2837ef",
              fontFamily: "GalanoMedium",
            }}
            block
            type="primary"
            htmlType="submit"
          >
            Login
          </Button>
        </Form.Item>
      </Form>
      {error ?
        <Link
          to="/"
          style={{
            fontFamily: "GalanoMedium",
            paddingTop:10,
            textAlign:"center"
          }}
        >
          Go back and Try again
        </Link>
        :null
      }
    </Container>
  );
};
export default Register;
