import React, { useState, useCallback, useEffect } from "react";
import { Typography, Space, Input, Form, Button } from "antd";
import { useLocation } from "react-router-dom";
import image from "../../assets/doctorbg.jpg";
import AdminNavbar from "../../layout/AdminNavbar";
import styled from "styled-components";
import moment from "moment";
import AdminFooter from "../../layout/AdminFooter";

const { Text } = Typography;

const AdminViewBlog = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const location = useLocation();
  const publishDate = moment(parseInt(location.date))
    .toISOString()
    .split("T")[0]
    .split("-");
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);

  const fixLength = (day_month) => {
    if (day_month < 10) {
      return `0${day_month}`;
    } else {
      return day_month;
    }
  };

  const handleFontSize = () => {
    if (width > 900) {
      return "45px";
    } else if (width > 600) {
      return "26px";
    } else {
      return "16px";
    }
  };

  const htmlDecode = (text)=>{
    var e = document.createElement('div');
    e.innerHTML = text;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  return (
    <>
      <AdminNavbar />
      <div style={{ minHeight: "70vh" }}>
        <Space
          direction="vertical"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {location.blogPhoto ? (
            <img src={location.blogPhoto} width={width} height={600} />
          ) : null}
          <Text style={{ fontSize: 50, fontFamily: "GalandoBold" }}>
            {location.title}
          </Text>
          <Text style={{ fontSize: 50, fontFamily: "GalandoBold" }}>
          {publishDate[2] +
            " " +
            moment.months()[parseInt(publishDate[1]) - 1] +
            " " +
            publishDate[0]}
          </Text>
          <Space style={{ display: "flex", padding: 30 }}>
            {/* <Text style={{ fontSize: 24, fontFamily: "GalandoLight" }}>
              {location.text}
            </Text> */}
            <div dangerouslySetInnerHTML={{__html: `${location.text}`}}/>
          </Space>
        </Space>
      </div>
      <AdminFooter />
    </>
  );
};

export default AdminViewBlog;
