import React, { useEffect, useState } from "react";
import {
  Typography,
  Input,
  Space,
  Avatar,
  Card,
  Upload,
  Button,
  message,
  Form,
  Spin,
  Tooltip,
} from "antd";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import firebase from "firebase";
import AdminNavbar from "../../layout/AdminNavbar";
import {useHistory} from "react-router-dom";
import styled from "styled-components";
import AdminFooter from "../../layout/AdminFooter";

const { Title, Text } = Typography;

const AdminSettings = () => {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const storage = firebase.storage();
const history = useHistory();
  const getUser = async () => {
    setLoading(true);
    const result = firestore.collection("Users").doc(auth.currentUser.uid);
    result.get().then((querySnapshot) => {
      setUser(querySnapshot.data());
      setLoading(false);
    });
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <AdminNavbar />
      <div
        style={{
          minHeight: "100vh",
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          padding: 20,
        }}
      >
        {loading ? (
          <>
            <Spin spinning={loading} style={{ marginTop: 450 }} />
          </>
        ) : (
          <>
            {user?.photoURL ? (
              <>
                <Avatar size={200} src={user?.photoURL} />
              </>
            ) : (
              <>
                <Avatar size={200} icon={<UserOutlined />} />
              </>
            )}
            <Space style={{ width: 90, overflow: "hidden", marginTop: 20 }}>
              <input
                type="file"
                accept="image/png, image/jpeg"
                onChange={async (e) => {
                    console.log(e.target.files[0]);
                  const isJpgOrPng =
                    e.target.files[0].type === "image/jpeg" ||
                    e.target.files[0].type === "image/png";
                  if (!isJpgOrPng) {
                    message.error("You can only upload JPG/PNG file!");
                    return;
                  }
                  const isLt2M = e.target.files[0].size / 1024 / 1024 < 2;
                  if (!isLt2M) {
                    message.error("Image must be smaller than 2MB!");
                    return;
                  }
                  try {
                    const upload = await storage
                      .ref("Pictures")
                      .child(auth.currentUser.uid)
                      .put(e.target.files[0]);
                    const url = await upload.ref.getDownloadURL();
                    await firestore
                      .collection("Users")
                      .doc(auth.currentUser.uid)
                      .update({
                        photoURL: url,
                      });
                    getUser();
                  } catch (error) {
                    console.log(error);
                  }
                }}
              />
            </Space>
            <Form
              // form={form}
              requiredMark={true}
              validateTrigger={false}
              layout="vertical"
              initialValues={{
                firstName: user?.firstName,
                lastName: user?.lastName,
                phoneNumber: user?.phoneNumber,
                education: user?.education,
                experience: user?.experience,
                languages: user?.languages,
                speciality: user?.speciality,
                awards:user?.awards,
                aboutMe:user?.aboutMe
              }}
              onFinish={async(values) => {
                  await firebase.firestore().collection("Users").doc(firebase.auth().currentUser.uid).update({
                    firstName: values?.firstName,
                    lastName: values?.lastName,
                    phoneNumber: values?.phoneNumber,
                    education: values?.education,
                    experience: values?.experience,
                    languages: values?.languages,
                    speciality: values?.speciality,
                    aboutMe:values?.aboutMe
                  });
                history.replace("/");
              }}
              style={{
                marginLeft: "5%",
                marginRight: "5%",
                padding: 20,
                fontWeight: 100,
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Space direction="vertical" style={{marginRight:20}}>
                  <Form.Item
                    label="First Name"
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your first name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="First Name"
                      size="large"
                    />
                  </Form.Item>
                  <Form.Item
                    label="Last Name"
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please input your last name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Last Name"
                      size="large"
                    />
                  </Form.Item>
                  <Tooltip title="Use the button above to change phone number with OTP">
                    <Form.Item
                      label="Phone Number" 
                      name="phoneNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please input your phone number!",
                        },
                      ]}
                    >
                      <Input
                        value={user?.phoneNumber}
                        size="large"
                        placeholder="Phone Number"
                      />
                    </Form.Item>
                  </Tooltip>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: false,
                        message: "Please input your email!",
                      },
                    ]}
                  >
                    <Input
                      disabled={true}
                      placeholder="Email"
                      defaultValue={user?.email}
                      size="large"
                    />
                  </Form.Item>
              </Space>
              <Space direction="vertical" style={{marginLeft:20}}>
                  <Form.Item
                    label="Education"
                    name="education"
                    rules={[
                      {
                        required: true,
                        message: "Please input your education!",
                      },
                    ]}
                  >
                    <Input placeholder="Education" size="large"/>
                  </Form.Item>
                  <Form.Item
                    label="Experience"
                    name="experience"
                    rules={[
                      {
                        required: true,
                        message: "Please input your experience!",
                      },
                    ]}
                  >
                    <Input placeholder="Experience" size="large"/>
                  </Form.Item>
                  <Form.Item
                    label="Languages"
                    name="languages"
                    rules={[
                      {
                        required: true,
                        message: "Please input your languages!",
                      },
                    ]}
                  >
                    <Input placeholder="Languages"size="large"/>
                  </Form.Item>
                  <Form.Item
                    label="Speciality"
                    name="speciality"
                    rules={[
                      {
                        required: true,
                        message: "Please input your speciality!",
                      },
                    ]}
                  >
                    <Input placeholder="Speciality" size="large"/>
                  </Form.Item>
              </Space>
                  <Form.Item
                    label="Awards"
                    name="awards"
                    rules={[
                      {
                        required: true,
                        message: "Please fill awards field!"
                      }
                    ]}
                  >
                    <Input.TextArea size="large" placeholder="Write down your achievements"/>
                  </Form.Item>
                  <Form.Item
                    label="About Me"
                    name="aboutMe"
                    rules={[
                      {
                        required: true,
                        message: "Please fill about me field!"
                      }
                    ]}
                  >
                    <Input.TextArea size="large" placeholder="Write some words about you and your career"/>
                  </Form.Item>
              <Form.Item>
                <Button
                  style={{
                    borderRadius: 5,
                    height: 40,
                    fontSize: 17,
                    marginTop: 20,
                    backgroundColor: "blue",
                    fontFamily: "GalanoMedium",
                  }}
                  block
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        )}
      </div>
      <AdminFooter />
    </>
  );
};
export default AdminSettings;
