import React, { useState, useCallback, useEffect } from "react";
import { Typography, Space, Input, Form, Button } from "antd";
import { useLocation } from "react-router-dom";
import image from "../../assets/doctorbg.jpg";
import UserNavbar from "../../layout/UserNavbar";
import PageFooter from "../../layout/PageFooter";
import styled from "styled-components";
import moment from "moment";

const { Text } = Typography;
const { Search } = Input;

const UserViewBlog = () => {
  const [width, setWidth] = useState(window.innerWidth);
  
  const location = useLocation();
  const publishDate = moment(parseInt(location.date))
    .toISOString()
    .split("T")[0]
    .split("-");
  // const publishDate = new Date(location.date).toISOString();
  // const publishDate=new Date().toISOString();

  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);

  const handleFontSize = () => {
    if (width > 900) {
      return "45px";
    } else if (width > 600) {
      return "26px";
    } else {
      return "16px";
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);
  
  return (
    <>
      <UserNavbar />
      {/* <Space direction="vertical">
        
      </Space> */}
      <div style={{ minHeight: "70vh" }}>
        <Space
          direction="vertical"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          
          {location.blogPhoto ? (
            <img src={location.blogPhoto} width={width} height={500} />
          ) : null}
          <Text style={{ fontSize: 50, fontFamily: "GalandoBold" }}>
            {location.title}
          </Text>
          <Text style={{ fontSize: 50, fontFamily: "GalandoBold" }}>
          {publishDate[2] +
            " " +
            moment.months()[parseInt(publishDate[1]) - 1] +
            " " +
            publishDate[0]}
          </Text>
          {/* <Space style={{ display: "flex", padding: 30 }}>
            <Text style={{ fontSize: 24, fontFamily: "GalandoLight" }}>
              {location.text}
            </Text>
          </Space> */}
          
          <Space style={{ display: "flex", padding: 30 }}>
            {/* <Text style={{ fontSize: 24, fontFamily: "GalandoLight" }}>
              {location.text}
            </Text> */}
            <div dangerouslySetInnerHTML={{__html: `${location.text}`}}/>
          </Space>
        </Space>
      </div>
     
      <PageFooter />
    </>
  );
};

export default UserViewBlog;
