import React, { useState } from "react";
import { Button, Typography } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";

const MainModalContent = ({
  communication,
  timeSlot,
  navigate,
  bookAppointment,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <>
      <Button
        onClick={() => {
          navigate(1);
        }}
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          backgroundColor: "#e9ecef",
          height: 50,
          marginBottom: 10,
        }}
      >
        <Typography style={{ color: "#82777d",
            fontFamily:"GalanoLight" }}>
          {communication ? communication : "Choose Communication"}
        </Typography>
      </Button>
      <Button
        onClick={() => {
          navigate(2);
        }}
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#e9ecef",
          height: 50,
          marginBottom: 25,
        }}
      >
        <Typography style={{ color: "#82777d",
            fontFamily:"GalanoLight" }}>
          {timeSlot ? timeSlot : "Choose Time Slot"}
        </Typography>
        <ClockCircleOutlined color="#48a8e2" />
      </Button>
      {!!communication && !!timeSlot ? (
        <Button
          onMouseEnter={() => {
            setHovered(true);
          }}
          onMouseLeave={() => {
            setHovered(false);
          }}
          onClick={() => {
            bookAppointment(communication, timeSlot);
          }}
          style={{
            width: "100%",
            borderRadius: 20,
            backgroundColor: hovered ? "#000" : "#2837ef",
            border: 0,
            boxShadow: "0px 1px 2px 1px #48a8e2",
            height:40
          }}
        >
          <Typography style={{ color: "#fff", fontWeight: "bold",
            fontFamily:"GalanoMedium" }}>
            Book Appointment
          </Typography>
        </Button>
      ) : null}
    </>
  );
};

export default MainModalContent;
