import React, { useEffect, useState } from "react";
import {
  Typography,
  Input,
  Space,
  Avatar,
  Card,
  Upload,
  Button,
  message,
  Form,
} from "antd";
import { UserOutlined, UploadOutlined } from "@ant-design/icons";
import firebase from "firebase";
import AdminNavbar from "../../layout/AdminNavbar";
import styled from "styled-components";
import AdminFooter from "../../layout/AdminFooter";

const { Title, Text } = Typography;

const FormLabel = ({ label }) => {
  return (
    <Text
      style={{
        fontSize: 20,
        fontFamily: "GalanoMedium",
      }}
    >
      {label}
    </Text>
  );
};

const StyledInput = styled(Input)`
  border-radius: 8px;
  height: 60px;
  input {
    font-size: 17px;
    font-family: "GalandoLight";
  }
`;

const AdminProfile = () => {
  const [user, setUser] = useState();
  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const storage = firebase.storage();

  const getUser = async () => {
    const result = firestore.collection("Users").doc(auth.currentUser.uid);
    result.get().then((querySnapshot) => {
      setUser(querySnapshot.data());
    });
  };

  useEffect(() => {
    getUser();
    console.log(auth.currentUser);
  }, []);

  return (
    <>
      <AdminNavbar />
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          padding: 20,  
          minHeight:"80vh",
        }}
      >
        {user?.photoURL ? (
          <>
            <Avatar size={250} src={user?.photoURL} style={{marginBottom: 50}} />
          </>
        ) : (
          <>
            <Avatar size={250} icon={<UserOutlined />} style={{marginBottom: 50}} />
          </>
        )}
      <Space style={{
          flexWrap: "wrap"}}>
      <Space direction="vertical">
          <Card style={{ width: 400, borderRadius: 5, margin: 10 }}>
            <Text>First Name : {user?.firstName}</Text>
          </Card>
          <Card style={{ width: 400, borderRadius: 5, margin: 10 }}>
            <Text>Last Name : {user?.lastName}</Text>
          </Card>
          <Card style={{ width: 400, borderRadius: 5, margin: 10 }}>
            <Text>Phone Number : {user?.phoneNumber}</Text>
          </Card>
          <Card style={{ width: 400, borderRadius: 5, margin: 10 }}>
            <Text>Email : {user?.email}</Text>
          </Card>
        </Space>
        <Space direction="vertical">
          <Card style={{ width: 400, borderRadius: 5, margin: 10 }}>
            <Text>Education : {user?.education}</Text>
          </Card>
          <Card style={{ width: 400, borderRadius: 5, margin: 10 }}>
            <Text>Experience : {user?.experience}</Text>
          </Card>
          {/* <Card style={{ width: 400, borderRadius: 5, margin: 10 }}>
            <Text>Languages : {user?.languages}</Text>
          </Card> */}
          <Card style={{ width: 400, borderRadius: 5, margin: 10 }}>
            <Text>Speciality : {user?.speciality}</Text>
          </Card>
          <Card style={{ width: 400, borderRadius: 5, margin: 10 }}>
            <Text>Awards : Cheif Fellow Awards</Text>
          </Card>
        </Space>
      </Space>
      </div>
      <AdminFooter />
    </>
  );
};
export default AdminProfile;
