import "./App.css";
import "react-intl-tel-input/dist/main.css";
import React from "react";
import Router from "./routes";
import {UserProvider} from "./context/UserContext";

function App() {
  return (
    <UserProvider>
      <Router/>
    </UserProvider>
  )
}

export default App;
