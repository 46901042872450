import React, { useState, useEffect } from "react";
import Login from "./Login";
import PhoneAuthentication from "./PhoneAuthentication";
// import ProfileDetails from "./ProfileDetails";

const Auth = ({trigger,setTrigger}) => {
  // const [isNewUser, setNewUser] = useState(false);
  const [useEmail, setUseEmail] = useState(false);

  // return isNewUser ? (
  //   <ProfileDetails  />
  // ) : (
  //   <>
  //     {useEmail ? (
  //       <Login setUseEmail={setUseEmail} />
  //     ) : (
  //       <PhoneAuthentication
          
  //         setNewUser={setNewUser}
  //         setUseEmail={setUseEmail}
  //       />
  //     )}
  //   </>
  // );
  return (
    
    <>
      {useEmail ? (
        <Login setUseEmail={setUseEmail} trigger={trigger} setTrigger={setTrigger}/>
      ) : (
        <PhoneAuthentication
          setUseEmail={setUseEmail}
          trigger={trigger}
          setTrigger={setTrigger}
        />
      )}
    </>

  ); 
};
export default Auth;
