import React,{useState,useCallback,useEffect} from "react";
import image from "../../assets/doctorbg.jpg";
import UserNavbar from "../../layout/UserNavbar";
import PageFooter from "../../layout/PageFooter";
import firebase from "firebase";
import {
  Typography,
  Input,
  Button,
  message,
  Form
} from "antd";

const { Text } = Typography;
const { TextArea } = Input;

const Newsletter = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState("");

  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);

  const handleFontSize = () => {
    if (width > 900) {
      return "45px";
    } else if (width > 600) {
      return "26px";
    } else {
      return "16px";
    }
  };  

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);
  return (
    <>
      <UserNavbar />
      <div
        style={{
          textAlign: "left",
          marginLeft: "5%",
          marginRight: "10%",
          padding: 20,
          fontWeight: 100,
          height:"80vh"
        }}
      >
        <Text
          style={{
            fontFamily: "GalanoBold",
            fontSize: 30,
            fontStyle: "italic",
          }}
        >
          Newsletter
        </Text>
        <hr
          style={{
            marginTop: "2%",
            borderTop: "5px solid rgb(40, 55, 239)",
            borderRadius: 3,
            width: 200,
            marginLeft:0
          }}
        />
        <Text style={{ fontFamily: "GalanoLight", fontSize: 14 }}>
        Nimble Neurology is using telemedicine to bring neurological experts directly to the palm of your hand for increased access. Telemedicine has become an increasingly necessary form of medical practice in light of the COVID-19 pandemic. However, this is a trend that is here to stay for many reasons.
        Get newsletter from Nimble!
        </Text>
        <br />
        <br />
        <Form
          requiredMark={true}
          validateTrigger={false}
          layout="vertical"
          onFinish={async(values) => {
            const emailCheckRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
              if (emailCheckRegex.test(String(values.email)) === true) {
                await firebase.firestore().collection("Subscribers").add({email:values.email}).then(()=>{
                  message.success('Thank you for subscribing!');
                });
              }else{
                message.error('Please enter correct email!');
              }
          }}
          style={{
            paddingTop: 20
          }}
        >
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              message: "Please enter email!",
            },
          ]}
        >
          <Input
            style={{
              borderRadius: 5,
              height: 34,
              fontSize: 16,
              width:340,
              fontFamily: "GalanoLight",
            }}
            placeholder="Enter your email"
          />
        </Form.Item>
        <Form.Item>
          <Button
            style={{
              borderRadius: 5,
              height: 34,
              fontSize: 16,
              width:100,
              backgroundColor: "#2837ef",
              fontFamily: "GalanoMedium",
            }}
            block
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
      </div>
      <PageFooter/>
    </>
  );
};

export default Newsletter;
