const firebaseConfig = {
  apiKey: "AIzaSyCplubM3IBpXsVfF2lJNBKqTlWYVFMgec4",
  authDomain: "nimble-neurology.firebaseapp.com",
  projectId: "nimble-neurology",
  storageBucket: "nimble-neurology.appspot.com",
  messagingSenderId: "1084914592896",
  appId: "1:1084914592896:web:85dd9c1e3e39343f09ca65",
  measurementId: "G-MRH6ZK427M"
};

export default firebaseConfig;