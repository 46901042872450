import React, { useEffect, useState } from "react";
import { Typography, Space, Avatar } from "antd";
import { useLocation } from 'react-router-dom';
import image from "../../assets/bg3.jpeg";
import drdeeImage from "../../assets/drdee.png";
import AdminNavbar from "../../layout/AdminNavbar";
import AdminFooter from "../../layout/AdminFooter";
import axios from "axios";
import firebase from "firebase";
import { UserOutlined } from "@ant-design/icons";
import { getAccessToken } from "../../common/getAccessToken";
const { Text } = Typography;

const PayPalResult = (props) => {

  const location = useLocation();
  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const [userId, setUserId] = useState('');

  useEffect(() => {
    if (location.search) {
      const responseArray = location.search.substring(1).split('&');
      if (responseArray.length > 1) {
        const split = responseArray[2].split('=')
        const getPayPalBuy = split.find(x => x == 'PayerID');
        if (getPayPalBuy == 'PayerID') {
          getPaypalResponseBuy();
        } else {
          getPaypalResponse();
        }
      }
    }
  }, [location.search]);

  const getPaypalResponseBuy = async () => {
    const tokenResults = await getAccessToken();
    if (tokenResults) {
      const headers = {
        headers: {
          'Authorization': `Bearer ${tokenResults.data.access_token}`,
          'Content-Type': 'application/json',
        }
      };
      const responseArray = location.search.substring(1).split('&');
      const userArray = responseArray[0].split('=');
      const subArray = responseArray[1].split('=');
      const body = {
        data: ''
      }
      axios.post(`https://api.paypal.com/v1/checkout/orders/${subArray[1]}/capture`, body, headers)
        .then(async (res) => {
          if (res) {
            await completeOrder(res, userArray[1]);
          }
        }).catch(err => console.log('err', err))
    }
  }

  const createOrder = async (firestore, user, planDetails, subscriptionResult) => {
    const ordersRef = firestore.collection("Orders");
    const orderResult = await ordersRef.add({
      planid: subscriptionResult.data.plan_id,
      price: subscriptionResult.data.billing_info.last_payment.amount.value,
      status: planDetails.data.status,
      userid: user.id,
      email: user.data().email,
      name: user.data().displayName ? user.data().displayName : '',
      createdtime: planDetails.data.create_time,
    }).then(() => { return true });
    return orderResult;
  }

  const getPaypalResponse = async () => {
    // api for get transaction details
    // https://api.paypal.com/v1/billing/subscriptions/I-0KL09T03NXR6?ba_=BA-46333103KS289580L&=6CS41824AV598202E

    const tokenResults = await getAccessToken();
    if (tokenResults) {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenResults.data.access_token}`
        }
      };
      const responseArray = location.search.substring(1).split('&');
      const userArray = responseArray[0].split('=');
      const subArray = responseArray[1].split('=');
      const params = responseArray[2] + '&' + responseArray[3];
      const result = await axios.get(`https://api.paypal.com/v1/billing/subscriptions/${subArray[1]}?${params}`, headers);

      if (result.status == 200 && result.data.status == 'ACTIVE') {
        const userResults = await firestore.collection('Users').doc(userArray[1]).get();

        const allOrders = firestore.collection("Orders").where('userid', '==', userArray[1]);
        allOrders.get().then(async function (querySnapshot) {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(async function (doc) {
              if (doc.data().status != 'CANCEL' && doc.data().planid != result.data.plan_id) {
                cancelSubscription(firestore, userResults, doc.id, result, subArray[1], params);
              } else if (doc.data().planid == result.data.plan_id) {
                const datestring = new Date(firebase.firestore.Timestamp.now().seconds * 1000).toISOString();
                await firestore.collection('Orders').doc(doc.id).update({ status: 'ACTIVE', createdtime: datestring })
                transactionsUpdate(result, subArray[1], params, userArray[1]);
              }
            });

            const sameplanorders = firestore.collection("Orders").where('userid', '==', userArray[1]).where('planid', '==', result.data.plan_id);
            sameplanorders.get().then(async function (snapshots) {
              if (snapshots.empty) {
                var planDetails = {
                  data: {
                    status: 'ACTIVE',
                    create_time: new Date(firebase.firestore.Timestamp.now().seconds * 1000).toISOString()
                  }
                }
                await createOrder(firestore, userResults, planDetails, result);
                transactionsUpdate(result, subArray[1], params, userArray[1]);
              }
            });

          } else {
            var planDetails = {
              data: {
                status: 'ACTIVE',
                create_time: new Date(firebase.firestore.Timestamp.now().seconds * 1000).toISOString()
              }
            }
            await createOrder(firestore, userResults, planDetails, result);
            transactionsUpdate(result, subArray[1], params, userArray[1]);
          }
          setTimeout(function () {
            window.open('https://nimbleapp.page.link/?link=https://nimbleapp.page.link/pay', '_self');
          }, 5000);
        });
      } else if (result.status == 200 && result.data.status == 'APPROVAL_PENDING') {
        const pendingTransactions = firestore.collection("Transactions").where('userid', '==', userArray[1]).where('paypalsubid', '==', subArray[1]);
        pendingTransactions.get().then(async (transaction) => {
          if (!transaction.empty) {
            transaction.forEach(async (doc) => {
              await firestore.collection("Transactions").doc(doc.id).update({ paypaltranstoken: params, status: 'CANCEL' });
              window.open(
                'https://nimbleapp.page.link/?link=https://nimbleapp.page.link/pay',
                '_self'
              );
            });
          }
        })
      }
    }
  };

  const transactionsUpdate = (result, subid, params, userid) => {
    const transCollection = firestore.collection("Transactions").where('userid', '==', userid).where('paypalsubid', '==', subid);
    transCollection.get().then(async function (querySnapshot) {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(async function (doc) {
          await firestore.collection("Transactions").doc(doc.id).update({ paypaltranstoken: params, status: result.data.status });
          if (result.data.status == 'ACTIVE') {
            firestore.collection("Plans").where('paypalplanid', '==', result.data.plan_id).get().then(response => {
              response.docs.forEach(async (doc) => {
                const subType = `${doc.data().name} Subscription`;
                const result = firestore.collection("Users").doc(userid);
                result.get().then((querySnapshot) => {
                  const appointmentArray = [];
                  querySnapshot.data().appointments.map((item, index) => {
                    if (item.type == 'Audio Call') {
                      const totalValue = item.value + doc.data().audio;
                      var audioObject = {
                        type: item.type,
                        value: totalValue
                      }
                      appointmentArray.push(audioObject);
                    } else if (item.type == 'Video Call') {
                      const totalValue = item.value + doc.data().video;
                      var videoObject = {
                        type: item.type,
                        value: totalValue
                      }
                      appointmentArray.push(videoObject);
                    } else if (item.type == 'Virtual Chat') {
                      const totalValue = item.value + doc.data().virtual;
                      var chatObject = {
                        type: item.type,
                        value: totalValue
                      }
                      appointmentArray.push(chatObject);
                    }
                  });
                  updateUser(appointmentArray, userid, subType)
                });
              });
            })
          }
        });
      }
    });
  }

  const completeOrder = async (item, userId) => {
    const ordersRef = firestore.collection("PlanUpgrade");
    var refid
    var price
    var quantity
    item.data.purchase_units.map((res) => {
      refid = res.reference_id
      res.items.map((response) => {
        price = response.price
        quantity = response.quantity
      })
    })
    const orderResult = await ordersRef.add({
      userid: userId,
      orderid: item.data.order_id,
      status: item.data.status,
      payerid: item.data.payer.payer_id,
      amount: item.data.gross_total_amount.value,
      payeremail: item.data.payer.email_address,
      price: price,
      planname: refid,
      quantity: quantity
    }).then(() => { return true })
    const result = firestore.collection("Users").doc(userId);
    result.get().then((querySnapshot) => {
      const appointmentArray = [];
      const subType = querySnapshot.data().subType;
      querySnapshot.data().appointments.map((item, index) => {
        if (item.type == 'Audio Call') {
          if (refid == 'audiocallplan') {
            const totalValue = item.value + quantity;
            var audioObject = {
              type: item.type,
              value: totalValue
            }
            appointmentArray.push(audioObject);
          } else {
            appointmentArray.push(item);
          }
        } else if (item.type == 'Video Call') {
          if (refid == 'videocallplan') {
            const totalValue = item.value + quantity
            var videoObject = {
              type: item.type,
              value: totalValue
            }
            appointmentArray.push(videoObject);
          } else {
            appointmentArray.push(item);
          }
        } else if (item.type == 'Virtual Chat') {
          if (refid == 'virtualchatplan') {
            const totalValue = item.value + quantity
            var chatObject = {
              type: item.type,
              value: totalValue
            }
            appointmentArray.push(chatObject);
          } else {
            appointmentArray.push(item);
          }
        }
      });
      updateUser(appointmentArray, userId, subType);
    });
    setTimeout(() => {
      window.open(
        'https://nimbleapp.page.link/?link=https://nimbleapp.page.link/pay',
        '_self'
      );
    }, 6000)
  }

  const cancelSubscription = async (firestore, auth, orderid, subresult, subid, params) => {
    const tokenDetails = await getAccessToken();
    const result = firestore.collection("Transactions").where('status', '==', 'ACTIVE')
      .where('userid', '==', auth.id);
    result.get().then(response => {
      response.forEach(async doc => {
        const cancelResult = await paypalCancelUrl(doc.data().paypalsubid, tokenDetails.data.access_token);
        if (cancelResult.status == 204) {
          await firestore.collection("Orders").doc(orderid).update({ status: 'CANCEL' });
          await firestore.collection("Transactions").doc(doc.id).update({ status: 'CANCEL' });
          await firestore.collection("Users").doc(auth.id).update({ subType: '' });
        } else {
          console.log('cancel subscription fails');
        }
      })
    })
  }

  const paypalCancelUrl = async (subid, token) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    };
    const body = {
      "reason": 'Change service'
    };
    const result = await axios.post(`https://api.paypal.com/v1/billing/subscriptions/${subid}/cancel`, JSON.stringify({ body }), headers);
    return result;
  }

  const updateUser = async (appointmentArray, userId, subType) => {
    await firestore.collection("Users").doc(userId).update({
      appointments: appointmentArray,
      subType: subType
    });
  }


  return (
    <>
      <AdminNavbar />
      <div
        style={{
          height: 650,
          overflow: 'visible',
          position: "relative",
          backgroundImage: image,
          borderBottomLeftRadius: "0%",
          borderBottomRightRadius: "30%",
        }}
      >
        <div
          style={{
            height: 650,
            position: "relative",
            backgroundImage: image,
            borderBottomLeftRadius: "0%",
            borderBottomRightRadius: "30%",
            overflow: "hidden",
          }}
        >
          <img
            src={image}
            alt=""
            style={{
              width: "100%",
              filter: 'blur(10px)',
              borderBottomLeftRadius: "0%",
              borderBottomRightRadius: "30%",
              top: -30,
              overflow: "hidden"
            }}
          />
        </div>
      </div>

      <div>
        <Avatar size={200} col style={{ marginLeft: '1%', padding: 10, marginTop: -100, backgroundColor: '#000', borderRadius: 250 }}>
          <Avatar src={drdeeImage} size={190} icon={<UserOutlined />} style={{ zIndex: -1, backgroundColor: '#dedede', marginTop: -23 }} />
        </Avatar>
        <br />
        <Text
          style={{
            fontFamily: "GalanoMedium",
            fontSize: 18,
            color: "#7a7a7a",
            marginBottom: 12,
            marginLeft: '16%',
          }}
        >
          Some Words About Me And My Profession
        </Text>
        <hr
          style={{
            marginTop: "2%",
            borderTop: "5px solid rgb(40, 55, 239)",
            borderRadius: 3,
            width: '90%',
            marginLeft: 0,
            marginLeft: '1%'
          }}
        />
      </div>


      <div
        style={{
          textAlign: "left",
          marginLeft: "1%",
          marginRight: "10%",
          padding: 20,
          paddingTop: 65,
          fontWeight: 100,
          marginTop: 10
        }}
      >
        <Text strong style={{ fontFamily: "GalanoBold", fontSize: 14 }}>Decontee Jimmeh , M.D. is a well-known and respected neurologist serving Birmingham and surrounding locations in the U.S . </Text><br/><br/>
        <Text style={{ fontFamily: "GalanoMedium", fontSize: 14 }}>
        As a graduate of Xavier University of Louisiana and Temple University School of Medicine, Dr. Dee is a sought-after voice in the world of neurology. Her expertise and understanding of these conditions are coupled with an intense desire to see each patient succeed with the best treatment plans. Dr. Dee seeks to provide individuals with the best neurological care through diagnosis and treatment options.
        </Text><br/><br/>
        <Text style={{ fontFamily: "GalanoLight", fontSize: 14 }}>
        Her curiosity about the intricacies of the nervous system began in childhood. Through strong mentorships and solid education, she is equipped to offer superb clinical care and medical expertise to those who solicit her service. The Tuskegee, Alabama native was born to Liberian parents, Dr. Joe and Mrs. Roseline Jimmeh. She is a loving mother to her handsome and intelligent son, Aaron Michael and the proud sister of educator and entrepreneur, Mrs. Seanna Jimmeh-Bryce. Her love of medicine, family and community service fortify her commitment to providing excellent results to those who entrust her with their care. It is her mission to respond to challenging neurological questions with simple explanations infused with her empathetic touch.
        </Text>
      </div>

      <div
        style={{
          textAlign: "left",
          marginLeft: "1%",
          marginRight: "10%",
          padding: 20,
          fontWeight: 100,
        }}
      >
        <Text
          style={{
            fontFamily: "GalanoBold",
            fontSize: 32,
            marginBottom: 12,
          }}
        >
          Education & Credentials
        </Text>
        <hr
          style={{
            marginTop: "2%",
            borderTop: "5px solid rgb(40, 55, 239)",
            borderRadius: 3,
            width: 200,
            marginLeft: 0,
          }}
        />
        <Space direction="horizontal" wrap={true}>
          <Space direction="vertical" span={4}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Education
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              MBBS, FCPS
            </Text>
          </Space>
          <Space direction="vertical" style={{ padding: 20 }}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Speciality
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              Neurologist
            </Text>
          </Space>
          <Space direction="vertical" style={{ padding: 20 }}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Experience
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              8 years experience
            </Text>
          </Space>
          <Space direction="vertical" style={{ padding: 20 }}>
            <Text
              style={{
                fontFamily: "GalanoMedium",
                fontSize: 30,
                fontWeight: "500",
              }}
            >
              Awards
            </Text>
            <Text style={{ fontFamily: "GalanoLight", fontSize: 15 }}>
              Cheif Fellow Awards
            </Text>
          </Space>
        </Space>
      </div>
      <AdminFooter />
    </>
  );
};
export default PayPalResult;
