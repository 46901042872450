import React, { useEffect, useState, useCallback } from "react";
import { Typography, Layout, Space, Button, Divider, Grid } from "antd";
import image from "../../assets/doctorbg.jpg";
import Aos from "aos";
import "aos/dist/aos.css";
import { Payment } from "./components/Cards";
// import { flexibleCompare } from "@fullcalendar/common";
import UserNavbar from "../../layout/UserNavbar";
import PageFooter from "../../layout/PageFooter";
import Splash from "../Splash";
import Auth from "../../pages/Auth";

const { Content } = Layout;
const { Title, Text } = Typography;

const UserHome = () => {
  const [loading, setLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [logging,setLogging]= useState(false);
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);
  useEffect(() => {
    Aos.init({ duration: 3000 });
    window.addEventListener("resize", handleWindowResize);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);

  const handleFontSize = () => {
    if (width > 900) {
      return "45px";
    } else if (width > 600) {
      return "26px";
    } else {
      return "16px";
    }
  };

  return loading ? (
    <Splash />
  ) : (
    <>
      <UserNavbar />
      
      <div
        style={{
          height: width > 600 ? 650 : 300,
          overflow: "hidden",
          // objectFit: 'cover',
          position: "relative",
          backgroundImage: image,
          borderBottomLeftRadius: "30%",
          borderBottomRightRadius: "30%",
        }}
      >
        <img
          src={image}
          alt=""
          style={{
            width: "100%",
            // objectFit: 'cover',
            borderBottomLeftRadius: "30%",
            borderBottomRightRadius: "30%",
          }}
        />
        <Space
          style={{
            position: "absolute",
            left: 0,
            marginTop: "20%",
            backgroundColor: "rgba(0,0,0,.4)",
            padding: 20,
            borderRadius: "0 60px 60px 0",
          }}
        >
          <Text
            style={{
              fontFamily: "GalanoBold",
              fontSize: handleFontSize(),
              fontStyle: "italic",
              color: "#fff",
            }}
          >
          'Where Your Health Matters'
          </Text>
        </Space>
      </div>

      <div
        style={{
          textAlign: "left",
          marginLeft: "5%",
          marginRight: "10%",
          padding: 20,
          fontWeight: 100,
        }}
      >
        <Text
          style={{
            fontFamily: "GalanoBold",
            fontSize: 30,
            fontStyle: "italic",
          }}
        >
          We’d like to introduce to you Nimble Neurology
        </Text>
        <hr
          style={{
            marginTop: "2%",
            borderTop: "5px solid rgb(40, 55, 239)",
            borderRadius: 3,
            width: 200,
            marginLeft: "0%",
          }}
        />
        <Text style={{ fontFamily: "GalanoLight", fontSize: 14 }}>
          It's new web application that allows you to see a certified
          neurologist virtually through video chat, virtual chat, or a phone
          call. If you suffer from neurological conditions like multiple
          sclerosis (MS), memory loss, uncontrolled headaches, imbalance, or
          just want a second opinion we’d like to help.
        </Text>
        <br />
        <br />
        <Text style={{ fontFamily: "GalanoLight", fontSize: 14 }}>
          Nimble Neurology is using telemedicine to bring neurological experts
          directly to the palm of your hand for increased access. Telemedicine
          has become an increasingly necessary form of medical practice in light
          of the COVID-19 pandemic. However, this is a trend that is here to
          stay.
        </Text>
      </div>
      <div style={{ marginTop: 0, marginLeft: "6%" }}>
        <div className="boxes aos-init aos-animate" data-aos="fade-right">
          <Text
            style={{
              fontFamily: "GalanoMedium",
              // fontSize: 15,
              fontStyle: "italic",
            }}
          >
            1 - Do you wish you could gather more information from your
            certified neurologist regarding your elderly family member's
            dementia?
          </Text>
        </div>
        <div className="boxes aos-init aos-animate" data-aos="fade-right">
          <Text
            style={{
              fontFamily: "GalanoMedium",
              // fontSize: 15,
              fontStyle: "italic",
            }}
          >
            2 - Do you ever feel like others are getting better healthcare from
            their physician because they have insurance coverage superior to
            yours?
          </Text>
        </div>
        <div className="boxes aos-init aos-animate" data-aos="fade-right">
          <Text
            style={{
              fontFamily: "GalanoMedium",
              // fontSize: 15,
              fontStyle: "italic",
            }}
          >
            3 - Do you ever feel frustrated when you just have a quick question
            for your doctor but dread picking up the phone?
          </Text>
        </div>
        <div className="boxes aos-init aos-animate" data-aos="fade-right">
          <Text
            style={{
              fontFamily: "GalanoMedium",
              // fontSize: 15,
              fontStyle: "italic",
            }}
          >
            4 - Ever feel like you are getting the runaround from your
            physician's office?
          </Text>
        </div>
        <div className="boxes aos-init aos-animate" data-aos="fade-right">
          <Text
            style={{
              fontFamily: "GalanoMedium",
              // fontSize: 15,
              fontStyle: "italic",
            }}
          >
            5 - Do you ever wish you could send a quick text to your provider
            and get a timely response
          </Text>
        </div>
      </div>

      <div
        style={{
          marginTop: "5%",
          marginBottom: "0",
          marginLeft: 0,
          textAlign: "center",
          paddingTop: 20,
          paddingLeft: 0,
          paddingRight: "2%",
          color: "#1557e3",
          fontWeight: 700,
          fontSize: 25,
          width: "80%",
          height: 60,
          textAlign: "left",
          marginLeft: "5%",
          marginRight: "10%",
          // padding: 20,
          fontWeight: 100,
        }}
      >
        <Text style={{ fontFamily: "GalanoBold", fontSize: 20 }}>
          "Now, with Nimble Neurology, get direct access to a professional via
          our app. Skip the on hold music, and the all day wait for a call
          back."
        </Text>
      </div>
      <div
        style={{
          textAlign: "left",
          marginLeft: "5%",
          marginRight: "10%",
          padding: 20,
          fontWeight: 100,
          marginTop: width > 600 ? 100 : 150,
        }}
      >
        <Text
          style={{
            fontFamily: "GalanoBold",
            fontSize: 30,
            fontStyle: "italic",
          }}
        >
          We’d like to introduce to you Nimble Neurology
        </Text>
        <hr
          style={{
            marginTop: "2%",
            borderTop: "5px solid rgb(40, 55, 239)",
            borderRadius: 3,
            width: 200,
            marginLeft: "0%",
          }}
        />
      </div>
      <div style={{ marginTop: "25px" }}>
        <Payment width={width} setTrigger={setLogging}/>
      </div>
      <PageFooter />
      <Auth trigger={logging} setTrigger={setLogging}/>
    </>
  );
};
export default UserHome;
