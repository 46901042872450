import React, { useEffect, useState, useCallback } from "react";
import {
  Typography,
  Layout,
  Space,
  Avatar,
  Card,
  Modal,
  Button,
  message,
} from "antd";
import { Button as CustomButton } from "../../layout/UserNavbar/Button";
import { UserOutlined } from "@ant-design/icons";
import firebase from "firebase";
import UserNavbar from "../../layout/UserNavbar";
import PageFooter from "../../layout/PageFooter";
import { Payment, EachPayment } from "./components/Cards";
import { useLocation, useHistory } from 'react-router-dom';
import axios from "axios";
import { getAccessToken } from "../../common/getAccessToken";

const { Title, Text } = Typography;

const UserProfile = () => {
  const [user, setUser] = useState();
  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const storage = firebase.storage();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalVisibleBuy, setModalVisibleBuy] = useState(false);
  const [plans, setPlans] = useState([]);

  const [eachplans, setEachPlans] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [isPlanActive, setIsPlanActive] = useState(false);
  const [activePlan, setActivePlan] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const [UserId, setUserId] = useState("");
    
  const handleWindowResize = useCallback((event) => {
    setWidth(window.innerWidth);
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [handleWindowResize]);
  
  


  const getPaypalResponse = async () => {
    // api for get transaction details
    // https://api.paypal.com/v1/billing/subscriptions/I-0KL09T03NXR6?ba_=BA-46333103KS289580L&=6CS41824AV598202E

    const tokenResults = await getAccessToken();
    if (tokenResults) {
      const headers = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokenResults.data.access_token}`
        }
      };
      const responseArray = location.search.split('&');
      const subArray = responseArray[0].split('=');
      const params = responseArray[1] + '&' + responseArray[2];
      const result = await axios.get(`https://api.paypal.com/v1/billing/subscriptions/${subArray[1]}?${params}`, headers);
      if (result.status == 200 && result.data.status == 'ACTIVE') {
        const sameplanorders = firestore.collection("Orders").where('userid', '==', auth.currentUser.uid).where('planid', '==', result.data.plan_id);
        sameplanorders.get().then(async function (snapshots) {
          if (snapshots.empty) {
            var planDetails = {
              data: {
                status: 'ACTIVE',
                create_time: new Date(firebase.firestore.Timestamp.now().seconds * 1000).toISOString()
              }
            }
            await createOrder(firestore, auth, planDetails, result);
          }
        });

        const allOrders = firestore.collection("Orders").where('userid', '==', auth.currentUser.uid);
        allOrders.get().then(async function (querySnapshot) {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(async function (doc) {
              if (doc.data().status != 'CANCEL' && doc.data().planid != result.data.plan_id) {
                cancelSubscription(firestore, auth, doc.id, result, subArray[1], params);
              } else if (doc.data().status != 'ACTIVE' && doc.data().planid == result.data.plan_id) {
                const datestring = new Date(firebase.firestore.Timestamp.now().seconds * 1000).toISOString();
                await firestore.collection('Orders').doc(doc.id).update({ status: 'ACTIVE', createdtime: datestring })
                transactionsUpdate(result, subArray[1], params);
              } else if (doc.data().status == 'ACTIVE' && doc.data().planid == result.data.plan_id) {
                const datestring = new Date(firebase.firestore.Timestamp.now().seconds * 1000).toISOString();
                await firestore.collection('Orders').doc(doc.id).update({ status: 'ACTIVE', createdtime: datestring })
                transactionsUpdate(result, subArray[1], params);
              }
            });
          } else {
            var planDetails = {
              data: {
                status: 'ACTIVE',
                create_time: new Date(firebase.firestore.Timestamp.now().seconds * 1000).toISOString()
              }
            }
            const orderResult = await createOrder(firestore, auth, planDetails, result);
            if (orderResult) {
              transactionsUpdate(result, subArray[1], params);
            }
          }
        });
      } else if (result.status == 200 && result.data.status == 'APPROVAL_PENDING') {
        const pendingTransactions = firestore.collection("Transactions").where('userid', '==', auth.currentUser.uid).where('paypalsubid', '==', subArray[1]);
        pendingTransactions.get().then(async (transaction) => {
          if (!transaction.empty) {
            transaction.forEach(async (doc) => {
              await firestore.collection("Transactions").doc(doc.id).update({ paypaltranstoken: params, status: 'CANCEL' });
              history.push('/profile')
              window.location.reload();
            });
          }
        })
      }
    }
  };

  const transactionsUpdate = (result, subid, params) => {
    const transCollection = firestore.collection("Transactions").where('userid', '==', auth.currentUser.uid)
      .where('paypalsubid', '==', subid);
    transCollection.get().then(async function (querySnapshot) {
      if (!querySnapshot.empty) {
        querySnapshot.forEach(async function (doc) {
          await firestore.collection("Transactions").doc(doc.id).update({ paypaltranstoken: params, status: result.data.status });
          if (result.data.status == 'ACTIVE') {
            firestore.collection("Plans").where('paypalplanid', '==', result.data.plan_id).get().then(response => {
              response.docs.forEach(async (doc) => {
                const subType = `${doc.data().name} Subscription`;
                const result = firestore.collection("Users").doc(auth.currentUser.uid);
                result.get().then((querySnapshot) => {
                  const appointmentArray = [];
                  querySnapshot.data().appointments.map((item, index) => {
                    if (item.type == 'Audio Call') {
                      const totalValue = item.value + doc.data().audio;
                      var audioObject = {
                        type: item.type,
                        value: totalValue
                      }
                      appointmentArray.push(audioObject);
                    } else if (item.type == 'Video Call') {
                      const totalValue = item.value + doc.data().video;
                      var videoObject = {
                        type: item.type,
                        value: totalValue
                      }
                      appointmentArray.push(videoObject);
                    } else if (item.type == 'Virtual Chat') {
                      const totalValue = item.value + doc.data().virtual;
                      var chatObject = {
                        type: item.type,
                        value: totalValue
                      }
                      appointmentArray.push(chatObject);
                    }
                  });
                  updateUser(appointmentArray, subType)
                });
              });
            })
          }
        });
      }
    });
  }

  const completeOrder = async (item) => {
    const ordersRef = firestore.collection("PlanUpgrade");
    var refid
    var price
    var quantity
    item.data.purchase_units.map((res) => {
      refid = res.reference_id
      res.items.map((response) => {
        price = response.price
        quantity = response.quantity
      })
    })
    const orderResult = await ordersRef.add({
      userid: auth.currentUser.uid,
      orderid: item.data.order_id,
      status: item.data.status,
      payerid: item.data.payer.payer_id,
      amount: item.data.gross_total_amount.value,
      payeremail: item.data.payer.email_address,
      price: price,
      planname: refid,
      quantity: quantity
    }).then(() => { return true })
    const result = firestore.collection("Users").doc(auth.currentUser.uid);
    result.get().then((querySnapshot) => {
      const subType = querySnapshot.data().subType;
      const appointmentArray = [];
      querySnapshot.data().appointments.map((item, index) => {
        if (item.type == 'Audio Call') {
          if (refid == 'audiocallplan') {
            const totalValue = item.value + quantity;
            var audioObject = {
              type: item.type,
              value: totalValue
            }
            appointmentArray.push(audioObject);
          } else {
            appointmentArray.push(item);
          }
        } else if (item.type == 'Video Call') {
          if (refid == 'videocallplan') {
            const totalValue = item.value + quantity
            var videoObject = {
              type: item.type,
              value: totalValue
            }
            appointmentArray.push(videoObject);
          } else {
            appointmentArray.push(item);
          }
        } else if (item.type == 'Virtual Chat') {
          if (refid == 'virtualchatplan') {
            const totalValue = item.value + quantity
            var chatObject = {
              type: item.type,
              value: totalValue
            }
            appointmentArray.push(chatObject);
          } else {
            appointmentArray.push(item);
          }
        }
      });
      updateUser(appointmentArray, subType)
    });
  }

  const getPaypalResponseBuy = async () => {
    const tokenResults = await getAccessToken();
    if (tokenResults) {
      const headers = {
        headers: {
          'Authorization': `Bearer ${tokenResults.data.access_token}`,
          'Content-Type': 'application/json',
        }
      };
      const responseArray = location.search.substring(1).split('&');
      const subArray = responseArray[0].split('=');
      const params = responseArray[0] + '&' + responseArray[1];
      const body = {
        data: ''
      }
      axios.post(`https://api.paypal.com/v1/checkout/orders/${subArray[1]}/capture`, body, headers)
        .then(async (res) => {
          if (res) {
            await completeOrder(res);
          }
        }).catch(err => console.log('err', err))
    }
  }

  const getPlans = async () => {
    const result = firestore.collection("Plans").orderBy('price', 'asc');
    result.get().then((querySnapshot) => {
      var planArray = [];
      querySnapshot.forEach((doc) => {
        var planObject = {};
        planObject.id = doc.id;
        planObject.name = doc.data().name;
        planObject.price = doc.data().price;
        planObject.symbol = doc.data().pricesymbol;
        planObject.paypalplanid = doc.data().paypalplanid;
        planObject.paypalproductid = doc.data().paypalproductid;
        planObject.profile = doc.data().profile;
        planObject.audio = doc.data().audio;
        planObject.video = doc.data().video;
        planObject.virtual = doc.data().virtual;
        planObject.virtualminutes = doc.data().virtualminutes;

        planArray.push(planObject);
      });
      setPlans(planArray);
    });
  };

  const getEachPlans = async () => {
    const result = firestore.collection("EachPlan")
    result.get().then((querySnapshot) => {
      var planArray = [];
      querySnapshot.forEach((doc) => {
        var planObject = {};
        planObject.uid = doc.id;
        planObject.amount = doc.data().amount;
        planObject.audio = doc.data().audio;
        planObject.video = doc.data().video;
        planObject.chat = doc.data().chat;
        planArray.push(planObject);
      });
      setEachPlans(planArray);
    });
  };

  const userActivePlan = async () => {
    const result = firestore.collection("Orders").where('status', '==', 'ACTIVE').where('userid', '==', auth.currentUser.uid);
    result.get().then(response => {
      if (!response.empty) {
        setIsPlanActive(true)
        var planArray = [];
        response.forEach(doc => {
          var planObject = {};
          planObject.id = doc.id;
          planObject.planid = doc.data().planid;
          planArray.push(planObject);
          setActivePlan(planArray);
        })
      } else {
        setIsPlanActive(false)
      }
    })
  }

  const getUser = async (userId) => {
    const result = firestore.collection("Users").doc(userId);
    result.get().then((querySnapshot) => {
      setUser(querySnapshot.data());
    });
  };

  const cancelSubscription = async (firestore, auth, orderid, subresult, subid, params) => {
    const tokenDetails = await getAccessToken();
    const result = firestore.collection("Transactions").where('status', '==', 'ACTIVE')
      .where('userid', '==', auth.currentUser.uid);
    result.get().then(response => {
      response.forEach(async doc => {
        const cancelResult = await paypalCancelUrl(doc.data().paypalsubid, tokenDetails.data.access_token);
        if (cancelResult.status == 204) {
          await firestore.collection("Orders").doc(orderid).update({ status: 'CANCEL' });
          await firestore.collection("Transactions").doc(doc.id).update({ status: 'CANCEL' });
          await firestore.collection("Users").doc(auth.id).update({ subType: '' });
          transactionsUpdate(subresult, subid, params);
        } else {
          console.log('cancel subscription fails');
        }
      })
    })
  }

  const createOrder = async (firestore, user, planDetails, subscriptionResult) => {
    const ordersRef = firestore.collection("Orders");
    const orderResult = await ordersRef.add({
      planid: subscriptionResult.data.plan_id,
      price: subscriptionResult.data.billing_info.last_payment.amount.value,
      status: planDetails.data.status,
      userid: user.currentUser.uid,
      email: user.currentUser.email,
      name: user.currentUser.displayName ? user.currentUser.displayName : '',
      createdtime: planDetails.data.create_time,
    }).then(() => { return true });
    return orderResult;
  }

  const cancelDirectSubscription = async () => {
    const tokenDetails = await getAccessToken();
    const result = firestore.collection("Transactions").where('status', '==', 'ACTIVE')
      .where('userid', '==', auth.currentUser.uid);
    result.get().then(response => {
      response.forEach(async doc => {
        const cancelResult = await paypalCancelUrl(doc.data().paypalsubid, tokenDetails.data.access_token);
        if (cancelResult.status == 204) {
          await firestore.collection("Orders").doc(activePlan[0].id).update({ status: 'CANCEL' });
          await firestore.collection("Transactions").doc(doc.id).update({ status: 'CANCEL' });
          userActivePlan();
          getUser(auth.currentUser.uid);
        } else {
          console.log('cancel subscription fails');
        }
      })
    })
  }

  const paypalCancelUrl = async (subid, token) => {
    const headers = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }
    };
    const body = {
      "reason": 'Change service'
    };
    const result = await axios.post(`https://api.paypal.com/v1/billing/subscriptions/${subid}/cancel`, JSON.stringify({ body }), headers);
    return result;
  }

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      setUserId(user.uid);
      
    getUser(user.uid);
    getPlans();
    getEachPlans();
    userActivePlan();

    if (location.search) {
      const responseArray = location.search.substring(1).split('&');
      if (responseArray.length > 1) {
        const split = responseArray[1].split('=')
        const getPayPalBuy = split.find(x => x == 'PayerID');
        if (getPayPalBuy == 'PayerID') {
          getPaypalResponseBuy();
        } else {
          getPaypalResponse();
        }
      } else {
        getUser(UserId);
        history.push('/profile')
        window.location.reload();
      }
    }
    }
    });
  }, []);

  const updateUser = async (appointmentArray, subType) => {
    await firestore.collection("Users").doc(auth.currentUser.uid).update({
      appointments: appointmentArray,
      subType: subType
    });
    getUser(auth.currentUser.uid);
    history.push('/profile')
    window.location.reload();
  }

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleCancelBuy = () => {
    setModalVisibleBuy(false);
  };

  return (
    <>
      <UserNavbar />
      {/* <div style={{flexDirection: "row", display: "flex", flex: 1, padding: 20, width:'100%'}}> */}
      <div
        style={{
          // height: 700,
          display: "flex",
          flex: 1,
          flexDirection: "column",
          alignItems: "center",
          fontFamily: "GalanoMedium",
          // marginLeft: 10,
          padding: 20,
        }}
      >
        {user?.photoURL ? (
          <>
            <Avatar size={150} src={user?.photoURL} style={{
              border: "5px solid #2837ef",
              borderRadius: "50%"
            }} />
          </>
        ) : (
          <>
            <Avatar size={150} icon={<UserOutlined />} />
          </>
        )}
        <Space style={{ marginTop: 20, marginBottom: 40 }}>
          {isPlanActive ? (
            <>
              <Button onClick={() => setModalVisible(true)} style={{ margin: 5, fontFamily: "GalanoMedium" }}>
                Modify Subscription
              </Button>
              <Button style={{ margin: 5, fontFamily: "GalanoMedium" }} onClick={cancelDirectSubscription}>
                Cancel Subscription
              </Button>
            </>
          ) : null}
        </Space>
        <div
          style={{
            height: 400,
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            // marginLeft: 10,
            marginTop: !isPlanActive ? 20 : -50,
            padding: 20,
          }}
        >
          <Card style={{
            display: "flex", width: 600, justifyContent: 'center', borderRadius: 10, padding: 20, backgroundColor: "#fff",
            border: ".5px solid #2837ef",
            borderRadius: 20
          }} >
            <Card.Grid style={{ height: 80, fontSize: 12, backgroundColor: "rgb(40, 55, 239)", color: '#fff', textAlign: 'center', width: '33.33%', borderRadius: '10px 0px 0px 0px' }}>Remaining Audios</Card.Grid>
            <Card.Grid style={{ height: 80, fontSize: 12, backgroundColor: "rgb(40, 55, 239)", color: '#fff', textAlign: 'center', width: '33.33%', borderRadius: '0px 0px 0px 0px' }}>Remaining Videos</Card.Grid>
            <Card.Grid style={{ height: 80, fontSize: 12, backgroundColor: "rgb(40, 55, 239)", color: '#fff', textAlign: 'center', width: '33.33%', borderRadius: '0px 10px 0px 0px' }}>Remaining Chats</Card.Grid>
            <Card.Grid style={{
              textAlign: 'center', height: 70, width: '33.33%', borderRadius: '0px 0px 0px 10px',
              border: ".5px solid #2837ef",
            }}>{user ? user?.appointments[0]?.value : 0}</Card.Grid>
            <Card.Grid style={{
              textAlign: 'center', borderLeft: "2px solid rgb(40, 55, 239)", height: 70, width: '33.33%', borderRadius: '0px 0px 0px 0px',
              border: ".5px solid #2837ef",
            }}>{user ? user?.appointments[1]?.value : 0}</Card.Grid>
            <Card.Grid style={{
              textAlign: 'center', borderLeft: "2px solid rgb(40, 55, 239)", height: 70, width: '33.33%', borderRadius: '0px 0px 10px 0px',
              border: ".5px solid #2837ef",
            }}>{user ? user?.appointments[2]?.value : 0}</Card.Grid>
            {!isPlanActive ? (
              // <Button onClick={() => setModalVisible(true)} style={{ height:60,  backgroundColor: 'rgb(40, 55, 239)', color: '#fff', borderRadius: 30,marginTop:20, width:"100%" }}>Buy Now</Button>
              <CustomButton
                buttonStyle="btn--outline"
                onClick={() => setModalVisible(true)}
                style={{ width: "100%", marginTop: 10 }}
                text="Buy Subscription"
              />
            ) : null}

            <CustomButton
              buttonStyle="btn--outline"
              onClick={() => setModalVisibleBuy(true)}
              style={{ width: "100%", marginTop: 10 }}
              text="Buy More"
            />

          </Card>
        </div>
        {/* <Space>
          <Card style={{ width: 400, borderRadius: 5, margin: 10 }} >
            <Text>First Name : {user?.firstName}</Text>
          </Card>
        </Space> */}
        <Space>
          <Space direction='vertical'>
            <Card style={{
              width: 600, borderRadius: 5, margin: 10,
              border: ".5px solid #2837ef",
              borderRadius: 20
            }}>
              <Text>First Name : {user?.firstName}</Text>
            </Card>
            <Card style={{
              width: 600, borderRadius: 5, margin: 10,
              border: ".5px solid #2837ef",
              borderRadius: 20
            }}>
              <Text>Last Name : {user?.lastName}</Text>
            </Card>
            <Card style={{
              width: 600, borderRadius: 5, margin: 10,
              border: ".5px solid #2837ef",
              borderRadius: 20
            }}>
              <Text>Phone Number : {user?.phoneNumber}</Text>
            </Card>
            <Card style={{
              width: 600, borderRadius: 5, margin: 10,
              border: ".5px solid #2837ef",
              borderRadius: 20
            }}>
              <Text>Email : {user?.email}</Text>
            </Card>
          </Space>
          {/* <Space direction='vertical'>
            <Card style={{ width: 400, borderRadius: 5, margin: 10 }}>
              <Text>First Name : {user?.firstName}</Text>
            </Card>
            <Card style={{ width: 400, borderRadius: 5, margin: 10 }}>
              <Text>Last Name : {user?.lastName}</Text>
            </Card>
            <Card style={{ width: 400, borderRadius: 5, margin: 10 }}>
              <Text>Phone Number : {user?.phoneNumber}</Text>
            </Card>
            <Card style={{ width: 400, borderRadius: 5, margin: 10 }}>
              <Text>Email : {user?.email}</Text>
            </Card>
          </Space> */}
        </Space>
      </div>
      <Modal
        visible={modalVisible}
        title="Plans"
        onCancel={handleCancel}
        footer={null}
        width={"70%"}
      >
        <Payment plans={plans} firestore={firestore} activePlan={isPlanActive ? activePlan : []} />
      </Modal>

      <Modal
        visible={modalVisibleBuy}
        title="EachPlans"
        onCancel={handleCancelBuy}
        footer={null}
        width={"70%"}
      >
        <EachPayment plans={eachplans} firestore={firestore} activePlan={isPlanActive ? activePlan : []} />
      </Modal>
      <PageFooter />
    </>
  );
};
export default UserProfile;
