import React from "react";
import { Typography, Space } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const ModalHeader = ({ screen, navigate }) => {
  if (screen === 0) {
    return (
      <Space direction="vertical" style={{ paddingTop: 10 }}>
        <Typography
          style={{
            color: "#333f4b",
            fontWeight: "bold",
            fontSize: 20,
            marginBottom: 20,
            fontFamily:"GalanoLight"
          }}
        >
          Best health care service awaits you
        </Typography>
        <Typography style={{ fontSize: 16,
            fontFamily:"GalanoLight" }}>
          Private online consultation with verified doctors in all specialities
        </Typography>
      </Space>
    );
  } else if (screen === 1) {
    return (
      <Space direction="horizontal">
        <ArrowLeftOutlined
          onClick={() => {
            navigate(0);
          }}
        />
        <Typography style={{ fontWeight: "bold",
            fontFamily:"GalanoLight" }}>
          Choose Communication
        </Typography>
      </Space>
    );
  } else {
    return (
      <Space direction="horizontal">
        <ArrowLeftOutlined
          onClick={() => {
            navigate(0);
          }}
        />
        <Typography style={{ fontWeight: "bold",
            fontFamily:"GalanoLight" }}>Choose Time Slot</Typography>
      </Space>
    );
  }
};

export default ModalHeader;
