import React, { useState, useEffect, useCallback } from 'react';
import AdminNavbar from "../../layout/AdminNavbar";
import AdminFooter from "../../layout/AdminFooter";
import ChatScreen from './Chat';

export default function AdminChat() {

    return (
        <>
            <AdminNavbar />
                <ChatScreen />
            <AdminFooter />
        </>
    );
}