import React, { useState, useEffect,useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Typography, Space, Popover,Avatar } from "antd";
import { Button as CustomButton } from "./Button";
import "./UserNavbar.css";
import logo from "../../assets/nimbleLogo.png";
import icon from "../../assets/header-user.png";
import AppoinmentModal from "../../components/AppoinmentModal";
import Auth from "../../pages/Auth";
import firebase from "firebase";
import styled from "styled-components";
import { UserOutlined } from "@ant-design/icons";
import {UserContext} from "../../context/UserContext";

const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  right: 15px;
  top: 15px;
  z-index: 20;
  @media (max-width: 768px) {
    display: none;
  }
  
`;

const HeaderRight2 = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
  
`;

const { Text } = Typography;

export const ProfileMenu = () => {
  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const Context = useContext(UserContext);
  const [parsedData, setParsedData] = useState({});

  const getUser = async() => {
    if (Context.userId !== "") {
      const location = firestore.collection("Users").doc(Context.userId);
      const userData = (await location.get()).data();
      setParsedData(userData);
    }
  }

  React.useEffect(() => {
    getUser();
  }, []);

  const UserName = () => {
    return <Text>{Context.name}</Text>;
  };

  const Content = () => {
    const history = useHistory();
    return (
      <>
        <Link to="/settings">
          <Button style={{ width: "100%" }}>Settings</Button>
        </Link>
        <Button
          style={{ width: "100%" }}
          onClick={() => {
            firebase.auth().signOut();
            history.replace("/");
            Context.setValue({});
            window.localStorage.setItem("access-token", "");
          }}
        >
          Logout
        </Button>
      </>
    );
  };

  return (
    <div style={{cursor:"pointer"}}>
      <Popover
        placement="bottomRight"
        title={UserName}
        content={Content}
        trigger="click"
      >
        {(parsedData?.photoURL == null || parsedData?.photoURL == "") ? <Avatar size={45} icon={<UserOutlined />} /> :
        <img
          src={parsedData?.photoURL}
          height={45}
          width={45}
          style={{ borderRadius: 22 }}
        />}
      </Popover>
    </div>
  );
};

const LogIn = ({ setLogging }) => {
  const [hover, setHover] = useState(false);

  return (
    <div
      onClick={() => {
        setLogging(true);
      }}
    >
      <div className="nav-logout">
        <Button
          style={{
            backgroundColor: "transparent",
            borderColor: "transparent",
          }}
          onMouseEnter={() => setHover(!hover)}
          onMouseLeave={() => setHover(!hover)}
        >
          <Text
            style={{
              fontSize: 18,
              fontFamily: "GalanoMedium",
              color: "#f5f2d0",
            }}
          >
            Login
          </Text>
        </Button>
      </div>
    </div>
  );
};

const LogOut = () => {
  const [hover, setHover] = useState(false);
  const history = useHistory();

  return (
    <div
      onClick={() => {
        firebase.auth().signOut();
        history.replace("/");
        window.localStorage.setItem("access-token", "");
      }}
    >
      <Space className="nav-logout">
        {/* <img src={icon} alt="" height={40} />
         */}
        <i
          className="fa fa-sign-out"
          style={{ fontSize: 20, paddingTop: 10, color: "#f5f2d0" }}
        />
        <Button
          style={{
            // position: "absolute",
            // top: 25,
            // borderRadius: 20,
            backgroundColor: "transparent",
            borderColor: "transparent",
            paddingLeft: 0,
          }}
          onMouseEnter={() => setHover(!hover)}
          onMouseLeave={() => setHover(!hover)}
        >
          <Text
            style={{
              fontSize: 18,
              fontFamily: "GalanoMedium",
              color: "#f5f2d0",
            }}
          >
            Logout
          </Text>
        </Button>
      </Space>
    </div>
  );
};

function Navbar({ refreshData }) {

  const [hover, setHover] = useState(false); //used for bookAppointment in menu
  const [logging, setLogging] = useState(false);

  const [show, setShow] = useState(false);

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click); //switches the state between true false on click
  const closeMobileMenu = () => setClick(false);

  const dual = () => {
    closeMobileMenu();
    if (window.localStorage.getItem("access-token") != "") {
      setShow(true);
    } else {
      setLogging(true);
    }
  };
  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
      setClick(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img
              src={logo}
              alt=""
              className="logo"
              style={{ width: "150px" }}
            />
          </Link>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            {window.localStorage.getItem("access-token") != "" ? null : (
              <li className="nav-item">
                <LogIn setLogging={setLogging} />
              </li>
            )}

            {window.localStorage.getItem("access-token") != "" && click ? (
                  <ProfileMenu />                
            ) : (
              null
            )}

            {window.localStorage.getItem("access-token") != "" ? (
              <li className="nav-item">
                <Link
                  to="/profile"
                  className="nav-links"
                  onClick={closeMobileMenu}
                  style={{
                    fontFamily: "GalanoMedium",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      color: !click ? "#fff" : "#000",
                    }}
                  >
                    Profile
                  </Text>
                </Link>
              </li>
              
            ) : (
              null
            )}
            {window.localStorage.getItem("access-token") != "" ? (
              
              <li className="nav-item">
                <Link
                  to="/doctor-profile"
                  className="nav-links"
                  onClick={closeMobileMenu}
                  style={{
                    fontFamily: "GalanoMedium",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      color: !click ? "#fff" : "#000",
                    }}
                  >
                    Doctor Profile
                  </Text>
                </Link>
              </li>
              
            ) : (
              null
            )}

            <li className="nav-item">
              <Link
                to="/about-us"
                className="nav-links"
                onClick={closeMobileMenu}
                style={{ width: 120 }}
              >
                <Text
                  style={{
                    fontFamily: "GalanoMedium",
                    fontSize: 18,
                    color: !click ? "#fff" : "#000",
                  }}
                >
                  About Us
                </Text>
              </Link>
            </li>

            <li className="nav-button">
              { (
                <CustomButton
                  buttonStyle="btn--outline"
                  onClick={() => {
                    if (window.localStorage.getItem("access-token") != "") {
                      setShow(true);
                    } else {
                      setLogging(true);
                    }
                  }}
                  text="Book Appointment"
                />
              )}
            </li>

            {window.localStorage.getItem("access-token") != "" ? (
              <li className="nav-item">
                <Link
                  to="/bookings"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  <Text
                    style={{
                      fontFamily: "GalanoMedium",
                      fontSize: 18,
                      color: !click ? "#fff" : "#000",
                    }}
                  >
                    Bookings
                  </Text>
                </Link>
              </li>
            ) : (
              <li className="nav-item">
                <Link
                  to="/blogs"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  <Text
                    style={{
                      fontFamily: "GalanoMedium",
                      fontSize: 18,
                      color: !click ? "#fff" : "#000",
                    }}
                  >
                    Blog
                  </Text>
                </Link>
              </li>
            )}

            
            {window.localStorage.getItem("access-token") != "" ? (
              <li className="nav-item">
                <Link
                  to="/blogs"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  <Text
                    style={{
                      fontFamily: "GalanoMedium",
                      fontSize: 18,
                      color: !click ? "#fff" : "#000",
                    }}
                  >
                    Blog
                  </Text>
                </Link>
              </li>
            ) : null}

            {/*  */}
            {window.localStorage.getItem("access-token") == "" ? (
              <li className="nav-item">
                <Link
                  to="/contact-us"
                  className="nav-links"
                  onClick={closeMobileMenu}
                  style={{ width: 150 }}
                >
                  <Text
                    style={{
                      fontFamily: "GalanoMedium",
                      fontSize: 18,
                      color: !click ? "#fff" : "#000",
                    }}
                  >
                    Contact Us
                  </Text>
                </Link>
              </li>
            ) : null}
            {window.localStorage.getItem("access-token") != "" && !click ? (
              <HeaderRight>
                <ProfileMenu />
              </HeaderRight>
            ) : null}
          </ul>

          <AppoinmentModal
            visible={show}
            setVisible={setShow}
            refreshData={refreshData}
          />
          <Auth trigger={logging} setTrigger={setLogging} />
        </div>
      </nav>
    </>
  );
}

export default Navbar;
