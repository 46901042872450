import React from "react";
import { Button, Card, Typography, Space } from "antd";
import styled from "styled-components";
import { CheckOutlined } from "@ant-design/icons";
import {Link} from 'react-router-dom';
import './Cards.css'
const { Text } = Typography;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px;
  justify-content: center;
`;
const OuterCard = styled(Card)`
  display: flex;
  flex: 1;
  flex-direction: column;
  border-width: 1px;
  border-radius: 10px;
  margin: 20px;
  max-width: 300px;
  justify-content: space-between;
`;
const UpperCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-color: 7a7a7a;
  border-bottom-width: 1px;
  height: 250px;
  marginBottom: 10%;
`;
const LowerCard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  height: auto;
  justify-content: flex-end;
  padding: 10px;
  padding-left: 20px;
  align-items: flex-start;
`;
const CardText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
// const ButtonContainer = styled.div`
//   margin: auto;
//   width: '60%';
//   height: '50px';
//   background: '#000';
// `;
// const ButtonBuy = styled.div`
//   width: '97%';
//   height: '85%';
//   background: '#1557e3';
//   border: '0px';
//   padding: '15px';
//   color: '#fff';
//   /* font-family:Arial, Helvetica, sans-serif; */
//   font-size: '15px';
//   font-weight: bolder;
//   text-align: center;
//   `;
const Feature = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  padding: 5px;
  align-items: center;
`;



const PaymentPackage = ({ type, price, features,width,setTrigger }) => {  


  const handleFontSize = () => {
    if (width > 900) {
      return "16px";
    } else if (width > 700) {
      return "14px";
    } else {
      return "12px";
    }
  };

  return (
    <OuterCard hoverable style={{boxShadow: '0px 8px 15px rgb(40, 55, 239, 0.5)'}}>
      <UpperCard>
        {type == "Routine Subscription" ? (
          <Space
            style={{
              backgroundColor: "#2837ef",
              position: "absolute",
              left: "10%",
              top: "-5%",
              width: "80%",
              justifyContent: "center",
              borderRadius: 5,
              height:40
            }}
          >
            <Text style={{ fontFamily: "GalanoMedium",color:"#fff" }}>
              RECOMMENDED
            </Text>
          </Space>
        ) : null}
        <Text
          style={{
            fontWeight: "bold",
            // marginBottom: "10px",
            // width: "95%",
            fontSize: "16px",
            fontFamily: "GalanoMedium",
          }}
        >
          {type}
        </Text>

        {features.map((item, index) => (
          <Feature key={index}>
            <CheckOutlined style={{ color: "#2837ef" }} />
            <Text
              style={{
                marginLeft: "20px",
                // color: "#7a7a7a",
                fontSize: handleFontSize(),
                color: "#000",
                fontFamily: "GalanoLight",
              }}
            >
              {item}
            </Text>
          </Feature>
        ))}
      </UpperCard>
      <LowerCard>
        <CardText>
          <Text
            style={{
              // marginTop: "30%",
              marginBottom: "10px",
              fontFamily: "GalanoMedium",
            }}
          >
            Price
          </Text>
        </CardText>
        <CardText>
          <Text
            style={{
              marginBottom: "10px",
              fontFamily: "GalanoLight",
              fontStyle: "italic",
            }}
          >
            ${parseInt(price).toFixed(2)}/month
          </Text>
        </CardText>
        {/* <ButtonContainer>
          <Button
            style={{
              backgroundColor: "#2837ef",
              width: "100%",
              borderRadius: 5,
              maxHeight: 50,
              height: 40,
              color: "white",
              fontFamily: "GalanoBold",
            }}
          >
            BUY NOW
          </Button>
        </ButtonContainer> */}
        {/* <Link to="/payment"> */}
        <div className='Button-div'>
          {
            window.localStorage.getItem("access-token") != "" ?
            (
              <Link to={{
                pathname:"/payment",
                packageType: type,
                packagePrice:price
              }}>
              
              <div className="Buy"><h4>BUY NOW</h4></div>
              </Link>
            ):(
              <div className="Buy" onClick={()=>{setTrigger(true);}}><h4>BUY NOW</h4></div>
            )
          }
        </div>
        {/* </Link> */}
      </LowerCard>
    </OuterCard>
  );
};

export const Payment = ({width,setTrigger}) => {
  const packages = [
    {
      type: "Basic Subscription",
      price: 15,
      features: [
        "1 User Profile",
        "1 Phone chat/month",
        "1 Virtual Chat Session (5 minutes each)/month",
      ],
    },
    {
      type: "Routine Subscription",
      price: 25,
      features: [
        "1 User Profile",
        "1 Video chat/month",
        "1 Phone call/month",
        "2 Virtual Chat Session (5 minutes each)/month",
      ],
    },
    {
      type: "High frequency subscription",
      price: 40,
      features: [
        "1 User Profile",
        "2 Video chat/month",
        "4 Phone call/month",
        "2 Virtual Chat Session (5 minutes each)/month",
      ],
    },
  ];

  return (
    <CardContainer>
      {packages.map(({ type, price, features }, index) => (
        <PaymentPackage
          key={index}
          type={type}
          price={price}
          features={features}
          width={width}
          setTrigger={setTrigger}
        />
      ))}
    </CardContainer>
  );
};
