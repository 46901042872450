import React from 'react'
import AdminNavbar from "../../layout/AdminNavbar";
import AdminFooter from "../../layout/AdminFooter";
import { Image } from 'antd';
import NotFoundImage from "../../assets/404.png";

export default function adminNotFound() {
  return (
    <>
      <AdminNavbar />
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            minHeight: "50vh"
        }}>
            <Image
                width={260}
                src={NotFoundImage}
                preview={false}
            />
        </div>
        <div style={{position:"fixed",bottom:0,width:"100%"}}>
            <AdminFooter/>
        </div>
    </>
  )
}