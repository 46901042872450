import './Audio.scss';
import React, { useState, useEffect } from 'react';
import Room from './components/Room';
import { useHistory, useLocation } from "react-router-dom";
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
const { connect } = require('twilio-video');

const AudioScreen = () => {
  const auth = firebase.auth();
  const firestore = firebase.firestore();
  const history = useHistory();
  const [room, setRoom] = useState(null);
  const [user, setUser] = useState(null);
  const [remoteUser, setRemoteUser] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const emailArray = auth.currentUser.email.split('@');
    joinRoom(emailArray[0]);
    getUser();
    if (location.state.name) {
      getRemoteUser(location.state.name);
    }
  }, [location.state.name])

  const getUser = async () => {
    if (auth.currentUser != null) {
      const result = firestore.collection("Users").doc(auth.currentUser.uid);
      result.get().then((querySnapshot) => {
        setUser(querySnapshot.data());
      });
    }
  };

  const getRemoteUser = async (remoteuser) => {
    const splitArray = remoteuser.split(' ');
    const result = firestore.collection("Users").where('firstName', '==', splitArray[0]).where('lastName', '==', splitArray[1]);
    result.get().then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        var data = {};
        data.uid = doc.id;
        data.photoURL = doc.data().photoURL;
        setRemoteUser(data)
      })
    });
  };

  const joinRoom = async (identity) => {
    try {
      // const response = await fetch(`https://token-service-7883-dev.twil.io/token?identity=${identity}&name=${location.state.name}`);
      const response = await fetch(`https://nimble-project-7274-dev.twil.io/token?identity=${identity}&name=${location.state.name}`);
      const data = await response.json();
      const room = await connect(data.accessToken, {
        audio: true,
        video: false
      });
      setRoom(room);
    } catch (err) {
      console.log(err);
      setRoom(null);
      history.push('/bookings', { alert: true })
    }
  };

  const returnToLobby = () => {
    setRoom(null);
    history.push('/bookings')
  }

  return (
    <div className="app">
      {
        room !== null && user !== null && remoteUser !== null ? <Room returnToLobby={returnToLobby} room={room} user={user} remoteUser={remoteUser} photo={location.state.photoURL}/> : null
      }

    </div>
  );

};

export default AudioScreen;