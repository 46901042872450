import React, { useEffect, useState, useRef } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import ChatMessage from './components/Message';
import { SendOutlined } from '@ant-design/icons';
import ChatMenu from './components/ChatMenu';
import { useHistory, useLocation } from "react-router-dom";
import { useFilePicker } from 'use-file-picker';

const ChatScreen = (props) => {

  const useFirestoreQuery = (query) => {
    const [docs, setDocs] = useState([]);
    const queryRef = useRef(query);

    useEffect(() => {
      if (!queryRef?.curent?.isEqual(query)) {
        queryRef.current = query;
      }
    });

    useEffect(() => {
      if (!queryRef.current) {
        return null;
      }
      const unsubscribe = queryRef.current.onSnapshot(querySnapshot => {
        const data = querySnapshot.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
        }));
        setDocs(data);
      });

      return unsubscribe;
    }, [queryRef]);

    return docs;
  }

  const auth = firebase.auth();
  const history = useHistory();
  const location = useLocation();
  const db = firebase.firestore();
  const chatsRef = db.collection(`Chats/${location.state}/messages`);
  const chats = useFirestoreQuery(chatsRef.orderBy('createdAt', 'asc'));
  const [newMessage, setNewMessage] = useState('');
  const [user, setUser] = useState([]);
  const storage = firebase.storage();
  const inputRef = useRef();
  const bottomListRef = useRef();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    getUser(auth.currentUser.uid);
  }, [inputRef]);

  const handleOnChange = e => {
    setNewMessage(e.target.value);
  };

  const getUser = (uid) => {
    db.collection('Users').doc(uid).get().then(response => {
      setUser(response.data())
    });
  }

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    const trimmedMessage = newMessage.trim();
    if (trimmedMessage) {
      var messageObject = {};
      if (user.privilege == 'admin') {
        messageObject = {
          _id: generateString(29),
          createdAt: new Date(),
          text: trimmedMessage,
          displayName: 'Doctor',
          photoURL: auth.currentUser.photoURL,
          user: {
            _id: generateString(29),
            displayName: 'patient'
          }
        }
      } else {
        messageObject = {
          _id: generateString(29),
          createdAt: new Date(),
          text: trimmedMessage,
          displayName: 'Patient',
          photoURL: auth.currentUser.photoURL,
          user: {
            _id: generateString(29),
            displayName: 'doctor'
          }
        }
      }

      await chatsRef.add(messageObject);
      setNewMessage('');
    }
  };

  const sendImageChat = async (url) => {
    var messageObject = {};
    if (user.privilege == 'admin') {
      messageObject = {
        _id: generateString(29),
        createdAt: new Date(),
        image: url,
        displayName: 'Doctor',
        photoURL: auth.currentUser.photoURL,
        user: {
          _id: generateString(29),
          displayName: 'patient'
        }
      }
    } else {
      messageObject = {
        _id: generateString(29),
        createdAt: new Date(),
        image: url,
        displayName: 'Patient',
        photoURL: auth.currentUser.photoURL,
        user: {
          _id: generateString(29),
          displayName: 'doctor'
        }
      }
    }
    await chatsRef.add(messageObject);
  }

  const [openFileSelector, { filesContent, loading }] = useFilePicker({ accept: '*', readAs: 'DataURL' });

  useEffect(() => {
    filesContent.map(item => {
      let file = item.content.split(',')[1];
      storage.ref(location.state + '/' + item.name).putString(file, 'base64').then(function (snapshot) {
        storage.ref(snapshot.metadata.fullPath).getDownloadURL()
          .then((url) => {
            sendImageChat(url);
          })
          .catch((error) => console.log(error));
      })
    });
  }, [filesContent])

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  const generateString = (length) => {
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  return (
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'space-between ', alignContent: 'center', height: "90vh",overflow:"scroll" }}>
      <div style={{ display: "flex", width: "100%", backgroundColor: "lightblue", justifyContent: "space-between", padding: 20, maxHeight: 60 }}>
        <p>Chat Messages</p>
        <ChatMenu history={history} doc={location.state}/>
      </div>
      <div>

        <div >
          <ul style={{ listStyleType: 'none' }}>
            {chats
              ?.sort((first, second) =>
                first?.createdAt?.seconds <= second?.createdAt?.seconds ? -1 : 1
              )
              ?.map(chat => (
                <li key={chat.id}>
                  <ChatMessage {...chat} />
                </li>
              ))}
          </ul>
          <div ref={bottomListRef} />
        </div>
      </div>

      <div  >
        <form onSubmit={handleOnSubmit} style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignContent: 'center', padding: 20 }}>
          <textarea
            ref={inputRef}
            name="body"
            onChange={handleOnChange}
            placeholder="Type your message here..."
            value={newMessage}
            style={{ width: '90%', minHeight: 40, borderRadius: 5, border: "1px solid #ccc", padding: 10, resize: "none" }}
          />

          <button
            onClick={() => {
              openFileSelector();
            }}
            style={{ width: '5%', borderRadius: "50%", height: 60, width: 60, margin: 10 }}
          >
            <i className="fa fa-paperclip" aria-hidden="true"></i>
          </button>
          <button type="submit" disabled={!newMessage} style={{ width: '5%', borderRadius: "50%", height: 60, width: 60, margin: 10 }}>
            <SendOutlined />
          </button>
        </form>
      </div>

    </div>
  );


};

export default ChatScreen;