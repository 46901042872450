import React, { useEffect, useState } from "react";
import { Modal, Button, Typography, Space, ModalProps,message } from "antd";
import { useHistory } from "react-router-dom";
import { ArrowLeftOutlined, CloseOutlined } from "@ant-design/icons";
import IntlTelInput from "react-intl-tel-input";
import OtpInput from "react-otp-input";
import firebase from "firebase";
import Countdown from "react-countdown";


const { Title, Text } = Typography;

const PhoneAuthentication = ({ setUseEmail, trigger, setTrigger }) => {
  const [countryCode, setCountryCode] = useState("+1");
  const [phone, setPhone] = useState("");
  const [codeSent, setCodeSent] = useState(false);
  const [codeEvent, setCodeEvent] = useState(false);
  const [otp, setOTP] = useState();
  const [loading, setLoading] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [timer, setTimer] = useState();
  const history = useHistory();

  const sendVerificationCode = async (number) => {
    try {
      setCodeSent(true);
      setTimer(Date.now() + 59000);
      let recaptcha = window.RecaptchaVerifier;
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION);
      const e = await firebase.auth().signInWithPhoneNumber(number, recaptcha);
      setCodeEvent(e);
    } catch (error) {
      console.log(error);
    }
  };

  const verifyCode = async () => {
    const _firestore = firebase.firestore();
    const _auth = firebase.auth();
    try {
      setLoading(true);
      const result = await codeEvent.confirm(otp);
      if (result.additionalUserInfo.isNewUser) {
        history.replace("/register");
      } else {
        const result = await _firestore.collection("Users").doc(_auth.currentUser.uid).get();
        if (result.exists) {
          const Token = await firebase.auth().currentUser.getIdToken();
          window.localStorage.setItem(
            "access-token",JSON.stringify(Token)
          );
          setTrigger(false);
        }
        else {
          history.replace("/register");
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const resendCode = async () => {
    try {
      if (window.RecaptchaVerifier) {
        window.RecaptchaVerifier.reset();
      } else {
        window.RecaptchaVerifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          { size: "invisible" }
        );
        window.RecaptchaVerifier.reset();
      }
      setTimer(Date.now() + 59000);
      let recaptcha = window.RecaptchaVerifier;
      await firebase
        .auth()
        .setPersistence(firebase.auth.Auth.Persistence.SESSION);
      const e = await firebase.auth().signInWithPhoneNumber(phone, recaptcha);
      setCodeEvent(e);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (window.RecaptchaVerifier) {
      window.RecaptchaVerifier.reset();
    } else {
      window.RecaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        { size: "invisible" }
      );
    }

    return () => {
      window.RecaptchaVerifier.reset();
    };
  }, []);

  return (
    <>
      <div id={"recaptcha-container"} />
      <Modal
        title={
          codeSent ? (
            <Space direction="horizontal" style={{ width: "100%", justifyContent: "space-between", alignItems: "center" }}>
              <div style={{ display: "flex", alignItems: "center" }}>

                <ArrowLeftOutlined
                  onClick={() => {
                    setCodeSent(false);
                    setPhone("");
                  }}
                />
                <Typography
                  style={{ fontWeight: "bold", fontFamily: "GalanoBold", paddingLeft: 20 }}
                >
                  Verify OTP
                </Typography>
              </div>
              <CloseOutlined
                onClick={() => {
                  setTrigger(false);
                }}
              />
            </Space>
          ) : (
            <Space direction="horizontal" style={{ width: "100%", justifyContent: "space-between" }}>
              <Title style={{ fontSize: 20, fontFamily: "GalanoBold" }}>
                Login with Mobile
              </Title>
              <CloseOutlined
                onClick={() => {
                  setTrigger(false);
                }}
              />
            </Space>
          )
        }
        visible={trigger}
        closable={true}
        closeIcon={() => { return null }}
        footer={null}
        bodyStyle={{
          display: 'flex',
          flexDirection: "column",
          // width: "40%",
          alignItems: "center",
          justifyContent: "center",
        }}
        maskClosable
        onCancel={() => {
          setTrigger(false);
        }}

      >
        {!codeSent ? (
          <>
            <IntlTelInput
              value={phone}
              onPhoneNumberChange={(status, input, countryData) => {
                setPhone(input);
                setCountryCode("+" + countryData?.dialCode);
              }}
              style={{
                display: "flex",
                height: "50px",
                width: "100%",
                paddingLeft: "25%",
              }}
              preferredCountries={['us','AE','PK']}

            />

            <Button
              disabled={loading}
              onMouseEnter={() => {
                setHovered(true);
              }}
              onMouseLeave={() => {
                setHovered(false);
              }}
              onClick={() => {
                if (phone[0] == 0) {
                  sendVerificationCode(
                    countryCode + phone.slice(1, phone.length)
                  );
                  setPhone(countryCode + phone.slice(1, phone.length));
                } else {
                  sendVerificationCode(countryCode + phone);
                  setPhone(countryCode + phone);
                }
              }}
              style={{
                width: "100%",
                borderRadius: 20,
                backgroundColor: hovered ? "#000" : "#2837ef",
                border: 0,
                boxShadow: "0px 1px 2px 1px #2837ef",
                marginTop: "20px",
                height: 40,
              }}
            >
              <Typography style={{ color: "#fff", fontFamily: "GalanoLight" }}>
                Send OTP
              </Typography>
            </Button>

            <Button
              onClick={() => {
                setUseEmail(true);
              }}
              style={{ margin: 10, fontFamily: "GalanoLight" }}
            >
              <Text>Use email instead ?</Text>
            </Button>
          </>
        ) : (
          <>
            <OtpInput
              value={otp}
              onChange={(val) => {
                // console.log(val);
                setOTP(val);
              }}
              numInputs={6}
              separator={<span style={{ color: "transparent" }}>-</span>}
              inputStyle={{
                height: 50,
                width: 50,
                borderRadius: 10,
                borderColor: "grey",
                fontSize: 45,
                fontFamily: "GalanoMedium",
                backgroundColor: "lightgrey"
              }}
              containerStyle={{ padding: 20 }}
            />
            <Countdown
              date={timer}
              renderer={(props) => {
                return props.completed ? (
                  <Button
                    onClick={resendCode}
                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                  >
                    <Text style={{ fontFamily: "GalanoLight" }}>
                      Resend Code
                    </Text>
                  </Button>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignSelf: "center",
                      fontFamily: "GalanoLight",
                    }}
                  >
                    {props?.seconds > 9 ? (
                      <>Resend in {props.minutes}:{props.seconds}</>
                    ) : (
                      <>
                        Resend in {props.minutes}:
                        {"0" + props.seconds.toString()}
                      </>
                    )}
                  </div>
                );
              }}
            />

            <Button
              disabled={loading}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onClick={() => verifyCode()}
              style={{
                width: "100%",
                borderRadius: 20,
                backgroundColor: hovered ? "#000" : "#2837ef",
                border: 0,
                boxShadow: "0px 1px 2px 1px #48a8e2",
                marginTop: "20px",
                height: 40,
              }}
            >
              <Typography
                style={{
                  color: "#fff",
                  fontWeight: "bold",
                  fontFamily: "GalanoLight",
                }}
              >
                Verify
              </Typography>
            </Button>
          </>
        )}
      </Modal>
    </>
  );
};

export default PhoneAuthentication;
